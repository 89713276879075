import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { postClearALLNotifi, removeNotification, fetchAppAdminNotifications, fetchTempleADNotifications } from '../../../../redux/actions/acions';
import { getNotificationsError, getNotificationsList, getNotificationstionsLoading, getTempleAdminNotificationsList, getTempleAdminNotificationsError, getTempleAdminNotificationstionsLoading, getAppAdminNotificationsList, getAppAdminNotificationsError, getAppAdminNotificationstionsLoading, gettempleAdminNotificationsError, gettempleAdminNotificationstionsLoading, gettempleAdminNotificationsList } from '../../../../redux/selectors/selectors';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, Card, Popconfirm, Result, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Error from '../error';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

let last_rec = 0;
const ManageNotification = () => {
    const { t } = useTranslation();
    const records_per_page = 15;
    const [clearAll, setClearAll] = useState(false);
    const [deletedIds, setDeletedIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const storedLanguage = localStorage.getItem('lng') || 5;
    const userrole = localStorage.getItem('urole')
    const dispatch = useDispatch();
    const { nList, nLoading, nError } = useSelector(state => ({
        nList: gettempleAdminNotificationsList(state),
        nLoading: gettempleAdminNotificationstionsLoading(state),
        nError: gettempleAdminNotificationsError(state),
    }));

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_clearnotifi_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'Cleared all notifications',
        // });
    };
    const warning = () => {
        showToast('info', t('label_notifideletd_success'))
        // messageApi.open({
        //     type: 'warning',
        //     content: 'Notification deleted',
        // });
    };
    const failure = (msg) => {
        showToast('fail', t('label_loginerror'))
        // messageApi.open({
        //     type: 'error',
        //     content: msg,
        // });
    };

    useEffect(() => {
        if (userrole === "AD" || userrole === "AS") {
            dispatch(fetchTempleADNotifications("appadmin", storedLanguage, 0));
        } else if (userrole === "TA" || userrole === "TU") {
            dispatch(fetchTempleADNotifications("templeadmin", storedLanguage, 0));
        }
    }, [userrole, storedLanguage, dispatch]);


    function deleteNotification(id) {
        const data = {
            "nid": id,
            "status": "v",
            "prev_status": "n"
        };

        // Dispatch an action to remove the notification via API
        dispatch(removeNotification(data));

        // Update the UI by filtering out the deleted notification
        setCurrentPageTemples(currentPageTemples.filter(notification => notification.nid !== id));

        // Optionally, you can display a success message
        warning();
    }


    function ClearALLNotifi() {
        dispatch(postClearALLNotifi(success, failure))
        setClearAll(true);
    }



    // const nextSetRef = useRef(null);


    // useEffect(() => {
    //     // Add a scroll event listener to the window
    //     window.addEventListener("scroll", handleScroll);
    //     return () => {
    //         // Remove the scroll event listener when the component unmounts
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    // const handleScroll = () => {
    //     const windowHeight =
    //         "innerHeight" in window
    //             ? window.innerHeight
    //             : document.documentElement.offsetHeight;
    //     const body = document.body;
    //     const html = document.documentElement;
    //     const docHeight = Math.max(
    //         body.scrollHeight,
    //         body.offsetHeight,
    //         html.clientHeight,
    //         html.scrollHeight,
    //         html.offsetHeight
    //     );
    //     const windowBottom = windowHeight + window.pageYOffset;

    //     // Detect if the user has scrolled to the bottom of the page
    //     if (windowBottom >= docHeight && !isLoading) {
    //         // Load more data when scrolling to the end
    //         nextTemplesList();
    //     }
    // };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])

    function isNotificationDeleted(id) {
        return deletedIds.includes(id);
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageTemples, setCurrentPageTemples] = useState([]);


    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className="mainsec">
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_manage_notifications')}</span>
                </div>
                <div></div>
            </div>

            <div className="f2 font-weight-bold mb-3 mt-3 container title-head-txt">
                <Link style={{ color: 'black' }} to="/">
                    <span className="bc-active-link">Home</span>
                </Link> -
                <span className="bc-link"> {t("label_manage_notifications")}</span>
            </div>
            <div className="bg-details-highlight" style={{ width: '100vw', marginLeft: 'calc(-50vw + 50%)' }}>
                <div className="details-headertxt container">
                    <div className="header_txt">
                        {t("label_manage_notifications")}
                        <Link to='/addNotification' type='button' className="button_move" style={{ marginLeft: '20px' }}>
                            {t("label_add_notify")}
                        </Link>
                    </div>
                    <div className="d-flex flex-row header_star mr-5">
                        <span className="f18 mb-2 colorHeadingviolet fw-7 events-icon"></span>
                    </div>
                </div>
            </div>
            <div className="mainsec-block-addtemple-detailspage">
                {clearAll ? (
                    <></>
                ) : (
                    <div className="container">
                        {nLoading ? (
                            <Error />
                        ) : (
                            nList && nList.length > 0 ? (
                                nList
                                    .filter((notificationList) => !isNotificationDeleted(notificationList.nid))
                                    .map((notificationList) => {
                                        const isRead = notificationList.read;
                                        const notificationClass = isRead ? 'notification-read' : 'notification-unread'; // apply different class based on read status
                                        return (
                                            <div
                                                key={notificationList.nid}
                                                className="tiles-designs-events"
                                            >
                                                <div className="d-flex flex-space-between">
                                                    <Link to={`/notifiDetails/${notificationList.nid}`} className="notification-link">
                                                        <div className="f1 events-block-link-title">
                                                            <i className="fa-solid fa-bell mr-3"></i>{' '}
                                                            <span className="f1">{notificationList.subject}</span>{' '}
                                                        </div>
                                                    </Link>
                                                    <div className="d-flex">
                                                        <Popconfirm
                                                            title="Are you sure you want to delete this notification?"
                                                            okText="Yes"
                                                            okType="danger"
                                                            cancelText="No"
                                                            onConfirm={() => {
                                                                deleteNotification(notificationList.nid);
                                                            }}
                                                            onCancel={() => {
                                                                console.warn('Deletion cancelled');
                                                            }}
                                                        >
                                                            <DeleteOutlined style={{ marginBottom: '4px' }} />
                                                        </Popconfirm>
                                                    </div>
                                                </div>
                                                <span className="f1 events-block-link-value">{notificationList.txt}</span>
                                            </div>
                                        );
                                    })
                            ) : (
                                <Result
                                    status="404"
                                    title="No Data Found"
                                    subTitle="Sorry, no notifications found."
                                />
                            )
                        )}
                    </div>
                )}

            </div>
            <Footer />
        </div>

    )
}

export default ManageNotification;
