import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, message, Table, Popconfirm, Select, Input } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getDonationsList, getDonationsListError, getDonationsListLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../redux/selectors/selectors';
import { deleteDonation, fetchDailyHoroscope, fetchDonationsList, fetchRegionalNotifi } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.png';
import { useTranslation } from 'react-i18next';
import Error from '../pages/Homepage/error';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Option } from 'antd/es/mentions';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';

const CreatePriest = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [searchQuery, setSearchQuery] = useState('');
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [userid, setUserid] = useState('');
    const failure = (msg) => {
        showToast('fail', t(`label_createpriest_failure`)); 
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getDonationsList(state),
        rLoading: getDonationsListLoading(state),
        rError: getDonationsListError(state),
    }));

    useEffect(() => {
        dispatch(fetchDonationsList(0));
    }, [dispatch]);

    function deleteDon(did, ts) {
        dispatch(deleteDonation(did, ts));
      }

    const modifiedData = rList && rList.map((item, index) => ({ ...item, "S.No": index + 1 }));
    const handleRoleChange = (value, record) => {
        // Implement your logic to handle role change here
        console.warn("New role selected:", value, "for record:", record);
        // You can call an API to update the role in the backend or update it in the state if it's managed locally.
    };

    const columns = [

        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Member
                </span>
            ),
            dataIndex: "fullname",
            key: "fullname",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Temple ID
                </span>
            ),
            dataIndex: "tid",
            key: "tid",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => (
                <Link className="notification-link" to={`/donationDetails/${record.donid}`}><span className='donation-text' >{text}</span></Link>
            ),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    User ID
                </span>
            ),
            dataIndex: "userid",
            key: "userid",
            sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Role
                </span>
            ),
            dataIndex: 'role',
            key: 'role',
            render: (text, record) => (
                userid === 'AD' ? (
                    <Select defaultValue={text} style={{ width: 120 }} onChange={(value) => handleRoleChange(value, record)}>
                        <Option value="TA">TA</Option>
                        <Option value="TU">TU</Option>
                    </Select>
                ) : (
                    <span></span>
                )
            ),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Active
                </span>
            ),
            dataIndex: "userid",
            key: "userid",
            sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Actions
                </span>
            ),
            dataIndex: 'Delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure you want to Remove User?"
                    onConfirm={() => {
                        deleteDon(record);
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Link>{t("label_delete")}</Link>
                </Popconfirm>

            ),
        },
    ];

    const navigate = useNavigate();
    const handleGoBack = () => {
      navigate(-1);
    };

    return (
        <div className='container main'>
                  <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_add_priest')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}
            <CustomToastContainer/>
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">Home</span></Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                {/* <span className="bc-link" onClick={handleGoBack}> {title}</span> - */}
                &nbsp;<span className="bc-link">{t("label_add_priest")}</span>
            </div>
            {/* <div className="container-fluid" style={{ display: 'flex', justifyContent: 'end' }}>
                <Link to='/addDonations'><button type="button" class="btn1 btn btn-secondary " style={{ marginLeft: '5px', marginBottom: '5px' }}>{t('Add Donation')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
            </div> */}
            <div style={{ marginBottom: 16, marginLeft:'13px',marginTop:'10px' }}>
            <Input
              placeholder="Search user by name, phone or user id."
              allowClear
              suffix={<SearchOutlined />}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ width: 300, marginBottom: 16 }}
            />
          </div>
            <div className='container'>
                <Table dataSource={modifiedData} columns={columns} bordered
                    style={{ border: '2px solid black', borderCollapse: 'collapse' }}
                />

            </div>
        </div>

    );
};

export default CreatePriest;
