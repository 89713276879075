import {
    DELETE_FAVOURITES_LIST_FAILURE,
    DELETE_FAVOURITES_LIST_REQUEST,
    DELETE_FAVOURITES_LIST_SUCESS,
    EDIT_CITY_LIST_FAILURE,
    EDIT_CITY_LIST_REQUEST,
    EDIT_CITY_LIST_SUCESS,
    EDIT_GENDER_LIST_FAILURE,
    EDIT_GENDER_LIST_REQUEST,
    EDIT_GENDER_LIST_SUCESS,
    EDIT_LANG_LIST_FAILURE,
    EDIT_LANG_LIST_REQUEST,
    EDIT_LANG_LIST_SUCESS,
    EDIT_TEMPLES_LIST_FAILURE,
    EDIT_TEMPLES_LIST_REQUEST,
    EDIT_TEMPLES_LIST_SUCESS,
    FETCH_BOOKS_BY_TAG_FAILURE,
    FETCH_BOOKS_BY_TAG_REQUEST,
    FETCH_BOOKS_BY_TAG_SUCESS,
    FETCH_BOOKS_FAILURE,
    FETCH_BOOKS_FAVOURITES_LIST_FAILURE,
    FETCH_BOOKS_FAVOURITES_LIST_REQUEST,
    FETCH_BOOKS_FAVOURITES_LIST_SUCESS,
    FETCH_BOOKS_REQUEST,
    FETCH_BOOKS_SUCESS,
    FETCH_BY_ST_DT_FAILURE,
    FETCH_BY_ST_DT_REQUEST,
    FETCH_BY_ST_DT_SUCESS,
    FETCH_CITY_TEMPLES_LIST_FAILURE,
    FETCH_CITY_TEMPLES_LIST_REQUEST,
    FETCH_CITY_TEMPLES_LIST_SUCESS,
    FETCH_CONTRIBUTIONS_LIST_FAILURE, FETCH_CONTRIBUTIONS_LIST_REQUEST, FETCH_CONTRIBUTIONS_LIST_SUCESS,
    FETCH_DAILY_HOROSCOPE_DETAILS_FAILURE,
    FETCH_DAILY_HOROSCOPE_DETAILS_REQUEST,
    FETCH_DAILY_HOROSCOPE_DETAILS_SUCESS,
    FETCH_DAILY_HOROSCOPE_FAILURE,
    FETCH_DAILY_HOROSCOPE_REQUEST,
    FETCH_DAILY_HOROSCOPE_SUCESS,
    FETCH_FAVOURITES_LIST_FAILURE, FETCH_FAVOURITES_LIST_REQUEST, FETCH_FAVOURITES_LIST_SUCESS,
    FETCH_FILTER_BY_GOD_FAILURE,
    FETCH_FILTER_BY_GOD_REQUEST,
    FETCH_FILTER_BY_GOD_SUCESS,
    FETCH_FILTER_BY_ST_DT_FAILURE,
    FETCH_FILTER_BY_ST_DT_REQUEST,
    FETCH_FILTER_BY_ST_DT_SUCESS,
    FETCH_FILTER_NEAREST_FAILURE,
    FETCH_FILTER_NEAREST_REQUEST,
    FETCH_FILTER_NEAREST_SUCESS,
    FETCH_GOD_NAMES_LIST_FAILURE, FETCH_GOD_NAMES_LIST_REQUEST, FETCH_GOD_NAMES_LIST_SUCESS,
    FETCH_GROUP_DESCRIPTION_FAILURE,
    FETCH_GROUP_DESCRIPTION_REQUEST,
    FETCH_GROUP_DESCRIPTION_SUCESS,
    FETCH_LIVETV_FAILURE,
    FETCH_LIVETV_REQUEST,
    FETCH_LIVETV_SUCESS,
    FETCH_NEAREST_FAILURE,
    FETCH_NEAREST_REQUEST,
    FETCH_NEAREST_SUCESS,
    FETCH_NOTIFICATIONS_DETAILS_FAILURE,
    FETCH_NOTIFICATIONS_DETAILS_REQUEST,
    FETCH_NOTIFICATIONS_DETAILS_SUCESS,
    FETCH_NOTIFICATIONS_LIST_FAILURE, FETCH_NOTIFICATIONS_LIST_REQUEST, FETCH_NOTIFICATIONS_LIST_SUCESS,
    FETCH_RECENTLY_VIEWED_FAILURE,
    FETCH_RECENTLY_VIEWED_REQUEST,
    FETCH_RECENTLY_VIEWED_SUCESS,
    FETCH_REFRESH_TOKEN_FAILURE,
    FETCH_REFRESH_TOKEN_REQUEST,
    FETCH_REFRESH_TOKEN_SUCESS,
    FETCH_REGIONAL_NOTIFI_FAILURE,
    FETCH_REGIONAL_NOTIFI_REQUEST,
    FETCH_REGIONAL_NOTIFI_SUCESS,
    FETCH_RESOURCE_BY_TXT_FAILURE,
    FETCH_RESOURCE_BY_TXT_REQUEST,
    FETCH_RESOURCE_BY_TXT_SUCESS,
    FETCH_RESOURCE_FAILURE,
    FETCH_RESOURCE_REQUEST,
    FETCH_RESOURCE_SUCESS,
    FETCH_STATE_FAILURE,
    FETCH_STATE_REQUEST,
    FETCH_STATE_SUCESS,
    FETCH_TEMPLES_BY_GP_FAILURE,
    FETCH_TEMPLES_BY_GP_REQUEST,
    FETCH_TEMPLES_BY_GP_SUCESS,
    FETCH_TEMPLES_BY_TXT_FAILURE,
    FETCH_TEMPLES_BY_TXT_REQUEST,
    FETCH_TEMPLES_BY_TXT_SUCESS,
    FETCH_TEMPLES_LIST_FAILURE, FETCH_TEMPLES_LIST_REQUEST, FETCH_TEMPLES_LIST_SUCCESS,
    FETCH_TEMPLE_AUDIO_FAILURE,
    FETCH_TEMPLE_AUDIO_REQUEST,
    FETCH_TEMPLE_AUDIO_SUCESS,
    FETCH_TEMPLE_BOOKS_FAILURE,
    FETCH_TEMPLE_BOOKS_REQUEST,
    FETCH_TEMPLE_BOOKS_SUCESS,
    FETCH_TEMPLE_FAVOURITES_LIST_FAILURE,
    FETCH_TEMPLE_FAVOURITES_LIST_REQUEST,
    FETCH_TEMPLE_FAVOURITES_LIST_SUCESS,
    FETCH_TEMPLE_GP_FAILURE,
    FETCH_TEMPLE_GP_REQUEST,
    FETCH_TEMPLE_GP_SUCESS,
    FETCH_TEMPLE_NOTIFI_FAILURE,
    FETCH_TEMPLE_NOTIFI_REQUEST,
    FETCH_TEMPLE_NOTIFI_SUCESS,
    FETCH_TEMPLE_VIDEO_FAILURE,
    FETCH_TEMPLE_VIDEO_REQUEST,
    FETCH_TEMPLE_VIDEO_SUCESS,
    FETCH_USER_LOGIN_FAILURE,
    FETCH_USER_LOGIN_REQUEST,
    FETCH_USER_LOGIN_SUCESS,
    FETCH_VIDEO_LINKS_FAILURE,
    FETCH_VIDEO_LINKS_REQUEST,
    FETCH_VIDEO_LINKS_SUCESS,
    FETCH_WEEKLY_HOROSCOPE_FAILURE,
    FETCH_WEEKLY_HOROSCOPE_REQUEST,
    FETCH_WEEKLY_HOROSCOPE_SUCESS,
    GET_RESOURCE_DET_FAILURE,
    GET_RESOURCE_DET_REQUEST,
    GET_RESOURCE_DET_SUCCESS,
    FETCH_DONATIONS_DETAILS_FAILURE,
    FETCH_DONATIONS_DETAILS_REQUEST,
    FETCH_DONATIONS_DETAILS_SUCESS,
    FETCH_DONATIONS_LIST_FAILURE,
    FETCH_DONATIONS_LIST_REQUEST,
    FETCH_DONATIONS_LIST_SUCESS,
    FETCH_EXPENSES_DETAILS_FAILURE,
    FETCH_EXPENSES_DETAILS_REQUEST,
    FETCH_EXPENSES_DETAILS_SUCESS,
    FETCH_EXPENSES_LIST_FAILURE,
    FETCH_EXPENSES_LIST_REQUEST,
    FETCH_EXPENSES_LIST_SUCESS,
    GET_TEMPLE_DET_FAILURE,
    GET_TEMPLE_DET_REQUEST,
    GET_TEMPLE_DET_SUCCESS,
    POST_FAVOURITES_LIST_FAILURE,
    POST_FAVOURITES_LIST_REQUEST,
    POST_FAVOURITES_LIST_SUCESS,
    POST_REGISTER_USER_FAILURE,
    POST_REGISTER_USER_REQUEST,
    POST_REGISTER_USER_SUCESS,
    POST_REQUEST_PASSWORD_FAILURE,
    POST_REQUEST_PASSWORD_REQUEST,
    POST_REQUEST_PASSWORD_SUCESS,
    POST_SET_NEW_PASSWORD_FAILURE,
    POST_SET_NEW_PASSWORD_REQUEST,
    POST_SET_NEW_PASSWORD_SUCESS,
    POST_TEMPLES_LIST_FAILURE,
    POST_TEMPLES_LIST_REQUEST,
    POST_TEMPLES_LIST_SUCESS,
    POST_UPLOAD_IMAGE_FAILURE,
    POST_UPLOAD_IMAGE_REQUEST,
    POST_UPLOAD_IMAGE_SUCESS,
    GET_TEMPLE_GRP_DETAILS_REQUEST,
    GET_TEMPLE_GRP_DETAILS_SUCCESS,
    GET_TEMPLE_GRP_DETAILS_FAILURE,
    FETCH_PRIEST_DETAILS_REQUEST,
    FETCH_PRIEST_DETAILS_SUCESS,
    FETCH_PRIEST_DETAILS_FAILURE,
    FETCH_PRIEST_BY_SPCLTY_REQUEST,
    FETCH_PRIEST_BY_SPCLTY_SUCESS,
    FETCH_PRIEST_BY_SPCLTY_FAILURE,
    FETCH_MY_REQUEST,
    FETCH_MY_SUCESS,
    FETCH_MY_FAILURE,
    FETCH_PRIEST_REQ_DETAILS_REQUEST,
    FETCH_PRIEST_REQ_DETAILS_SUCESS,
    FETCH_PRIEST_REQ_DETAILS_FAILURE,
    GET_LATEST_TEMPLE_DET_REQUEST,
    GET_LATEST_TEMPLE_DET_SUCCESS,
    GET_LATEST_TEMPLE_DET_FAILURE,
    FETCH_WEEKLY_HOROSCOPE_LIST_REQUEST,
    FETCH_WEEKLY_HOROSCOPE_LIST_SUCESS,
    FETCH_WEEKLY_HOROSCOPE_LIST_FAILURE,
    FETCH_DAILY_HOROSCOPE_LIST_REQUEST,
    FETCH_DAILY_HOROSCOPE_LIST_SUCESS,
    FETCH_DAILY_HOROSCOPE_LIST_FAILURE,
    FETCH_DONATIONS_BY_MONTH_REQUEST,
    FETCH_DONATIONS_BY_MONTH_SUCESS,
    FETCH_DONATIONS_BY_MONTH_FAILURE,
    FETCH_SERVICES_DETAILS_REQUEST,
    FETCH_SERVICES_DETAILS_SUCESS,
    FETCH_SERVICES_DETAILS_FAILURE,
    FETCH_SERVICES_BY_USER_REQUEST,
    FETCH_SERVICES_BY_USER_SUCESS,
    FETCH_SERVICES_BY_USER_FAILURE,
    FETCH_SERVICES_BY_ADMIN_REQUEST,
    FETCH_SERVICES_BY_ADMIN_SUCESS,
    FETCH_SERVICES_BY_ADMIN_FAILURE,
    FETCH_TEMPLE_DASHBOARD_LIST_REQUEST,
    FETCH_TEMPLE_DASHBOARD_LIST_SUCESS,
    FETCH_TEMPLE_DASHBOARD_LIST_FAILURE,
    FETCH_TEMPLE_MEMBERS_LIST_REQUEST,
    FETCH_TEMPLE_MEMBERS_LIST_SUCESS,
    FETCH_TEMPLE_MEMBERS_LIST_FAILURE,
    FETCH_PENDING_TEMPLES_LIST_REQUEST,
    FETCH_PENDING_TEMPLES_LIST_SUCESS,
    FETCH_PENDING_TEMPLES_LIST_FAILURE,
    FETCH_TEMPLESERVICEREG_LIST_REQUEST,
    FETCH_TEMPLESERVICEREG_LIST_SUCESS,
    FETCH_TEMPLESERVICEREG_LIST_FAILURE,
    FETCH_TEMPLE_SERVICE_REGISTRATION_REQUEST,
    FETCH_TEMPLE_SERVICE_REGISTRATION_SUCESS,
    FETCH_TEMPLE_SERVICE_REGISTRATION_FAILURE,
    FETCH_SERVICE_REGISTRATION_DETAILS_REQUEST,
    FETCH_SERVICE_REGISTRATION_DETAILS_SUCESS,
    FETCH_SERVICE_REGISTRATION_DETAILS_FAILURE,
    FETCH_USER_SERVICE_REGISTRATION_REQUEST,
    FETCH_USER_SERVICE_REGISTRATION_SUCESS,
    FETCH_USER_SERVICE_REGISTRATION_FAILURE,
    FETCH_TEMPLE_USERS_BY_ST_REQUEST,
    FETCH_TEMPLE_USERS_BY_ST_SUCESS,
    FETCH_TEMPLE_USERS_BY_ST_FAILURE,
    GET_TEMPLE_DET_IN_OTHER_LANG_REQUEST,
    GET_TEMPLE_DET_IN_OTHER_LANG_SUCCESS,
    GET_TEMPLE_DET_IN_OTHER_LANG_FAILURE,
    FETCH_DAILY_HORO_DETAILS_BY_DATE_REQUEST,
    FETCH_DAILY_HORO_DETAILS_BY_DATE_SUCESS,
    FETCH_DAILY_HORO_DETAILS_BY_DATE_FAILURE,
    FETCH_APP_USERS_REQUEST,
    FETCH_APP_USERS_FAILURE,
    FETCH_APP_USERS_SUCCESS,
    FETCH_BY_ST_DT_TXT_REQUEST,
    FETCH_BY_ST_DT_TXT_SUCESS,
    FETCH_BY_ST_DT_TXT_FAILURE,
    FETCH_CITY_ST_DESCR_LIST_REQUEST,
    FETCH_CITY_ST_DESCR_LIST_SUCESS,
    FETCH_CITY_ST_DESCR_LIST_FAILURE,
    FETCH_TEMPLES_OUTSIDE_IND_LIST_REQUEST,
    FETCH_TEMPLES_OUTSIDE_IND_LIST_SUCESS,
    FETCH_TEMPLES_OUTSIDE_IND_LIST_FAILURE,
    FETCH_WEEKLY_HOROSCOPE_DETAILS_REQUEST,
    FETCH_WEEKLY_HOROSCOPE_DETAILS_SUCESS,
    FETCH_WEEKLY_HOROSCOPE_DETAILS_FAILURE,
    FETCH_ANONOMOUS_USER_REQUEST,
    FETCH_ANONOMOUS_USER_SUCESS,
    FETCH_ANONOMOUS_USER_FAILURE,
    FETCH_PRIEST_REQUEST,
    FETCH_PRIEST_REQUEST_SUCESS,
    FETCH_PRIEST_REQUEST_FAILURE,
    FETCH_MY_SERVICES_LIST_REQUEST,
    FETCH_MY_SERVICES_LIST_FAILURE,
    FETCH_MY_SERVICES_LIST_SUCESS,
    FETCH_RESOURCE_FAVOURITES_LIST_REQUEST,
    FETCH_RESOURCE_FAVOURITES_LIST_SUCESS,
    FETCH_RESOURCE_FAVOURITES_LIST_FAILURE,
    FETCH_RES_BOOKS_REQUEST,
    FETCH_RES_BOOKS_SUCCESS,
    FETCH_RES_BOOKS_FAILURE,
    FETCH_ARTICLES_REQUEST,
    FETCH_ARTICLES_SUCCESS,
    FETCH_ARTICLES_FAILURE,
    FETCH_MANTRAS_REQUEST,
    FETCH_MANTRAS_SUCCESS,
    FETCH_MANTRAS_FAILURE,
    FETCH_AUDIOS_REQUEST,
    FETCH_AUDIOS_SUCCESS,
    FETCH_AUDIOS_FAILURE,
    FETCH_RES_LIVETV_REQUEST,
    FETCH_RES_LIVETV_SUCCESS,
    FETCH_RES_LIVETV_FAILURE,
    FETCH_VIDEOS_REQUEST,
    FETCH_VIDEOS_SUCCESS,
    FETCH_VIDEOS_FAILURE,
    FETCH_TEMPLES_MIN_REQUEST,
    FETCH_TEMPLES_MIN_SUCCESS,
    FETCH_TEMPLES_MIN_FAILURE,
    FETCH_TEMPLE_ADMIN_NOTIFICATIONS_LIST_FAILURE,
    FETCH_TEMPLE_ADMIN_NOTIFICATIONS_LIST_REQUEST,
    FETCH_TEMPLE_ADMIN_NOTIFICATIONS_LIST_SUCESS,
    FETCH_APP_ADMIN_NOTIFICATIONS_LIST_FAILURE,
    FETCH_APP_ADMIN_NOTIFICATIONS_LIST_REQUEST,
    FETCH_APP_ADMIN_NOTIFICATIONS_LIST_SUCESS
}
    from "../conistants/conistants";

const initialState = {
    godNamesLoading: true,
    godNames: null,
    godNamesError: '',
    templesListLoading: true,
    templesList: null,
    templesListError: '',
    templeDetailsLoading: true,
    templeDetails: null,
    templeDetailsError: '',
    1: {}, // Telugu
    5: {}, // English
    templeLatestDetailsLoading: true,
    templeLatestDetails: null,
    templeLatestDetailsError: '',
    templeDetailsInOtherLangLoading: true,
    templeDetailsInOtherLang: null,
    templeDetailsInOtherLangError: '',
    contributionsLoading: true,
    contributions: null,
    contributionsError: '',
    notificationsLoading: true,
    notifications: null,
    notificationsError: '',
    postFavouritesLoading: true,
    postFavourites: null,
    postFavouritesError: null,
    deleteFavouritesLoading: true,
    deleteFavourites: null,
    deleteFavouritesError: null,
    favouritesLoading: true,
    favourites: null,
    favouritesError: null,
    resourceFavouritesLoading: true,
    resourceFavourites: null,
    resourceFavouritesError: null,
    booksFavouritesLoading: true,
    booksFavourites: null,
    booksFavouritesError: null,
    templeFavouritesLoading: true,
    templeFavourites: null,
    templeFavouritesError: null,
    postTemplesLoading: true,
    postTemples: null,
    postTemplesError: null,
    editTemplesLoading: true,
    editTemples: null,
    editTemplesError: null,
    validUserLoginLoading: true,
    validUserLogin: null,
    validUserLoginError: null,
    postPasswordRequestLoading: true,
    postPassword: null,
    postPasswordRequestError: null,
    postRegisterUserLoading: true,
    postRegisterUser: null,
    postRegisterUserError: null,
    articlesLoading: true,
    articlesData: [],
    articlesError: null,
    liveTvLoading: true,
    liveTv: null,
    liveTvError: null,
    videoLinksLoading: true,
    videoLinks: null,
    videoLinksError: null,
    postSetNewPasswordLoading: true,
    postSetNewPassword: null,
    postSetNewPasswordError: null,
    postUploadImageLoading: true,
    postUploadImage: null,
    postUploadImageError: null,
    booksLoading: true,
    books: null,
    booksError: null,
    dailyHoroscopeLoading: true,
    dailyHoroscope: null,
    dailyHoroscopeError: null,
    weeklyHoroscopeLoading: true,
    weeklyHoroscope: null,
    weeklyHoroscopeError: null,
    editLangLoading: true,
    editLang: null,
    editLangError: null,
    editGenderLoading: true,
    editGender: null,
    editGenderError: null,
    editCityLoading: true,
    editCity: null,
    editCityError: null,
    userDetailsLoading: true,
    userDetails: null,
    userDetailsError: null,
    nearestLoading: true,
    nearest: null,
    nearestError: null,
    filterByGodLoading: true,
    filterByGod: null,
    filterByGodError: '',
    filterByStDtLoading: true,
    filterByStDt: null,
    filterByStDtError: null,
    filterByStDtTxtLoading: false,
    filterByStDtTxt: null,
    filterByStDtTxtError: null,
    templeGroupsLoading: true,
    templeGroups: null,
    templeGroupsError: null,
    byStDtLoading: true,
    byStDt: null,
    byStDtError: null,
    filterNearestLoading: true,
    filterNearest: null,
    filterNearestError: null,
    cityTemplesListLoading: true,
    cityTemplesList: null,
    cityTemplesListError: null,

    cityStDescrListLoading: true,
    cityStDescrList: null,
    cityStDescrListError: null,
    templeTxtLoading: true,
    templeTxt: null,
    templeTxtError: null,
    resourceLoading: true,
    resource: null,
    resourceError: null,
    resourceDetailsLoading: true,
    resourceDetails: null,
    resourceDetailsError: '',
    resourceByTxtLoading: true,
    resourceByTxt: null,
    resourceByTxtError: null,
    templeGroupLoading: true,
    templeGroup: null,
    templeGroupError: null,
    templeBooksLoading: true,
    templeBooks: null,
    templeBooksError: null,
    templeAudioLoading: true,
    templeAudio: null,
    templeAudioError: null,
    templeVideoLoading: true,
    templeVideo: null,
    templeVideoError: null,
    templeMantrasLoading: true,
    templeMantras: null,
    templeMantrasError: null,
    templeNotifiLoading: true,
    templeNotifi: null,
    templeNotifiError: null,
    groupDescriptionLoading: true,
    groupDescription: null,
    groupDescriptionError: null,
    refreshTokenLoading: true,
    refreshToken: null,
    refreshTokenError: null,
    recentlyViewedLoading: true,
    recentlyViewed: null,
    recentlyViewedError: null,
    regionalNotifiLoading: true,
    regionalNotifi: null,
    regionalNotifiError: null,
    booksByTagLoading: true,
    booksByTag: null,
    booksByTagError: null,
    dailyHoroscopeDetailsLoading: true,
    dailyHoroscopeDetails: null,
    dailyHoroscopeDetailsError: null,
    horoDetailsByDtLoading: true,
    horoDetailsByDt: null,
    horoDetailsByDtError: null,
    weeklyHoroscopeDetailsLoading: true,
    weeklyHoroscopeDetails: null,
    weeklyHoroscopeDetailsError: null,
    dailyHoroscopeListLoading: true,
    dailyHoroscopeList: null,
    dailyHoroscopeListError: null,
    weeklyHoroscopeListLoading: true,
    weeklyHoroscopeList: null,
    weeklyHoroscopeListError: null,
    notificationDetailsLoading: true,
    notificationDetails: null,
    notificationDetailsError: '',
    donationsListLoading: true,
    donationsList: null,
    donationsListError: null,
    donationsDetailsLoading: true,
    donationsDetails: null,
    donationsDetailsError: null,
    donationsByMonthLoading: true,
    donationsByMonth: null,
    donationsByMonthError: null,
    expensesListLoading: true,
    expensesList: null,
    expensesListError: null,
    expensesDetailsLoading: true,
    expensesDetails: null,
    expensesDetailsError: null,
    templeGroupDetailsLoading: true,
    templeGroupDetails: null,
    templeGroupDetailsError: null,
    priestDetails: null,
    priestDetailsLoading: false,
    priestDetailsError: '',
    priestBySpcltyLoading: true,
    priestBySpclty: null,
    priestBySpcltyError: null,
    myRequestsLoading: true,
    myRequests: null,
    myRequestsError: null,
    myRequestsDetailsLoading: true,
    myRequestsDetails: null,
    myRequestsDetailsError: null,
    priestRequestsLoading: true,
    priestRequests: null,
    priestRequestsError: null,
    servicesByUserLoading: true,
    servicesByUser: null,
    servicesByUserError: null,
    servicesByAdminLoading: true,
    servicesByAdmin: null,
    servicesByAdminError: null,
    servicesDetailsLoading: true,
    servicesDetails: null,
    servicesDetailsError: null,
    templeDashboardListLoading: true,
    templeDashboardList: null,
    templeDashboardListError: null,
    templeMembersListLoading: true,
    templeMembersList: null,
    templeMembersListError: null,
    pendingTemplesListLoading: true,
    pendingTemplesList: null,
    pendingTemplesListError: null,
    templeServiceRegistrationLoading: true,
    templeServiceRegistration: null,
    templeServiceRegistrationError: null,
    serviceRegDetailsLoading: true,
    serviceRegDetails: null,
    serviceRegDetailsError: null,
    userServiceRegistrationLoading: true,
    userServiceRegistration: null,
    userServiceRegistrationError: null,
    templeUsersByStLoading: true,
    templeUsersBySt: null,
    templeUsersByStError: null,
    appUsersLoading: true,
    appUsers: null,
    appUsersError: null,
    templesOutsideIndLoading: true,
    templesOutsideInd: null,
    templesOutsideIndError: null,
    anonomousUserLoading: true,
    anonomousUser: null,
    anonomousUserError: null,
    myServicesLoading: true,
    myServices: null,
    myServicesError: null,
    templeServicesRegLoading: true,
    templeServicesReg: null,
    templeServicesRegError: null,
    resBooksLoading: true,
    resBooks: null,
    resBooksError: null,
    resArticlesLoading: true,
    resArticles: null,
    resArticlesError: null,
    resMantrasLoading: true,
    resMantras: null,
    resMantrasError: null,
    resAudiosLoading: true,
    resAudios: null,
    resAudiosError: null,
    resLivetvLoading: true,
    resLivetv: null,
    resLivetvError: null,
    resVideosLoading: true,
    resVideos: null,
    resVideosError: null,
    templeMinDetailsLoading: true,
    templeMinDetails: null,
    templeMinDetailsError: null,
    templeAdminNotificationsLoading: true,
    templeAdminNotifications: null,
    templeAdminNotificationsError: '',
    appAdminnotificationsLoading: true,
    appAdminnotifications: null,
    appAdminnotificationsError: '',
};



export const templeServiceRegReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLESERVICEREG_LIST_REQUEST:
            return {
                ...state,
                templeServicesRegLoading: true
            }
        case FETCH_TEMPLESERVICEREG_LIST_SUCESS:
            return {
                templeServicesRegLoading: false,
                templeServicesReg: action.payload,
                templeServicesRegError: ''
            }
        case FETCH_TEMPLESERVICEREG_LIST_FAILURE:
            return {
                templeServicesRegLoading: false,
                templeServicesReg: null,
                templeServicesRegError: action.payload
            }

        default:
            return state;
    }
};

export const templeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLES_LIST_REQUEST:
            return {
                ...state,
                templesListLoading: true
            }
        case FETCH_TEMPLES_LIST_SUCCESS:
            return {
                templesListLoading: false,
                templesList: action.payload,
                templesListError: ''
            }
        case FETCH_TEMPLES_LIST_FAILURE:
            return {
                templesListLoading: false,
                templesList: null,
                templesListError: action.payload
            }

        default:
            return state;
    }
};

export const godNamesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GOD_NAMES_LIST_REQUEST:
            return {
                ...state,
                godNamesLoading: true
            }
        case FETCH_GOD_NAMES_LIST_SUCESS:
            return {
                godNamesLoading: false,
                godNames: action.payload,
                godNamesError: ''
            }
        case FETCH_GOD_NAMES_LIST_FAILURE:
            return {
                godNamesLoading: false,
                godNames: null,
                godNamesError: action.payload
            }

        default:
            return state;
    }
};

export const myServicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MY_SERVICES_LIST_REQUEST:
            return {
                ...state,
                myServicesLoading: true
            }
        case FETCH_MY_SERVICES_LIST_SUCESS:
            return {
                myServicesLoading: false,
                myServices: action.payload,
                myServicesError: ''
            }
        case FETCH_MY_SERVICES_LIST_FAILURE:
            return {
                myServicesLoading: false,
                myServices: null,
                myServicesError: action.payload
            }

        default:
            return state;
    }
};

export const templeDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEMPLE_DET_REQUEST:
            return {
                ...state,
                templeDetailsLoading: true
            }
        case GET_TEMPLE_DET_SUCCESS:
            return {
                ...state,
                [action.payload.languageId]: action.payload.data,
                templeDetailsLoading: false,
                templeDetails: action.payload,
                templeDetailsError: ''
            }
        case GET_TEMPLE_DET_FAILURE:
            return {
                templeDetailsLoading: false,
                templeDetails: null,
                templeDetailsError: action.payload
            }

        default:
            return state;
    }
};

export const templeDetailsInOtherLangReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEMPLE_DET_IN_OTHER_LANG_REQUEST:
            return {
                ...state,
                templeDetailsInOtherLangLoading: true
            }
        case GET_TEMPLE_DET_IN_OTHER_LANG_SUCCESS:
            return {
                ...state,
                [action.payload.languageId]: action.payload.data,
                templeDetailsInOtherLangLoading: false,
                templeDetailsInOtherLang: action.payload,
                templeDetailsInOtherLangError: ''
            }
        case GET_TEMPLE_DET_IN_OTHER_LANG_FAILURE:
            return {
                templeDetailsInOtherLangLoading: false,
                templeDetailsInOtherLang: null,
                templeDetailsInOtherLangError: action.payload
            }

        default:
            return state;
    }
};

export const templeLatestDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LATEST_TEMPLE_DET_REQUEST:
            return {
                ...state,
                templeLatestDetailsLoading: true
            }
        case GET_LATEST_TEMPLE_DET_SUCCESS:
            return {
                templeLatestDetailsLoading: false,
                templeLatestDetails: action.payload,
                templeLatestDetailsError: ''
            }
        case GET_LATEST_TEMPLE_DET_FAILURE:
            return {
                templeLatestDetailsLoading: false,
                templeLatestDetails: null,
                templeLatestDetailsError: action.payload
            }

        default:
            return state;
    }
};

export const contributionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONTRIBUTIONS_LIST_REQUEST:
            return {
                ...state,
                contributionsLoading: true
            }
        case FETCH_CONTRIBUTIONS_LIST_SUCESS:
            return {
                contributionsLoading: false,
                contributions: action.payload,
                contributionsError: ''
            }
        case FETCH_CONTRIBUTIONS_LIST_FAILURE:
            return {
                contributionsLoading: false,
                contributions: null,
                contributionsError: action.payload
            }

        default:
            return state;
    }
};

export const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS_LIST_REQUEST:
            return {
                ...state,
                notificationsLoading: true
            }
        case FETCH_NOTIFICATIONS_LIST_SUCESS:
            return {
                notificationsLoading: false,
                notifications: action.payload,
                notificationsError: ''
            }
        case FETCH_NOTIFICATIONS_LIST_FAILURE:
            return {
                notificationsLoading: false,
                notifications: null,
                notificationsError: action.payload
            }

        default:
            return state;
    }
};

export const templeAdminNotificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLE_ADMIN_NOTIFICATIONS_LIST_REQUEST:
            return {
                ...state,
                templeAdminNotificationsLoading: true
            }
        case FETCH_TEMPLE_ADMIN_NOTIFICATIONS_LIST_SUCESS:
            return {
                templeAdminNotificationsLoading: false,
                templeAdminNotifications: action.payload,
                templeAdminNotificationsError: ''
            }
        case FETCH_TEMPLE_ADMIN_NOTIFICATIONS_LIST_FAILURE:
            return {
                templeAdminNotificationsLoading: false,
                templeAdminNotifications: null,
                templeAdminNotificationsError: action.payload
            }

        default:
            return state;
    }
};

export const appAdminnotificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_APP_ADMIN_NOTIFICATIONS_LIST_REQUEST:
            return {
                ...state,
                appAdminnotificationsLoading: true
            }
        case FETCH_APP_ADMIN_NOTIFICATIONS_LIST_SUCESS:
            return {
                appAdminnotificationsLoading: false,
                appAdminnotifications: action.payload,
                appAdminnotificationsError: ''
            }
        case FETCH_APP_ADMIN_NOTIFICATIONS_LIST_FAILURE:
            return {
                appAdminnotificationsLoading: false,
                appAdminnotifications: null,
                appAdminnotificationsError: action.payload
            }

        default:
            return state;
    }
};


export const notificationDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS_DETAILS_REQUEST:
            return {
                ...state,
                notificationDetailsLoading: true
            }
        case FETCH_NOTIFICATIONS_DETAILS_SUCESS:
            return {
                notificationDetailsLoading: false,
                notificationDetails: action.payload,
                notificationDetailsError: ''
            }
        case FETCH_NOTIFICATIONS_DETAILS_FAILURE:
            return {
                notificationDetailsLoading: false,
                notificationDetails: null,
                notificationDetailsError: action.payload
            }

        default:
            return state;
    }
};

export const filterByGodReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FILTER_BY_GOD_REQUEST:
            return {
                ...state,
                filterByGodLoading: true
            }
        case FETCH_FILTER_BY_GOD_SUCESS:
            return {
                filterByGodLoading: false,
                filterByGod: action.payload,
                filterByGodError: ''
            }
        case FETCH_FILTER_BY_GOD_FAILURE:
            return {
                filterByGodLoading: false,
                filterByGod: null,
                filterByGodError: action.payload
            }

        default:
            return state;
    }
};

export const donationsListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DONATIONS_LIST_REQUEST:
            return {
                ...state,
                donationsListLoading: true
            }
        case FETCH_DONATIONS_LIST_SUCESS:
            return {
                donationsListLoading: false,
                donationsList: action.payload,
                donationsListError: ''
            }
        case FETCH_DONATIONS_LIST_FAILURE:
            return {
                donationsListLoading: false,
                donationsList: null,
                donationsListError: action.payload
            }

        default:
            return state;
    }
};

export const GetTempleUsersBySt = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLE_USERS_BY_ST_REQUEST:
            return {
                ...state,
                templeUsersByStLoading: true
            }
        case FETCH_TEMPLE_USERS_BY_ST_SUCESS:
            return {
                templeUsersByStLoading: false,
                templeUsersBySt: action.payload,
                templeUsersByStError: ''
            }
        case FETCH_TEMPLE_USERS_BY_ST_FAILURE:
            return {
                templeUsersByStLoading: false,
                templeUsersBySt: null,
                templeUsersByStError: action.payload
            }

        default:
            return state;
    }
};

export const donationsByMonthReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DONATIONS_BY_MONTH_REQUEST:
            return {
                ...state,
                donationsByMonthLoading: true
            }
        case FETCH_DONATIONS_BY_MONTH_SUCESS:
            return {
                donationsByMonthLoading: false,
                donationsByMonth: action.payload,
                donationsByMonthError: ''
            }
        case FETCH_DONATIONS_BY_MONTH_FAILURE:
            return {
                donationsByMonthLoading: false,
                donationsByMonth: null,
                donationsByMonthError: action.payload
            }

        default:
            return state;
    }
};

export const donationsDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DONATIONS_DETAILS_REQUEST:
            return {
                ...state,
                donationsDetailsLoading: true
            }
        case FETCH_DONATIONS_DETAILS_SUCESS:
            return {
                donationsDetailsLoading: false,
                donationsDetails: action.payload,
                donationsDetailsError: ''
            }
        case FETCH_DONATIONS_DETAILS_FAILURE:
            return {
                donationsDetailsLoading: false,
                donationsDetails: null,
                donationsDetailsError: action.payload
            }

        default:
            return state;
    }
};

export const templeDashboardListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLE_DASHBOARD_LIST_REQUEST:
            return {
                ...state,
                templeDashboardListLoading: true
            }
        case FETCH_TEMPLE_DASHBOARD_LIST_SUCESS:
            return {
                templeDashboardListLoading: false,
                templeDashboardList: action.payload,
                templeDashboardListError: ''
            }
        case FETCH_TEMPLE_DASHBOARD_LIST_FAILURE:
            return {
                templeDashboardListLoading: false,
                templeDashboardList: null,
                templeDashboardListError: action.payload
            }

        default:
            return state;
    }
};

export const pendingTemplesListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PENDING_TEMPLES_LIST_REQUEST:
            return {
                ...state,
                pendingTemplesListLoading: true
            }
        case FETCH_PENDING_TEMPLES_LIST_SUCESS:
            return {
                pendingTemplesListLoading: false,
                pendingTemplesList: action.payload,
                pendingTemplesListError: ''
            }
        case FETCH_PENDING_TEMPLES_LIST_FAILURE:
            return {
                pendingTemplesListLoading: false,
                pendingTemplesList: null,
                pendingTemplesListError: action.payload
            }

        default:
            return state;
    }
};

export const templeMembersListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLE_MEMBERS_LIST_REQUEST:
            return {
                ...state,
                templeMembersListLoading: true
            }
        case FETCH_TEMPLE_MEMBERS_LIST_SUCESS:
            return {
                templeMembersListLoading: false,
                templeMembersList: action.payload,
                templeMembersListError: ''
            }
        case FETCH_TEMPLE_MEMBERS_LIST_FAILURE:
            return {
                templeMembersListLoading: false,
                templeMembersList: null,
                templeMembersListError: action.payload
            }

        default:
            return state;
    }
};


export const expensesListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EXPENSES_LIST_REQUEST:
            return {
                ...state,
                expensesListLoading: true
            }
        case FETCH_EXPENSES_LIST_SUCESS:
            return {
                expensesListLoading: false,
                expensesList: action.payload,
                expensesListError: ''
            }
        case FETCH_EXPENSES_LIST_FAILURE:
            return {
                expensesListLoading: false,
                expensesList: null,
                expensesListError: action.payload
            }

        default:
            return state;
    }
};

export const expensesDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EXPENSES_DETAILS_REQUEST:
            return {
                ...state,
                expensesDetailsLoading: true
            }
        case FETCH_EXPENSES_DETAILS_SUCESS:
            return {
                expensesDetailsLoading: false,
                expensesDetails: action.payload,
                expensesDetailsError: ''
            }
        case FETCH_EXPENSES_DETAILS_FAILURE:
            return {
                expensesDetailsLoading: false,
                expensesDetails: null,
                expensesDetailsError: action.payload
            }

        default:
            return state;
    }
};

export const favouritesPostReducer = (state = initialState, action) => {
    switch (action.type) {
        case POST_FAVOURITES_LIST_REQUEST:
            return {
                ...state,
                postFavouritesLoading: true
            }
        case POST_FAVOURITES_LIST_SUCESS:
            return {
                postFavouritesLoading: false,
                postFavourites: action.payload,
                postFavouritesError: ''
            }
        case POST_FAVOURITES_LIST_FAILURE:
            return {
                postFavouritesLoading: false,
                postFavourites: null,
                postFavouritesError: action.payload
            }

        default:
            return state;
    }
};

export const favouritesDeleteReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_FAVOURITES_LIST_REQUEST:
            return {
                ...state,
                deleteFavouritesLoading: true
            }
        case DELETE_FAVOURITES_LIST_SUCESS:
            return {
                deleteFavouritesLoading: false,
                deleteFavourites: action.payload,
                deleteFavouritesError: ''
            }
        case DELETE_FAVOURITES_LIST_FAILURE:
            return {
                deleteFavouritesLoading: false,
                deleteFavourites: null,
                deleteFavouritesError: action.payload
            }

        default:
            return state;
    }
};

export const favouritesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FAVOURITES_LIST_REQUEST:
            return {
                ...state,
                favouritesLoading: true
            }
        case FETCH_FAVOURITES_LIST_SUCESS:
            return {
                favouritesLoading: false,
                favourites: action.payload,
                favouritesError: ''
            }
        case FETCH_FAVOURITES_LIST_FAILURE:
            return {
                favouritesLoading: false,
                favourites: null,
                favouritesError: action.payload
            }

        default:
            return state;
    }
};

export const resourceFavouritesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOURCE_FAVOURITES_LIST_REQUEST:
            return {
                ...state,
                resourceFavouritesLoading: true
            }
        case FETCH_RESOURCE_FAVOURITES_LIST_SUCESS:
            return {
                resourceFavouritesLoading: false,
                resourceFavourites: action.payload,
                resourceFavouritesError: ''
            }
        case FETCH_RESOURCE_FAVOURITES_LIST_FAILURE:
            return {
                resourceFavouritesLoading: false,
                resourceFavourites: null,
                resourceFavouritesError: action.payload
            }

        default:
            return state;
    }
};

export const resourceArticlesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ARTICLES_REQUEST:
            return {
                ...state,
                resArticlesLoading: true
            }
        case FETCH_ARTICLES_SUCCESS:
            return {
                resArticlesLoading: false,
                resArticles: action.payload,
                resArticlesError: ''
            }
        case FETCH_ARTICLES_FAILURE:
            return {
                resArticlesLoading: false,
                resArticles: null,
                resArticlesError: action.payload
            }

        default:
            return state;
    }
};

export const resourceMantrasReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MANTRAS_REQUEST:
            return {
                ...state,
                resMantrasLoading: true
            }
        case FETCH_MANTRAS_SUCCESS:
            return {
                resMantrasLoading: false,
                resMantras: action.payload,
                resMantrasError: ''
            }
        case FETCH_MANTRAS_FAILURE:
            return {
                resMantrasLoading: false,
                resMantras: null,
                resMantrasError: action.payload
            }

        default:
            return state;
    }
};

export const resourceAudiosReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AUDIOS_REQUEST:
            return {
                ...state,
                resAudiosLoading: true
            }
        case FETCH_AUDIOS_SUCCESS:
            return {
                resAudiosLoading: false,
                resAudios: action.payload,
                resAudiosError: ''
            }
        case FETCH_AUDIOS_FAILURE:
            return {
                resAudiosLoading: false,
                resAudios: null,
                resAudiosError: action.payload
            }

        default:
            return state;
    }
};

export const resourceLivetvReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RES_LIVETV_REQUEST:
            return {
                ...state,
                resLivetvLoading: true
            }
        case FETCH_RES_LIVETV_SUCCESS:
            return {
                resLivetvLoading: false,
                resLivetv: action.payload,
                resLivetvError: ''
            }
        case FETCH_RES_LIVETV_FAILURE:
            return {
                resLivetvLoading: false,
                resLivetv: null,
                resLivetvError: action.payload
            }

        default:
            return state;
    }
};

export const resourceVideosReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VIDEOS_REQUEST:
            return {
                ...state,
                resVideosLoading: true
            }
        case FETCH_VIDEOS_SUCCESS:
            return {
                resVideosLoading: false,
                resVideos: action.payload,
                resVideosError: ''
            }
        case FETCH_VIDEOS_FAILURE:
            return {
                resVideosLoading: false,
                resVideos: null,
                resVideosError: action.payload
            }

        default:
            return state;
    }
};

export const priestBySpcltyReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRIEST_BY_SPCLTY_REQUEST:
            return {
                ...state,
                priestBySpcltyLoading: true
            }
        case FETCH_PRIEST_BY_SPCLTY_SUCESS:
            return {
                priestBySpcltyLoading: false,
                priestBySpclty: action.payload,
                priestBySpcltyError: ''
            }
        case FETCH_PRIEST_BY_SPCLTY_FAILURE:
            return {
                priestBySpcltyLoading: false,
                priestBySpclty: null,
                priestBySpcltyError: action.payload
            }

        default:
            return state;
    }
};

export const myRequestsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MY_REQUEST:
            return {
                ...state,
                myRequestsLoading: true
            }
        case FETCH_MY_SUCESS:
            return {
                myRequestsLoading: false,
                myRequests: action.payload,
                myRequestsError: ''
            }
        case FETCH_MY_FAILURE:
            return {
                myRequestsLoading: false,
                myRequests: null,
                myRequestsError: action.payload
            }

        default:
            return state;
    }
};

export const priestRequestsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRIEST_REQUEST:
            return {
                ...state,
                priestRequestsLoading: true
            }
        case FETCH_PRIEST_REQUEST_SUCESS:
            return {
                priestRequestsLoading: false,
                priestRequests: action.payload,
                priestRequestsError: ''
            }
        case FETCH_PRIEST_REQUEST_FAILURE:
            return {
                priestRequestsLoading: false,
                priestRequests: null,
                priestRequestsError: action.payload
            }

        default:
            return state;
    }
};

export const templeMinDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLES_MIN_REQUEST:
            return {
                ...state,
                templeMinDetailsLoading: true
            }
        case FETCH_TEMPLES_MIN_SUCCESS:
            return {
                templeMinDetailsLoading: false,
                templeMinDetails: action.payload,
                templeMinDetailsError: ''
            }
        case FETCH_TEMPLES_MIN_FAILURE:
            return {
                templeMinDetailsLoading: false,
                templeMinDetails: null,
                templeMinDetailsError: action.payload
            }

        default:
            return state;
    }
};


export const myRequestsDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRIEST_REQ_DETAILS_REQUEST:
            return {
                ...state,
                myRequestsDetailsLoading: true
            }
        case FETCH_PRIEST_REQ_DETAILS_SUCESS:
            return {
                myRequestsDetailsLoading: false,
                myRequestsDetails: action.payload,
                myRequestsDetailsError: ''
            }
        case FETCH_PRIEST_REQ_DETAILS_FAILURE:
            return {
                myRequestsDetailsLoading: false,
                myRequestsDetails: null,
                myRequestsError: action.payload
            }

        default:
            return state;
    }
};

export const booksFavouritesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BOOKS_FAVOURITES_LIST_REQUEST:
            return {
                ...state,
                booksFavouritesLoading: true
            }
        case FETCH_BOOKS_FAVOURITES_LIST_SUCESS:
            return {
                booksFavouritesLoading: false,
                booksFavourites: action.payload,
                booksFavouritesError: ''
            }
        case FETCH_BOOKS_FAVOURITES_LIST_FAILURE:
            return {
                booksFavouritesLoading: false,
                booksFavourites: null,
                booksFavouritesError: action.payload
            }

        default:
            return state;
    }
};

export const templeFavouritesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLE_FAVOURITES_LIST_REQUEST:
            return {
                ...state,
                templeFavouritesLoading: true
            }
        case FETCH_TEMPLE_FAVOURITES_LIST_SUCESS:
            return {
                templeFavouritesLoading: false,
                templeFavourites: action.payload,
                templeFavouritesError: ''
            }
        case FETCH_TEMPLE_FAVOURITES_LIST_FAILURE:
            return {
                templeFavouritesLoading: false,
                templeFavourites: null,
                templeFavouritesError: action.payload
            }

        default:
            return state;
    }
};

export const templesPostReducer = (state = initialState, action) => {
    switch (action.type) {
        case POST_TEMPLES_LIST_REQUEST:
            return {
                ...state,
                postTemplesLoading: true
            }
        case POST_TEMPLES_LIST_SUCESS:
            return {
                postTemplesLoading: false,
                postTemples: action.payload,
                postTemplesError: ''
            }
        case POST_TEMPLES_LIST_FAILURE:
            return {
                postTemplesLoading: false,
                postTemples: null,
                postTemplesError: action.payload
            }

        default:
            return state;
    }
};

export const templesEditReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_TEMPLES_LIST_REQUEST:
            return {
                ...state,
                editTemplesLoading: true
            }
        case EDIT_TEMPLES_LIST_SUCESS:
            return {
                editTemplesLoading: false,
                editTemples: action.payload,
                editTemplesError: ''
            }
        case EDIT_TEMPLES_LIST_FAILURE:
            return {
                editTemplesLoading: false,
                editTemples: null,
                editTemplesError: action.payload
            }

        default:
            return state;
    }
};

export const validUserLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_LOGIN_REQUEST:
            return {
                ...state,
                validUserLoginLoading: true
            }
        case FETCH_USER_LOGIN_SUCESS:

            return {
                validUserLoginLoading: false,
                validUserLogin: action.payload,
                validUserLoginError: ''
            }
        case FETCH_USER_LOGIN_FAILURE:
            return {
                validUserLoginLoading: false,
                validUserLogin: null,
                validUserLoginError: action.payload
            }

        default:
            return state;
    }
};

export const refreshTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REFRESH_TOKEN_REQUEST:
            return {
                ...state,
                refreshTokenLoading: true
            }
        case FETCH_REFRESH_TOKEN_SUCESS:

            return {
                refreshTokenLoading: false,
                refreshToken: action.payload,
                refreshTokenError: ''
            }
        case FETCH_REFRESH_TOKEN_FAILURE:
            return {
                refreshTokenLoading: false,
                refreshToken: null,
                refreshTokenError: action.payload
            }

        default:
            return state;
    }
};

export const recentlyViewedReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RECENTLY_VIEWED_REQUEST:
            return {
                ...state,
                recentlyViewedLoading: true
            }
        case FETCH_RECENTLY_VIEWED_SUCESS:

            return {
                recentlyViewedLoading: false,
                recentlyViewed: action.payload,
                recentlyViewedError: ''
            }
        case FETCH_RECENTLY_VIEWED_FAILURE:
            return {
                recentlyViewedLoading: false,
                recentlyViewed: null,
                recentlyViewedError: action.payload
            }

        default:
            return state;
    }
};

export const passwordRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case POST_REQUEST_PASSWORD_REQUEST:
            return {
                ...state,
                postPasswordRequestLoading: true
            }
        case POST_REQUEST_PASSWORD_SUCESS:
            return {
                postPasswordRequestLoading: false,
                postPassword: action.payload,
                postPasswordRequestError: ''
            }
        case POST_REQUEST_PASSWORD_FAILURE:
            return {
                postPasswordRequestLoading: false,
                postPassword: null,
                postPasswordRequestError: action.payload
            }

        default:
            return state;
    }
};

export const registerUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case POST_REGISTER_USER_REQUEST:
            return {
                ...state,
                postRegisterUserLoading: true
            }
        case POST_REGISTER_USER_SUCESS:
            return {
                postRegisterUserLoading: false,
                postRegisterUser: action.payload,
                postRegisterUserError: ''
            }
        case POST_REGISTER_USER_FAILURE:
            return {
                postRegisterUserLoading: false,
                postRegisterUser: null,
                postRegisterUserError: action.payload
            }

        default:
            return state;
    }
};



export const liveTvReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LIVETV_REQUEST:
            return {
                ...state,
                liveTvLoading: true
            }
        case FETCH_LIVETV_SUCESS:
            return {
                liveTvLoading: false,
                liveTv: action.payload,
                liveTvError: ''
            }
        case FETCH_LIVETV_FAILURE:
            return {
                liveTvLoading: false,
                liveTv: null,
                liveTvError: action.payload
            }

        default:
            return state;
    }
};

export const booksReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BOOKS_REQUEST:
            return {
                ...state,
                booksLoading: true
            }
        case FETCH_BOOKS_SUCESS:
            return {
                booksLoading: false,
                books: action.payload,
                booksError: ''
            }
        case FETCH_BOOKS_FAILURE:
            return {
                booksLoading: false,
                books: null,
                booksError: action.payload
            }

        default:
            return state;
    }
};

export const booksByTagReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BOOKS_BY_TAG_REQUEST:
            return {
                ...state,
                booksByTagLoading: true
            }
        case FETCH_BOOKS_BY_TAG_SUCESS:
            return {
                booksByTagLoading: false,
                booksByTag: action.payload,
                booksByTagError: ''
            }
        case FETCH_BOOKS_BY_TAG_FAILURE:
            return {
                booksByTagLoading: false,
                booksByTag: null,
                booksByTagError: action.payload
            }

        default:
            return state;
    }
};

export const videoLinksReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VIDEO_LINKS_REQUEST:
            return {
                ...state,
                videoLinksLoading: true
            }
        case FETCH_VIDEO_LINKS_SUCESS:
            return {
                videoLinksLoading: false,
                videoLinks: action.payload,
                videoLinksError: ''
            }
        case FETCH_VIDEO_LINKS_FAILURE:
            return {
                videoLinksLoading: false,
                videoLinks: null,
                videoLinksError: action.payload
            }

        default:
            return state;
    }
};

export const dailyHoroscopeListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DAILY_HOROSCOPE_LIST_REQUEST:
            return {
                ...state,
                dailyHoroscopeListLoading: true
            }
        case FETCH_DAILY_HOROSCOPE_LIST_SUCESS:
            return {
                dailyHoroscopeListLoading: false,
                dailyHoroscopeList: action.payload,
                dailyHoroscopeListError: ''
            }
        case FETCH_DAILY_HOROSCOPE_LIST_FAILURE:
            return {
                dailyHoroscopeListLoading: false,
                dailyHoroscopeList: null,
                dailyHoroscopeListError: action.payload
            }

        default:
            return state;
    }
};

export const weeklyHoroscopeListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WEEKLY_HOROSCOPE_LIST_REQUEST:
            return {
                ...state,
                weeklyHoroscopeListLoading: true
            }
        case FETCH_WEEKLY_HOROSCOPE_LIST_SUCESS:
            return {
                weeklyHoroscopeListLoading: false,
                weeklyHoroscopeList: action.payload,
                weeklyHoroscopeListError: ''
            }
        case FETCH_WEEKLY_HOROSCOPE_LIST_FAILURE:
            return {
                weeklyHoroscopeListLoading: false,
                weeklyHoroscopeList: null,
                weeklyHoroscopeListError: action.payload
            }

        default:
            return state;
    }
};

export const dailyHoroscopeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DAILY_HOROSCOPE_REQUEST:
            return {
                ...state,
                dailyHoroscopeLoading: true
            }
        case FETCH_DAILY_HOROSCOPE_SUCESS:
            return {
                dailyHoroscopeLoading: false,
                dailyHoroscope: action.payload,
                dailyHoroscopeError: ''
            }
        case FETCH_DAILY_HOROSCOPE_FAILURE:
            return {
                dailyHoroscopeLoading: false,
                dailyHoroscope: null,
                dailyHoroscopeError: action.payload
            }

        default:
            return state;
    }
};

export const dailyHoroscopeDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DAILY_HOROSCOPE_DETAILS_REQUEST:
            return {
                ...state,
                dailyHoroscopeDetailsLoading: true
            }
        case FETCH_DAILY_HOROSCOPE_DETAILS_SUCESS:
            return {
                dailyHoroscopeDetailsLoading: false,
                dailyHoroscopeDetails: action.payload,
                dailyHoroscopeDetailsError: ''
            }
        case FETCH_DAILY_HOROSCOPE_DETAILS_FAILURE:
            return {
                dailyHoroscopeLoading: false,
                dailyHoroscope: null,
                dailyHoroscopeError: action.payload
            }

        default:
            return state;
    }
};

export const weeklyHoroscopeDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WEEKLY_HOROSCOPE_DETAILS_REQUEST:
            return {
                ...state,
                weeklyHoroscopeDetailsLoading: true
            }
        case FETCH_WEEKLY_HOROSCOPE_DETAILS_SUCESS:
            return {
                weeklyHoroscopeDetailsLoading: false,
                weeklyHoroscopeDetails: action.payload,
                weeklyHoroscopeDetailsError: ''
            }
        case FETCH_WEEKLY_HOROSCOPE_DETAILS_FAILURE:
            return {
                weeklyHoroscopeLoading: false,
                weeklyHoroscope: null,
                weeklyHoroscopeError: action.payload
            }

        default:
            return state;
    }
};

export const horoscopeDetailsByDtReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DAILY_HORO_DETAILS_BY_DATE_REQUEST:
            return {
                ...state,
                horoDetailsByDtLoading: true
            }
        case FETCH_DAILY_HORO_DETAILS_BY_DATE_SUCESS:
            return {
                horoDetailsByDtLoading: false,
                horoDetailsByDt: action.payload,
                horoDetailsByDtError: ''
            }
        case FETCH_DAILY_HORO_DETAILS_BY_DATE_FAILURE:
            return {
                horoDetailsByDtLoading: false,
                horoDetailsByDt: null,
                horoDetailsByDtError: action.payload
            }

        default:
            return state;
    }
};

export const weeklyHoroscopeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WEEKLY_HOROSCOPE_REQUEST:
            return {
                ...state,
                weeklyHoroscopeLoading: true
            }
        case FETCH_WEEKLY_HOROSCOPE_SUCESS:
            return {
                weeklyHoroscopeLoading: false,
                weeklyHoroscope: action.payload,
                weeklyHoroscopeError: ''
            }
        case FETCH_WEEKLY_HOROSCOPE_FAILURE:
            return {
                weeklyHoroscopeLoading: false,
                weeklyHoroscope: null,
                weeklyHoroscopeError: action.payload
            }

        default:
            return state;
    }
};

export const setNewpasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case POST_SET_NEW_PASSWORD_REQUEST:
            return {
                ...state,
                postSetNewPasswordLoading: true
            }
        case POST_SET_NEW_PASSWORD_SUCESS:
            return {
                postSetNewPasswordLoading: false,
                postSetNewPassword: action.payload,
                postSetNewPasswordError: ''
            }
        case POST_SET_NEW_PASSWORD_FAILURE:
            return {
                postSetNewPasswordLoading: false,
                postSetNewPassword: null,
                postSetNewPasswordError: action.payload
            }

        default:
            return state;
    }
};

export const uploadImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case POST_UPLOAD_IMAGE_REQUEST:
            return {
                ...state,
                postUploadImageLoading: true
            }
        case POST_UPLOAD_IMAGE_SUCESS:
            return {
                postUploadImageLoading: false,
                postUploadImage: action.payload,
                postUploadImageError: ''
            }
        case POST_UPLOAD_IMAGE_FAILURE:
            return {
                postUploadImageLoading: false,
                postUploadImage: null,
                postUploadImageError: action.payload
            }

        default:
            return state;
    }
};

export const langEditReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_LANG_LIST_REQUEST:
            return {
                ...state,
                editLangLoading: true
            }
        case EDIT_LANG_LIST_SUCESS:
            return {
                editLangLoading: false,
                editLang: action.payload,
                editLangError: ''
            }
        case EDIT_LANG_LIST_FAILURE:
            return {
                editLangLoading: false,
                editLang: null,
                editLangError: action.payload
            }

        default:
            return state;
    }
};

export const genderEditReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_GENDER_LIST_REQUEST:
            return {
                ...state,
                editGenderLoading: true
            }
        case EDIT_GENDER_LIST_SUCESS:
            return {
                editGenderLoading: false,
                editGender: action.payload,
                editGenderError: ''
            }
        case EDIT_GENDER_LIST_FAILURE:
            return {
                editGenderLoading: false,
                editGender: null,
                editGenderError: action.payload
            }

        default:
            return state;
    }
};

export const cityEditReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_CITY_LIST_REQUEST:
            return {
                ...state,
                editCityLoading: true
            }
        case EDIT_CITY_LIST_SUCESS:
            return {
                editCityLoading: false,
                editCity: action.payload,
                editCityError: ''
            }
        case EDIT_CITY_LIST_FAILURE:
            return {
                editCityLoading: false,
                editCity: null,
                editCityError: action.payload
            }

        default:
            return state;
    }
};

export const cityStDescrReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CITY_ST_DESCR_LIST_REQUEST:
            return {
                ...state,
                cityStDescrListLoading: true
            }
        case FETCH_CITY_ST_DESCR_LIST_SUCESS:
            return {
                cityStDescrListLoading: false,
                cityStDescrList: action.payload,
                cityStDescrListError: ''
            }
        case FETCH_CITY_ST_DESCR_LIST_FAILURE:
            return {
                cityStDescrListLoading: false,
                cityStDescrList: null,
                cityStDescrListError: action.payload
            }

        default:
            return state;
    }
};

export const templesOutsideIndReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLES_OUTSIDE_IND_LIST_REQUEST:
            return {
                ...state,
                templesOutsideIndLoading: true
            }
        case FETCH_TEMPLES_OUTSIDE_IND_LIST_SUCESS:
            return {
                templesOutsideIndLoading: false,
                templesOutsideInd: action.payload,
                templesOutsideIndError: ''
            }
        case FETCH_TEMPLES_OUTSIDE_IND_LIST_FAILURE:
            return {
                templesOutsideIndLoading: false,
                templesOutsideInd: null,
                templesOutsideIndError: action.payload
            }

        default:
            return state;
    }
};

export const getDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STATE_REQUEST:
            return {
                ...state,
                userDetailsLoading: true
            }
        case FETCH_STATE_SUCESS:
            return {
                userDetailsLoading: false,
                userDetails: action.payload,
                userDetailsError: ''
            }
        case FETCH_STATE_FAILURE:
            return {
                userDetailsLoading: false,
                userDetails: null,
                userDetailsError: action.payload
            }

        default:
            return state;
    }
};

export const getServicesDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SERVICES_DETAILS_REQUEST:
            return {
                ...state,
                servicesDetailsLoading: true
            }
        case FETCH_SERVICES_DETAILS_SUCESS:
            return {
                servicesDetailsLoading: false,
                servicesDetails: action.payload,
                servicesDetailsError: ''
            }
        case FETCH_SERVICES_DETAILS_FAILURE:
            return {
                servicesDetailsLoading: false,
                servicesDetails: null,
                servicesDetailsError: action.payload
            }

        default:
            return state;
    }
};

export const getServicesByUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SERVICES_BY_USER_REQUEST:
            return {
                ...state,
                servicesByUserLoading: true
            }
        case FETCH_SERVICES_BY_USER_SUCESS:
            return {
                servicesByUserLoading: false,
                servicesByUser: action.payload,
                servicesByUserError: ''
            }
        case FETCH_SERVICES_BY_USER_FAILURE:
            return {
                servicesByUserLoading: false,
                servicesByUser: null,
                servicesByUserError: action.payload
            }

        default:
            return state;
    }
};

export const getTempleServiceRegistrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLE_SERVICE_REGISTRATION_REQUEST:
            return {
                ...state,
                templeServiceRegistrationLoading: true
            }
        case FETCH_TEMPLE_SERVICE_REGISTRATION_SUCESS:
            return {
                templeServiceRegistrationLoading: false,
                templeServiceRegistration: action.payload,
                templeServiceRegistrationError: ''
            }
        case FETCH_TEMPLE_SERVICE_REGISTRATION_FAILURE:
            return {
                templeServiceRegistrationLoading: false,
                templeServiceRegistration: null,
                templeServiceRegistrationError: action.payload
            }

        default:
            return state;
    }
};

export const getUserServiceRegistrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_SERVICE_REGISTRATION_REQUEST:
            return {
                ...state,
                userServiceRegistrationLoading: true
            }
        case FETCH_USER_SERVICE_REGISTRATION_SUCESS:
            return {
                userServiceRegistrationLoading: false,
                userServiceRegistration: action.payload,
                userServiceRegistrationError: ''
            }
        case FETCH_USER_SERVICE_REGISTRATION_FAILURE:
            return {
                userServiceRegistrationLoading: false,
                userServiceRegistration: null,
                userServiceRegistrationError: action.payload
            }

        default:
            return state;
    }
};

export const getServiceRegDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SERVICE_REGISTRATION_DETAILS_REQUEST:
            return {
                ...state,
                serviceRegDetailsLoading: true
            }
        case FETCH_SERVICE_REGISTRATION_DETAILS_SUCESS:
            return {
                serviceRegDetailsLoading: false,
                serviceRegDetails: action.payload,
                serviceRegDetailsError: ''
            }
        case FETCH_SERVICE_REGISTRATION_DETAILS_FAILURE:
            return {
                serviceRegDetailsLoading: false,
                serviceRegDetails: null,
                serviceRegDetailsError: action.payload
            }

        default:
            return state;
    }
};

export const getAnonomousUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ANONOMOUS_USER_REQUEST:
            return {
                ...state,
                anonomousUserLoading: true
            }
        case FETCH_ANONOMOUS_USER_SUCESS:
            return {
                anonomousUserLoading: false,
                anonomousUser: action.payload,
                anonomousUserError: ''
            }
        case FETCH_ANONOMOUS_USER_FAILURE:
            return {
                anonomousUserLoading: false,
                anonomousUser: null,
                anonomousUserError: action.payload
            }

        default:
            return state;
    }
};

export const getServicesByAdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SERVICES_BY_ADMIN_REQUEST:
            return {
                ...state,
                servicesByAdminLoading: true
            }
        case FETCH_SERVICES_BY_ADMIN_SUCESS:
            return {
                servicesByAdminLoading: false,
                servicesByAdmin: action.payload,
                servicesByAdminError: ''
            }
        case FETCH_SERVICES_BY_ADMIN_FAILURE:
            return {
                servicesByAdminLoading: false,
                servicesByAdmin: null,
                servicesByAdminError: action.payload
            }

        default:
            return state;
    }
};

export const nearestReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NEAREST_REQUEST:
            return {
                ...state,
                nearestLoading: true
            }
        case FETCH_NEAREST_SUCESS:
            return {
                nearestLoading: false,
                nearest: action.payload,
                nearestError: ''
            }
        case FETCH_NEAREST_FAILURE:
            return {
                nearestLoading: false,
                nearest: null,
                nearestError: action.payload
            }

        default:
            return state;
    }
};

export const filterNearestReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FILTER_NEAREST_REQUEST:
            return {
                ...state,
                filterNearestLoading: true
            }
        case FETCH_FILTER_NEAREST_SUCESS:
            return {
                filterNearestLoading: false,
                filterNearest: action.payload,
                filterNearestError: ''
            }
        case FETCH_FILTER_NEAREST_FAILURE:
            return {
                filterNearestLoading: false,
                filterNearest: null,
                filterNearestError: action.payload
            }

        default:
            return state;
    }
};

export const getByStDtReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BY_ST_DT_REQUEST:
            return {
                ...state,
                filterByStDtLoading: true
            }
        case FETCH_BY_ST_DT_SUCESS:
            return {
                filterByStDtLoading: false,
                filterByStDt: action.payload,
                filterByStDtError: ''
            }

        case FETCH_BY_ST_DT_FAILURE:
            return {
                filterByStDtLoading: false,
                filterByStDt: null,
                filterByStDtError: action.payload
            }

        default:
            return state;
    }
};

export const getByStDtTxtReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BY_ST_DT_TXT_REQUEST:
            return {
                ...state,
                filterByStDtTxtLoading: true
            }
        case FETCH_BY_ST_DT_TXT_SUCESS:
            return {
                filterByStDtTxtLoading: false,
                filterByStDtTxt: action.payload,
                filterByStDtTxtError: ''
            }

        case FETCH_BY_ST_DT_TXT_FAILURE:
            return {
                filterByStDtTxtLoading: false,
                filterByStDtTxt: null,
                filterByStDtTxtError: action.payload
            }

        default:
            return state;
    }
};


export const templeGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLES_BY_GP_REQUEST:
            return {
                ...state,
                templeGroupsLoading: true
            }
        case FETCH_TEMPLES_BY_GP_SUCESS:
            return {
                templeGroupsLoading: false,
                templeGroups: action.payload,
                templeGroupsError: ''
            }
        case FETCH_TEMPLES_BY_GP_FAILURE:
            return {
                templeGroupsLoading: false,
                templeGroups: null,
                templeGroupsError: action.payload
            }

        default:
            return state;
    }
};

export const templeGroupDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEMPLE_GRP_DETAILS_REQUEST:
            return {
                ...state,
                templeGroupDetailsLoading: true
            }
        case GET_TEMPLE_GRP_DETAILS_SUCCESS:
            return {
                templeGroupDetailsLoading: false,
                templeGroupDetails: action.payload,
                templeGroupDetailsError: ''
            }
        case GET_TEMPLE_GRP_DETAILS_FAILURE:
            return {
                templeGroupDetailsLoading: false,
                templeGroupDetails: null,
                templeGroupDetailsError: action.payload
            }

        default:
            return state;
    }
};

export const getPriestDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRIEST_DETAILS_REQUEST:
            return {
                ...state,
                priestDetailsLoading: true
            }
        case FETCH_PRIEST_DETAILS_SUCESS: // Corrected Typo
            return {
                ...state, // Preserve other state properties
                priestDetailsLoading: false,
                priestDetails: action.payload,
                priestDetailsError: ''
            }
        case FETCH_PRIEST_DETAILS_FAILURE:
            return {
                ...state, // Preserve other state properties
                priestDetailsLoading: false,
                priestDetails: null,
                priestDetailsError: action.payload
            }
        default:
            return state;
    }
};


export const getAppUsersListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_APP_USERS_REQUEST:
            return {
                ...state,
                appUsersLoading: true,
            };
        case FETCH_APP_USERS_SUCCESS: // Use correct action type
            return {
                appUsersLoading: false,
                appUsers: action.payload,
                appUsersError: '',
            };
        case FETCH_APP_USERS_FAILURE:
            return {
                appUsersLoading: false,
                appUsers: null,
                appUsersError: action.payload,
            };
        default:
            return state;
    }
};

export const getFilterByStDtReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FILTER_BY_ST_DT_REQUEST:
            return {
                ...state,
                byStDtLoading: true
            }
        case FETCH_FILTER_BY_ST_DT_SUCESS:
            return {
                byStDtLoading: false,
                byStDt: action.payload,
                byStDtError: ''
            }

        case FETCH_FILTER_BY_ST_DT_FAILURE:
            return {
                byStDtLoading: false,
                byStDt: null,
                byStDtError: action.payload
            }

        default:
            return state;
    }
};

export const getCityTemplesListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CITY_TEMPLES_LIST_REQUEST:
            return {
                ...state,
                cityTemplesListLoading: true
            }
        case FETCH_CITY_TEMPLES_LIST_SUCESS:
            return {
                cityTemplesListLoading: false,
                cityTemplesList: action.payload,
                cityTemplesListError: ''
            }

        case FETCH_CITY_TEMPLES_LIST_FAILURE:
            return {
                cityTemplesListLoading: false,
                cityTemplesList: null,
                cityTemplesListError: action.payload
            }

        default:
            return state;
    }
};

export const templeTxtReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLES_BY_TXT_REQUEST
            :
            return {
                ...state,
                templeTxtLoading: true
            }
        case FETCH_TEMPLES_BY_TXT_SUCESS
            :
            return {
                templeTxtLoading: false,
                templeTxt: action.payload,
                templeTxtError: ''
            }
        case FETCH_TEMPLES_BY_TXT_FAILURE:
            return {
                templeTxtLoading: false,
                templeTxt: null,
                templeTxtError: action.payload
            }

        default:
            return state;
    }
};

export const resourceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOURCE_REQUEST:
            return {
                ...state,
                resourceLoading: true
            }
        case FETCH_RESOURCE_SUCESS:
            return {
                resourceLoading: false,
                resource: action.payload,
                resourceError: ''
            }
        case FETCH_RESOURCE_FAILURE:
            return {
                resourceLoading: false,
                resource: null,
                resourceError: action.payload
            }

        default:
            return state;
    }
};

export const resourceByTxtReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOURCE_BY_TXT_REQUEST:
            return {
                ...state,
                resourceByTxtLoading: true
            }
        case FETCH_RESOURCE_BY_TXT_SUCESS:
            return {
                resourceByTxtLoading: false,
                resourceByTxt: action.payload,
                resourceByTxtError: ''
            }
        case FETCH_RESOURCE_BY_TXT_FAILURE:
            return {
                resourceByTxtLoading: false,
                resourceByTxt: null,
                resourceByTxtError: action.payload
            }

        default:
            return state;
    }
};

export const resourceDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RESOURCE_DET_REQUEST:
            return {
                ...state,
                resourceDetailsLoading: true
            }
        case GET_RESOURCE_DET_SUCCESS:
            return {
                resourceDetailsLoading: false,
                resourceDetails: action.payload,
                resourceDetailsError: ''
            }
        case GET_RESOURCE_DET_FAILURE:
            return {
                resourceDetailsLoading: false,
                resourceDetails: null,
                resourceDetailsError: action.payload
            }

        default:
            return state;
    }
};

export const templeGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLE_GP_REQUEST:
            return {
                ...state,
                templeGroupLoading: true
            }
        case FETCH_TEMPLE_GP_SUCESS:
            return {
                templeGroupLoading: false,
                templeGroup: action.payload,
                templeGroupError: ''
            }
        case FETCH_TEMPLE_GP_FAILURE:
            return {
                templeGroupLoading: false,
                templeGroup: null,
                templeGroupError: action.payload
            }

        default:
            return state;
    }
};

export const groupDescriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GROUP_DESCRIPTION_REQUEST:
            return {
                ...state,
                groupDescription: true
            }
        case FETCH_GROUP_DESCRIPTION_SUCESS:
            return {
                groupDescriptionLoading: false,
                groupDescription: action.payload,
                groupDescriptionError: ''
            }
        case FETCH_GROUP_DESCRIPTION_FAILURE:
            return {
                groupDescriptionLoading: false,
                groupDescription: null,
                groupDescriptionError: action.payload
            }

        default:
            return state;
    }
};

export const templeNotifiReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLE_NOTIFI_REQUEST:
            return {
                ...state,
                templeNotifiLoading: true
            }
        case FETCH_TEMPLE_NOTIFI_SUCESS:
            return {
                templeNotifiLoading: false,
                templeNotifi: action.payload,
                templeNotifiError: ''
            }
        case FETCH_TEMPLE_NOTIFI_FAILURE:
            return {
                templeNotifiLoading: false,
                templeNotifi: null,
                templeNotifiError: action.payload
            }

        default:
            return state;
    }
};

export const regionalNotifiReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REGIONAL_NOTIFI_REQUEST:
            return {
                ...state,
                regionalNotifiLoading: true
            }
        case FETCH_REGIONAL_NOTIFI_SUCESS:
            return {
                regionalNotifiLoading: false,
                regionalNotifi: action.payload,
                regionalNotifiError: ''
            }
        case FETCH_REGIONAL_NOTIFI_FAILURE:
            return {
                regionalNotifiLoading: false,
                regionalNotifi: null,
                regionalNotifiError: action.payload
            }

        default:
            return state;
    }
};

export const templeBooksReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLE_BOOKS_REQUEST:
            return {
                ...state,
                templeBooksLoading: true
            }
        case FETCH_TEMPLE_BOOKS_SUCESS:
            return {
                templeBooksLoading: false,
                templeBooks: action.payload,
                templeBooksError: ''
            }
        case FETCH_TEMPLE_BOOKS_FAILURE:
            return {
                templeBooksLoading: false,
                templeBooks: null,
                templeBooksError: action.payload
            }

        default:
            return state;
    }
};

export const templeVideoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLE_VIDEO_REQUEST:
            return {
                ...state,
                templeVideoLoading: true
            }
        case FETCH_TEMPLE_VIDEO_SUCESS:
            return {
                templeVideoLoading: false,
                templeVideoData: action.payload,
                templeVideoError: ''
            }
        case FETCH_TEMPLE_VIDEO_FAILURE:
            return {
                templeBooksLoading: false,
                templeBooksData: null,
                templeBooksError: action.payload
            }

        default:
            return state;
    }
};

export const templeAudioReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEMPLE_AUDIO_REQUEST:
            return {
                ...state,
                templeAudioLoading: true
            }
        case FETCH_TEMPLE_AUDIO_SUCESS:
            return {
                templeAudioLoading: false,
                templeAudioData: action.payload,
                templeAudioError: ''
            }
        case FETCH_TEMPLE_AUDIO_FAILURE:
            return {
                templeAudioLoading: false,
                templeAudioData: null,
                templeAudioError: action.payload
            }

        default:
            return state;
    }
};

