import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchNotifications, fetchTempleNotifi, postClearALLNotifi, removeNotification } from '../../../../redux/actions/acions';
import { getNotificationsError, getNotificationsList, getNotificationstionsLoading, getTempleNotifiError, getTempleNotifiList, getTempleNotifiLoading } from '../../../../redux/selectors/selectors';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, Card, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Error from '../error';
// import user from '../../assets/images/user.jpg';





let last_rec = 0;
const TempleNotification = () => {
  const { t } = useTranslation();
  const { tid } = useParams()
  const records_per_page = 15;
  const [clearAll, setClearAll] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const storedLanguage = localStorage.getItem('lng') || 5;
  const dispatch = useDispatch();
  const { nList, nLoading, nError } = useSelector(state => ({
    nList: getTempleNotifiList(state),
    nLoading: getTempleNotifiLoading(state),
    nError: getTempleNotifiError(state),
  }));

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Cleared all notifications',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Notification deleted',
    });
  };
  const failure = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };

  useEffect(() => {
    dispatch(fetchTempleNotifi(storedLanguage, tid, 0))
  }, [tid, storedLanguage]);


  function isNotificationDeleted(id) {
    return deletedIds.includes(id);
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTemples, setCurrentPageTemples] = useState([]);

  // useEffect(() => {
  //     dispatch(fetchNotifications(storedLanguage, last_rec));
  // }, [storedLanguage, currentPage]);


  function nextTemplesList() {
    setIsLoading(true);
    last_rec = last_rec + records_per_page;
    // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
    dispatch(fetchNotifications(last_rec));
    // setIsLoading(false);
  }


  return (
    <div class="h-100">

      {/* <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                <Link style={{ color: 'black' }} to='/'><span className="bc-link">Home</span></Link> -
                
                <span className="bc-active-link"> Notifications</span>
            </div> */}


     

        <div>
         
            {nLoading ? (
              <Error />
            ) : Array.isArray(nList) ? (
              nList.map((notificationList) => {
                return (
                  <Link style={{color:'black'}} to={`/notifiDetails/${notificationList.nid}`} className="notification-link">
                  <div key={notificationList.nid} className="tiles-designs" style={{ backgroundColor: 'white', height: '35%', borderRadius: '4px', padding: '2px 5px' }}>
                    <span class="f1 events-block-link " style={{ color: '#e36f07', textAlign: 'start', fontWeight: 'bold' }}>{notificationList.subject}</span>
                    <span class="f1 events-block-link">{notificationList.txt}</span>
                  </div>
                  </Link>
                );
              })
            ) : (
              <h4>{t('label_no_notifi')}</h4>
            )}
         
        </div>


      </div>
    
  )
}

export default TempleNotification;
