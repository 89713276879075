import { useEffect, useRef, useState } from "react";
import React, { Component } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchByStDt, fetchFavourites, fetchFilterByGod, fetchFilterByStDt, fetchGodNames, fetchTemplesList, postFavourites, removeFavourites, retrieveGodNames, retrieveTemples } from "../../../../redux/actions/acions";
import { getGodNames, getGodNamesLoading, getGodNamesError, getTemplesList, getTemplesListLoading, getTemplesListError, getFavouritesList, getFavouritesLoading, getFavouritesError, getFilterByGodList, getFilterByGodLoading, getFilterByGodError, getFilterByStDtLoading, getFilterByStDtList, getFilterByStDtError, getByStDtList, getByStDtLoading, getByStDtError } from "../../../../redux/selectors/selectors";
import { Card, Button, Col, Row, message, Modal, Select, Form } from 'antd';
import Error from '../error';
import "../../search.css"
import statesData from '../../states.json';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import TemplesPage from "../../../common/TemplesPage";
import Footer from "../footer/footer";
import { useLocation } from "react-router-dom";



var last_rec = 0;
let god_last_rec = 0;
let stLastRec = 0;

function TemplesList() {
    const { Option } = Select;
    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
    const dispatch = useDispatch()
    const [filterId, setFilterId] = useState(localStorage.getItem('filterId') || null);
    const { Meta } = Card;
    const localEnv = false;
    const records_per_page = 15;
    const [did, setDid] = useState(0);
    const [showMaps, setShowMaps] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [state, setState] = useState(null);
    const [activeGodCtgry, setActiveGodCtgry] = useState("All");
    const [showImage, setShowImage] = useState(true);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;


    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const [messageApi, contextHolder] = message.useMessage();
    // const success = (tname) => {
    //     messageApi.open({
    //         type: 'success',
    //         content: `Added ${tname} to favorites`,
    //     });
    // };
    // const warning = (tname) => {
    //     messageApi.open({
    //         type: 'warning',
    //         content: `Removed ${tname} from favourites`,
    //     });
    // };

    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: 'Please Login to see additional features',
        });
    };
    const { fList, fLoading, fError, templesList, templesListLoading, templesListError, godCtgryList, godCtgryListLoading, godCtgryListError, sList, sLoading, sError, stList, stLoading, stError, fstList, fstLoading, fstError } = useSelector(state => ({
        templesList: getTemplesList(state),
        templesListLoading: getTemplesListLoading(state),
        templesListError: getTemplesListError(state),
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
        fList: getFavouritesList(state),
        fLoading: getFavouritesLoading(state),
        fError: getFavouritesError(state),
        sList: getFilterByGodList(state),
        sLoading: getFilterByGodLoading(state),
        sError: getFilterByGodError(state),
        stList: getFilterByStDtList(state),
        stLoading: getFilterByStDtLoading(state),
        stError: getFilterByStDtError(state),
        fstList: getByStDtList(state),
        fstLoading: getByStDtLoading(state),
        fstError: getByStDtError(state),
    }))
    

    const storeTempleDetails = (temple) => {
        const recentlyViewedTemples = JSON.parse(localStorage.getItem('recentlyViewedTemples')) || [];
        const updatedTemples = [temple, ...recentlyViewedTemples.slice(0, 10)]; // Keep only the latest 10 recently viewed temples
        localStorage.setItem('recentlyViewedTemples', JSON.stringify(updatedTemples));
    };

    const [isLoading, setIsLoading] = useState(false);



    let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.tid);

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])

    // useEffect(() => {
    //     dispatch(fetchFavourites(storedLanguage, 0))
    // }, [getFavouriteTemples])



    useEffect(() => {
        dispatch(fetchByStDt(5, selectedState, selectedCity, last_rec));
    }, [storedLanguage, selectedState, selectedCity, 0]);


    useEffect(() => {
        if (selectedState) {
            dispatch(fetchFilterByStDt(storedLanguage, selectedState && selectedState, selectedCity, did, 0))
        }
        else {
            dispatch(fetchFilterByGod(storedLanguage, did, last_rec))
        }
    }, [storedLanguage, did])

    const location = useLocation();

    // useEffect(() => {
    //     const { state } = location;
    //     // Check if the previous location was TempleDetails
    //     const isFromTempleDetails = state && state.from === 'TempleDetails';

    //     // Conditionally set the filter ID only if coming from TempleDetails
    //     if (isFromTempleDetails && state.filterId) {
    //         setFilterId(state.filterId);
    //     } else {
    //         // Reset filter ID if not coming from TempleDetails
    //         setFilterId(null);
    //         localStorage.removeItem('filterId');
    //     }
    // }, [location]);



    function updateFilterId(id) {

        localStorage.setItem('filterId', id);
        setFilterId(id);
    }

    function filterTemplesList(gDid) {
        setDid(gDid);
        if (gDid === filterId) {
            setFilterId(null)
            dispatch(retrieveTemples(storedLanguage, last_rec));
        } else {
            setFilterId(gDid);
            updateFilterId(gDid);
        }
    }
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageTemples, setCurrentPageTemples] = useState([]);

    useEffect(() => {
        dispatch(retrieveTemples(storedLanguage, last_rec));
    }, [storedLanguage, filterId, currentPage]);

    useEffect(() => {
        if (!templesListLoading && templesList) {
            setCurrentPageTemples([...currentPageTemples, ...templesList]);
        }
    }, [templesListLoading]);

    const nextTemplesList = async () => {
        setIsLoading(true);
        // Increment the current page

        if (filterId === null) {
            last_rec += records_per_page;
            dispatch(retrieveTemples(storedLanguage, last_rec));
        } else {
            god_last_rec += records_per_page;
            dispatch(fetchFilterByGod(storedLanguage, did, god_last_rec));
        }
        // dispatch(fetchByStDt(storedLanguage, selectedState && selectedState.name, selectedCity, last_rec));
        setIsLoading(false);
        window.scrollTo(0, 0);
    }

    function prevTemplesList() {
        if (filterId === null) {
            last_rec -= records_per_page;
            last_rec = last_rec < 0 ? 0 : last_rec;
            dispatch(retrieveTemples(storedLanguage, last_rec));
        } else {
            god_last_rec -= records_per_page;
            god_last_rec = god_last_rec < 0 ? 0 : god_last_rec;
            dispatch(fetchFilterByGod(storedLanguage, did, god_last_rec));
        }
        // dispatch(fetchByStDt(storedLanguage, selectedState && selectedState.name, selectedCity, last_rec));
    }



    function getStyle(did) {
        if (did === filterId) {
            return {
                color: '#4096ff',
                borderColor: '#4096ff',
            };
        } else {
            return {
                borderColor: '#d9d9d9',
                color: '#000'
            };
        }
    }

    // function addFavourites(id) {
    //     dispatch(postFavourites(id, failure))
    // }

    // function deleteFavourites(deleteId) {
    //     dispatch(removeFavourites(deleteId, failure))
    // }

    // const getToken = Boolean(localStorage.getItem('token'));

    // function toggleFavorite(temple) {
    //     const index = favList.findIndex(t => t === temple.tid);
    //     if (index === -1) {
    //         addFavourites(temple.tid, temple.tname);
    //         setGetFavoriteTemples(!getFavouriteTemples);
    //         if (getToken) {
    //             success(temple.tname);
    //         }
    //     } else {
    //         deleteFavourites(temple.tid, temple.tname);
    //         setGetFavoriteTemples(!getFavouriteTemples);
    //         warning(temple.tname);
    //     }
    // }

    useEffect(() => {
        dispatch(localEnv ? fetchTemplesList(failure) : retrieveTemples(storedLanguage, last_rec))
    }, [storedLanguage, filterId])


    const states = statesData.states.map((state) => state.name);

    const handleStateChange = (value) => {
        const newState = statesData.states.find((state) => state.name === value);
        setState(newState);
        setSelectedCity(newState ? newState.cities : []);
    };

    const handleDialogSubmit = () => {
        dispatch(fetchByStDt(storedLanguage, state.name, selectedCity, 0));
        setDialogVisible(false);
        setSelectedState(state.name);
        setFilterId(null)
    };

    const handleDialogueCancel = () => {
        setDialogVisible(false)
        setSelectedState(null);
        setSelectedCity([])
    }
    useEffect(() => {
        if (state) {
            setSelectedCity(state.cities[0]);
        }
    }, [state]);

    const [filled, setFilled] = useState({});

    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }

    const godList = selectedState ? fstList : sList;

    return (
        <>
            {contextHolder}
            {filterId === null ? (templesListLoading || templesList === null) ? (<Error />) :

                (
                    <TemplesPage
                        title={t("label_fam_temples")}
                        description={t("label_these_famous")}
                        tList={templesList}
                        tLoading={templesListLoading}
                        // favList={favList}
                        // toggleFavorite={toggleFavorite}
                        // toggleFilled={toggleFilled}
                        setFilterId={setFilterId}
                        godCtgryList={godCtgryList}
                        filterTemplesList={filterTemplesList}
                        godCtgryListLoading={godCtgryListLoading}
                        pageTitle={t("label_fam_temples")}
                        filterId={filterId}
                        linkPage={'/templeCtgry'}
                        nextTemplesList={nextTemplesList}
                        prevTemplesList={prevTemplesList}
                        last_rec={last_rec}
                        latit={templesList.latit}
                        longi={templesList.longi}
                        templesList={templesList}
                        
                    />


                ) : sLoading ? (<Error />) : (
                    <TemplesPage
                        title={t("label_fam_temples")}
                        description={'label_these_famous'}
                        tList={godList}
                        tLoading={sLoading}
                        // favList={favList}
                        // toggleFavorite={toggleFavorite}
                        // toggleFilled={toggleFilled}
                        setFilterId={setFilterId}
                        godCtgryList={godCtgryList}
                        filterTemplesList={filterTemplesList}
                        godCtgryListLoading={godCtgryListLoading}
                        pageTitle={'label_fam_temples'}
                        filterId={filterId}
                        nextTemplesList={nextTemplesList}
                        prevTemplesList={prevTemplesList}
                        last_rec={last_rec}
                        templesList={godList}
                    />
                )}

            <Modal className="select-state"
                title="Search By City and State"
                visible={dialogVisible}
                onOk={handleDialogSubmit}
                onCancel={handleDialogueCancel}
            >
                <div>

                    <Form
                        name="nest-messages"
                        labelCol={{
                            span: 24,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                    >
                        <Form.Item
                            label="State"
                            name="state"
                            rules={[
                                { required: true, message: 'Please enter your State' },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onChange={handleStateChange}
                            >
                                <Option value="All">{t('label_all')}</Option>
                                {states.map((state) => (
                                    <Select.Option key={state} value={state}>
                                        {state}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="City"
                            name="city"
                            rules={[
                                { required: true, message: 'Please select your city' },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                value={selectedCity}
                                onChange={(value) => setSelectedCity(value)}
                            >
                                <Option value="All">{t('label_all')}</Option>
                                {state &&
                                    state.cities.map((city) => (
                                        <Option key={city} value={city}>
                                            {city}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default TemplesList;