/* global adsbygoogle */
import React, { useEffect, useRef } from "react";

const ResponsiveAd = () => {
  const adRef = useRef(null);

  useEffect(() => {
    const pushAd = () => {
      if (adRef.current && window.adsbygoogle) {
        (adsbygoogle = window.adsbygoogle || []).push({});
      }
    };

    // Check if the adsbygoogle script has been loaded
    if (window.adsbygoogle) {
      pushAd();
    } else {
      const script = document.createElement("script");
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.async = true;
      script.onload = pushAd; // Push the ad after the script loads
      document.body.appendChild(script);
    }

    return () => {
      // Clean up script if needed (optional)
    };
  }, []);

  return (
    <div style={{ textAlign: "center", margin: "20px 0" }}>
      <ins
        className="adsbygoogle"
        style={{ display: "block", height: "250px" }} // Adjust height based on ad format
        data-ad-client="ca-pub-3745324756668457" // Test ad client ID
        data-ad-slot="8252693488" // Use a valid ad slot ID (dummy is fine for testing)
        data-ad-format="auto"
      ></ins>
    </div>
  );
};

export default ResponsiveAd;
