import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, message } from 'antd';
import { getFavouritesError, getFavouritesList, getFavouritesLoading, getTemplesList, getTemplesListError, getTemplesListLoading } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { addFavourites, fetchContributions, fetchFavourites, fetchTemplesList, postFavourites, removeFavourites } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';


const Favourite = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { fList, fLoading, fError, templesList, templesListLoading, templesListError } = useSelector(state => ({
        fList: getFavouritesList(state),
        fLoading: getFavouritesLoading(state),
        fError: getFavouritesError(state),
        templesList: getTemplesList(state),
        templesListLoading: getTemplesListLoading(state),
        templesListError: getTemplesListError(state),
    }));

    const [filled, setFilled] = useState({}); // initialize as an empty object
    const [refresh, setRefresh] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_removefav_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'Removed from favourites',
        // });
    };

    // function deleteFavourites(deleteId) {
    //     dispatch(removeFavourites(deleteId));
    //     setRefresh(!refresh)

    // }

    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }
    function deleteFavourites(deleteId) {
        dispatch(removeFavourites(deleteId));

    }


    useEffect(() => {
        dispatch(fetchFavourites(storedLanguage, 0));

    }, [storedLanguage, refresh]);

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'));
    }, []);


    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className='main'>
                        <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('FAVOURITES')}</span>
                </div>
                <div></div>
            </div>
            <div>
                      
                {contextHolder}
                <CustomToastContainer/>
                </div>
                <div className='favourite-header'>
                    <Link to={'/'}>{t('back')}</Link>
                    <h4>{t('FAVOURITES')}</h4>
                    <Link to={'/'}>{t('back')}</Link>
                </div>

                <div>
                    {fLoading ? <p>loading</p> : (fList&&fList.map(temple => {
                        return (
                            <div className='favourite-list' key={temple.tid}>
                                <div>
                                <Link  className='ignore-style' to={`/templeDetails/${temple.tid}`}>
                                    <h2 class="f1 events-block-link">{temple.tname}</h2>
                                    <p>{temple.city}, {temple.state}</p>
                                    </Link>
                                    <Button
                                        onClick={() => {
                                            deleteFavourites(temple.tid);
                                            success()
                                        }}
                                        icon={filled[temple.tid] ? (
                                            <HeartOutlined
                                                style={{ color: "orange" }}
                                                onClick={() => toggleFilled(temple.tid)}
                                            />
                                        ) : (
                                            <HeartFilled
                                                style={{ color: "orange" }}
                                                onClick={() => toggleFilled(temple.tid)}
                                            />

                                        )}
                                    />
                                </div>
                            </div>
                        )
                    }))}
                </div>
                <div className='footer'></div>
        </div>
    );
};

export default Favourite;
