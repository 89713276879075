import {
    Form, Input, Typography, message,
    Col,
    Row,
    Select,
    DatePicker,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import "../../addTemple.css"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGodNames, postResource, retrieveGodNames } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/es/mentions';
import { getGodNames, getGodNamesError, getGodNamesLoading } from '../../../../redux/selectors/selectors';
import i18next from 'i18next';
import dayjs from 'dayjs';
import 'moment/locale/en-gb';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';
import 'cropperjs/dist/cropper.css';
import deityImages from '../../../common/godImages';
import aum from '../../../../assets/icons/om.jpg'
import { UploadOutlined } from '@ant-design/icons';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e.fileList;
};


const AddResource = () => {
    const { TextArea } = Input;
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
    const { rid } = useParams()
    const [selectedDeity, setSelectedDeity] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [date, setDate] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [newImagePath, setNewImagePath] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const getToken = Boolean(localStorage.getItem('token'));
    const [refresh, setRefresh] = useState(false);
    const [name, setName] = useState(null);

    const onFileChange = ({ file, fileList }) => {
        console.warn(file);
    };

    const onChange = (event) => {
        const date = event.target.value;
        setDate(date);
    };

    const [cropper, setCropper] = useState(null);

    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const localEnv = false;
    const { godCtgryList, godCtgryListLoading, godCtgryListError } = useSelector(state => ({
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    }))

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])


    const onFinish = () => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();

                const data = {
                    title: values.title,
                    author: values.author,
                    maindeity: values.maindeity,
                    src_url: values.src_url,
                    txt: values.txt,
                    res_type: values.res_type,
                    lang: values.lang,
                    publish_date: dayjs(values.publish_date).format('YYYY-MM-DD'),
                    tag1: values.tag1,
                    tag2: values.tag2,
                    avatar: selectedDeity !== null ? selectedDeity : values.maindeity,
                    src: values.src,
                    img_path: values.img_path || null,
                    ctgry: 0
                };
                dispatch(postResource(data, success, failure));
            })
            .catch((errorInfo) => {
                console.error('Validation failed:', errorInfo);
            });
    };

    const clickCancel = () => {
        navigate(-1)
    };



    const storedLanguage = localStorage.getItem('lng') || 5;


    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])



    const [showAllImages, setShowAllImages] = useState(false);


    const handleImageClick = () => {
        setShowAllImages(true);
    };

    const handleDeityImageClick = (deityNumber) => {
        setSelectedDeity(deityNumber);
        setShowAllImages(false);
        //dispatch(postResource({ did: did, }));
    };


    const handleCloseModal = () => {
        setShowAllImages(false);
    };

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_addresource_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY EDITED',
        // });
        navigate(-1)
        form.resetFields()
    };

    const failure = (error) => {
        let msg;
        if (error.response && error.response.data && error.response.data.error) {
            // If error.response exists, and data and error properties exist within it
            msg = error.response.data.error;
        } else if (error.message) {
            // If there's a message property in the error object
            msg = error.message;
        } else {
            // If none of the above conditions are met, fallback to a generic error message
            msg = "An error occurred";
        }

        messageApi.open({
            type: "failed",
            content: msg,
        });

        showToast('fail', t('label_addresource_failure'));

    };

    const editSuccess = () => {
        showToast('success', t('label_editresource_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY EDITED',
        // });
        navigate(-1)
        form.resetFields()
    };

    const favSuccess = (tname) => {
        showToast('success', t(`Added ${tname} to favorites`))

    };

    const warning = (title) => {
        showToast('info', t(`Removed ${title} from favourites`))
        // messageApi.open({
        //   type: 'warning',
        //   content: `Removed ${title} from favourites`,
        // });
    };

    const editFailure = (error) => {
        let msg;
        if (error.response && error.response.data && error.response.data.error) {
            // If error.response exists, and data and error properties exist within it
            msg = error.response.data.error;
        } else if (error.message) {
            // If there's a message property in the error object
            msg = error.message;
        } else {
            // If none of the above conditions are met, fallback to a generic error message
            msg = "An error occurred";
        }

        messageApi.open({
            type: "failed",
            content: msg,
        });

        showToast('fail', t('label_editresource_failure'));

    };


    const token = localStorage.getItem('token');


    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        form.resetFields();
        navigate(-1);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));

        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleLinkClick = () => {
        // setOpen(false);
        message.info('label_loginerror.');
    };

    const handleUploadClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';

        // Event listener for file selection
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            if (file) {
                // Call onFileChange to handle the file upload logic
                onFileChange(file);
            }

            // Clean up file input element after processing
            document.body.removeChild(fileInput);
        });

        // Append file input to the DOM and trigger click to open file dialog
        document.body.appendChild(fileInput);
        fileInput.click(); // This triggers the file dialog
    };

    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(isMobileDevice());
    }, []);

    const handleName = (e) => {
        setName(e.target.value)
    };

    const userRole = localStorage.getItem('uid')

    return (
        <>
            {contextHolder}
            <CustomToastContainer />
            <div class='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_create_resource')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> -
                    {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                    &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('Resources')}</span> -
                    &nbsp; <span className="bc-link">
                        <span>{t('label_create_resource')}</span>
                    </span>
                </div>
                <div class={`bg-details-highlight `}>
                    <div class=" details-headertxt container">
                        <div class="header_txt">
                            <span>{t('label_create_resource')}</span>
                        </div>
                        <div class="d-flex flex-row header_star mr-5">

                        </div>
                        <div class="">

                            <Link class="button_move" to='/addResTxt' style={{ marginRight: "5px" }}>{t("label_lib_by_text")}</Link>

                            <a href="#" class="button_move"
                                onClick={onFinish}
                                style={{ marginRight: '5px' }}
                            >
                                {t("label_save")}
                            </a>
                            <a href="#" class="button_move"
                                onClick={clickCancel}
                                style={{ marginRight: '5px' }}
                            >
                                {t("label_cancel")}
                            </a>
                        </div>

                        <div class="d-flex flex-row header_star mr-5">
                            <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">

                            </span>
                        </div>
                    </div>
                </div>
                <div class="mainsec-block-detailspage">
                    <div className='container'>
                        {/* <Link to={'/resource/rid'}><Typography.Text>Back</Typography.Text></Link> */}
                        <Form form={form} name="nest-messages" onFinish={onFinish}

                        // labelCol={{
                        //     span: 7,
                        // }}
                        // wrapperCol={{
                        //     span: 9,
                        // }} 
                        >
                            <div class="float-left w-100 mt-4" ><h5 class="card-text-title">{t("error_msg_38")} <span>|  {t('label_name_author_source')}</span></h5></div>
                            <div class="active-details">
                                {/* <Row gutter={10}>
                                    <Col span={16}> */}
                                <div class="tiles-designs" >
                                    <Form.Item

                                        // labelCol={{
                                        //     span: 10,
                                        // }}
                                        // wrapperCol={{
                                        //     span: 18,
                                        // }}
                                        name='title'
                                        label={<Typography.Title level={4}>{t('label_name')}</Typography.Title>}
                                        onChange={handleName}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >

                                        <Input placeholder="Enter Title " rows={4}


                                        />

                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        name='txt'
                                        label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >

                                        <TextArea
                                            placeholder="Enter Details"
                                            rows={4}

                                        />

                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t('label_sel_diety_img')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    // name='avatar'
                                    >

                                        <>
                                            {/* Editing mode: show image from API or the selected image */}
                                            <img
                                                src={selectedDeity ? deityImages[selectedDeity] : deityImages[0]}
                                                alt="Selected Deity Image"
                                                onClick={handleImageClick}
                                                style={{ cursor: 'pointer', width: '5%' }}
                                            />
                                            <div class="popup-bg" style={{ display: showAllImages ? 'flex' : 'none' }}>
                                                <div class="popup-main animate__animated animate__jackInTheBox ">
                                                    <div class="popup-main-header">
                                                        <span class="popup-main-header-text">{t('label_god_categ')}</span>
                                                        <div class="popup-close" onClick={handleCloseModal}><i class="fa-solid fa-xmark"></i></div>
                                                        <div class="popup-back" onClick={handleCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                                                    </div>
                                                    <div class="popup-main-container">
                                                        <div class="d-block htinner-popup-main-container">
                                                            <div className=" god-category-list">
                                                                <div className="all-gods-sec">{t('label_all')}</div>
                                                                <div className="god_select">
                                                                    <div className="god_pic">
                                                                        <img
                                                                            className="dietyImg"
                                                                            src={aum}
                                                                            alt="All"
                                                                            onClick={() => handleDeityImageClick(null)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="all-gods-sec">{t("label_filter_by_god")}</div>
                                                                <Row >
                                                                    {godCtgryList && godCtgryList.map((godCtgry) => (
                                                                        <Col key={godCtgry.avatar}>
                                                                            <div className="god_select">
                                                                                <div className="god_pic">
                                                                                    <img
                                                                                        src={deityImages[godCtgry.avatar]}
                                                                                        alt="Deity Image"
                                                                                        style={{
                                                                                            width: '80px',
                                                                                            height: '80px',
                                                                                            margin: '5px',
                                                                                            cursor: 'pointer',
                                                                                            border: '1px solid #ddd'
                                                                                        }}
                                                                                        onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                                                                    />
                                                                                </div>
                                                                                <p>{godCtgry.deity}</p>
                                                                            </div>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="popup-main-footer">
                                                        <a className="button_move" onClick={handleCloseModal}>{t('label_cancel')}</a>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Modal
                                                visible={showAllImages}
                                                onCancel={handleCloseModal}
                                                footer={null}
                                                mask={false}
                                            >
                                                <div className="diag-header">
                                                    {t('label_god_categ')}
                                                    <span className="diag-close">
                                                        <i className="fa-solid fa-xmark" onClick={handleCloseModal}></i>
                                                    </span>
                                                </div>
                                                <div className="dialog-content god-category-list">
                                                    <div className="all-gods-sec">{t('label_all')}</div>
                                                    <div className="god_select">
                                                        <div className="god_pic">
                                                            <img
                                                                className="dietyImg"
                                                                src={aum}
                                                                alt="All"
                                                                onClick={() => handleDeityImageClick(null)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="all-gods-sec">{t("label_filter_by_god")}</div>
                                                    <Row gutter={[16, 16]}>
                                                        {godCtgryList && godCtgryList.map((godCtgry) => (
                                                            <Col key={godCtgry.avatar}>
                                                                <div className="god_select">
                                                                    <div className="god_pic">
                                                                        <img
                                                                            src={deityImages[godCtgry.avatar]}
                                                                            alt="Deity Image"
                                                                            style={{
                                                                                width: '100px',
                                                                                height: '100px',
                                                                                margin: '5px',
                                                                                cursor: 'pointer',
                                                                                border: '1px solid #ddd'
                                                                            }}
                                                                            onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                                                        />
                                                                    </div>
                                                                    <p>{godCtgry.deity}</p>
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </div>
                                            </Modal> */}
                                        </>


                                        {/* <img
                                            src={deityImages[tDetails && tDetails.avatar]}
                                            alt="Deity Image"
                                            style={{ width: '5%' }}
                                        /> */}

                                    </Form.Item>
                                </div>


                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='author'
                                        label={<Typography.Title level={4}>{t('label_author_speaker')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >

                                        <Input placeholder="Enter Author" rows={4}


                                        />

                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='src'
                                        label={<Typography.Title level={4}>{t('label_source')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >

                                        <Input placeholder="Enter Source" rows={4}


                                        />

                                    </Form.Item>
                                </div>
                                {/* </Col>
                                <Col span={12}> */}
                                <div className="tiles-designs">
                                    <Form.Item
                                        name='src_url'
                                        label={<Typography.Title level={4}>{t('label_source_link')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >

                                        <Input
                                            placeholder="Enter Source URL"
                                            rows={4}

                                        />

                                    </Form.Item>
                                </div>

                                {/* </Col>
                            </Row> */}

                            </div>
                            <div class="float-left w-100 mt-4" ><h5 class="card-text-title">{t('label_additi_det')} <span>| {t('label_tmpl_timings_pujas')}</span></h5></div>

                            <div class="active-details">
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='res_type'
                                        label={<Typography.Title level={4}>{t('label_resource_type')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select >
                                            <Option value="videos">{t("label_videos")}</Option>
                                            <Option value="livetv">{t("label_livetv")}</Option>
                                            <Option value="books">{t("label_books")}</Option>
                                            <Option value="audios">{t("label_audios")}</Option>
                                            <Option value="articles">{t("label_articles")}</Option>
                                            <Option value="mantras">{t("label_mantras")}</Option>
                                        </Select>
                                    </Form.Item>
                                </div>


                                {/* </Col>
                                <Col span={12}> */}
                                <div className="tiles-designs">
                                    <Form.Item
                                        name="lang"
                                        label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select a language.',
                                            },
                                        ]}
                                    >
                                        {/* <Select
                                            className="select"
                                            defaultValue={storedLanguage}
                                            style={{ width: '100%'}}
                                        >
                                            {AP_LANGUAGES.map((item) => (
                                            <Option key={item.value} value={item.value}>
                                                {isMobile ? item.label2 : item.label}
                                            </Option>
                                            ))}
                                        </Select> */}
                                        <Select
                                            options={[
                                                { value: '', label: t('label_lang') },
                                                { value: '1', label: 'తెలుగు' },
                                                { value: '2', label: 'ಕನ್ನಡ' },
                                                { value: '3', label: 'தமிழ்' },
                                                { value: '4', label: 'हिंदी' },
                                                { value: '5', label: 'English' },
                                                { value: '6', label: 'Española' },
                                                { value: '7', label: 'বাংলা' },

                                                // Add any other languages here
                                            ]}
                                            placeholder={t('label_lang')}
                                        />
                                    </Form.Item>
                                </div>


                                <div className="tiles-designs">
                                    <Form.Item
                                        name="publish_date"
                                        label={<Typography.Title level={4}>{t('label_pub_date')}</Typography.Title>}
                                        // initialValue={dayjs()}
                                        rules={[{ required: true }]}
                                    >
                                        <DatePicker
                                            style={{ height: '30px' }}

                                        />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item name='tag1'
                                        label={<Typography.Title level={4}>{t('label_tag1')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >


                                        <Input placeholder="Enter Tag1" rows={4}


                                        />

                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >

                                    <Form.Item name='tag2'
                                        label={<Typography.Title level={4}>{t('label_tag2')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >


                                        <Input placeholder="Enter Tag2" rows={4}


                                        />

                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        name="img"
                                        label={<Typography.Title level={4}>{t('label_add_img')}</Typography.Title>}
                                        extra="Use PNG/JPG/JPEG format only"
                                    >
                                        <button
                                            icon={<UploadOutlined style={{ marginBottom: '5px' }} />}
                                            type="button"
                                            onClick={() => handleUploadClick()}
                                            className="btn btn-outline-dark"
                                            style={{ width: '150px' }}
                                        >
                                            <i className="fa-solid fa-upload" style={{ marginBottom: '5px' }}></i>&nbsp;{t('label_upload')}
                                        </button>
                                    </Form.Item>
                                </div>
                                {/* <button type="submit" class="btn btn-warning shadow-0 yellowbtn" data-mdb-ripple-color="dark"  >
                                    {t("label_save")}
                                </button> */}
                            </div>
                        </Form>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};
export default AddResource;