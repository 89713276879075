import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, message, Table, Popconfirm, DatePicker, Space } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getDonationsByMonthList, getDonationsByMonthListError, getDonationsByMonthListLoading, getDonationsList, getDonationsListError, getDonationsListLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../redux/selectors/selectors';
import { deleteDonation, fetchDailyHoroscope, fetchDonationsByMonth, fetchDonationsList, fetchRegionalNotifi } from '../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';

const DonationsByMonth = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [selectedMonth, setSelectedMonth] = useState(2);
    const [selectedYear, setSelectedYear] = useState(2024);

    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const failure = (msg) => {
        showToast('failure', t('label_donation_monthly_failure')); // You can use 'error' directly
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getDonationsByMonthList(state),
        rLoading: getDonationsByMonthListLoading(state),
        rError: getDonationsByMonthListError(state),
    }));

    useEffect(() => {
        if (selectedMonth !== null && selectedYear !== null) {
            dispatch(fetchDonationsByMonth(selectedYear, selectedMonth));
        }
    }, [selectedMonth, selectedYear]);

    function deleteDon(did, ts) {
        dispatch(deleteDonation(did, ts));
    }

    const onChange = (date, dateString) => {
        const [year, month] = dateString.split("-");
        setSelectedMonth(parseInt(month));
        setSelectedYear(parseInt(year));
    };

    const modifiedData = rList ? rList.map((item, index) => ({ ...item, "S.No": index + 1 })) : [];

    const totalAmount = modifiedData.reduce((acc, curr) => {
        if (curr.decode === "d") {
            return acc + curr.amount;
        } else if (curr.decode === "e") {
            return acc - curr.amount;
        }
        return acc;
    }, 0);

    const downloadAsPDF = () => {
        const doc = new jsPDF();

        // Define column headers
        const headers = columns.map(column => column.title.props.children);

        // Extract data from modifiedData
        const data = modifiedData.map(item => columns.map(column => item[column.dataIndex]));

        // Add headers and data to the PDF
        doc.autoTable({
            head: [headers],
            body: data,
        });

        // Save the PDF
        doc.save('donations.pdf');
    };


    const columns = [

        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_donation_id")}
                </span>
            ),
            dataIndex: "deid",
            key: "projname",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_donor_name")}
                </span>
            ),
            dataIndex: "dename",
            key: "dename",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => (
                <Link className="notification-link" to={`/donationDetails/${record.donid}`}><span className='donation-text' >{text}</span></Link>
            ),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_donation_type")}
                </span>
            ),
            dataIndex: "detype",
            key: "ctgry",
            sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_donation_type")}
                </span>
            ),
            dataIndex: "dedt",
            key: "sponsor",
            sorter: (a, b) => a.company.localeCompare(b.company),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_amount")}
                </span>
            ),
            dataIndex: "amount",
            key: "startdate",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_details")}
                </span>
            ),
            dataIndex: "decode",
            key: "startdate",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
    ];

    return (
        <div className='mainsec'>
            {contextHolder}
            <CustomToastContainer />
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t("Home")}</span></Link> -
                &nbsp;<span className="bc-link">{t("label_donations")}</span>
            </div>
            <div class="bg-details-highlight" >
                <div class=" details-headertxt container">
                    <div class="header_txt"> {t("label_donations_month_rep")}</div>
                    <div class="d-flex flex-row header_star mr-5">
                        <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                        </span>
                    </div>
                </div>
            </div>
            <div className="container" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '15px', marginTop: '9px' }}>
                <Space direction="horizontal" size="middle">
                    <DatePicker onChange={onChange} picker="month" />
                    <Link to='/addDonations' className='donation-but'>
                        <button type="button" className="btn1 btn btn-secondary">
                            {t('label_add_donations')} <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                        </button>
                    </Link>
                </Space>
            </div>
            <div className='container'>
                <Table dataSource={modifiedData} columns={columns} bordered
                    style={{ border: '2px solid black', borderCollapse: 'collapse' }}
                    footer={() => (
                        <div>
                            <p>{t("label_total_amount")} {totalAmount}</p>
                        </div>
                    )}
                />
            </div>
        </div>

    );
};

export default DonationsByMonth;
