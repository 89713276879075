import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Typography, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import statesData from '../../states.json';
import './HeaderSearch.css';
import showToast from '../../../../utils/showToast';
import ResponsiveAd from '../Advertisements/responsiveAd';

const HeaderSearch = ({ closeModal }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const [searchText, setSearchText] = useState(
        localStorage.getItem('searchTempleText') || ''
    );
    const [inputFocused, setInputFocused] = useState(false);
    const exampleTexts = ['Temples in Hyderabad', 'Temples in Karataka', 'Tirumala Tirupathi', 'Kakinada temples', 'Hanuman temple in Kakinada'];
    const [searchLibraryText, setSearchLibraryText] = useState(
        localStorage.getItem('searchLibraryText') || ''
    );
    const [selectedState, setSelectedState] = useState('All');
    const [selectedCity, setSelectedCity] = useState('All');
    const [state, setState] = useState(null);
    const { Option } = Select;
    const [filteredStates, setFilteredStates] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const states = statesData.states.map((state) => state.name);
    const [selectedItem, setSelectedItem] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {
        // Set focus to the input field with cursor
        if (inputRef.current && inputRef.current.focus) {
            inputRef.current.focus();
        }
    }, []);

    // useEffect(() => {
    //     localStorage.setItem('searchLibraryText', searchLibraryText);
    //     localStorage.setItem('searchType', 'library');
    //     window.dispatchEvent(new Event('storageChange')); // Dispatch event
    // }, [searchLibraryText]);

    // useEffect(() => {
    //     localStorage.setItem('searchTempleText', searchText);
    //     localStorage.setItem('searchType', 'temple');
    //     window.dispatchEvent(new Event('storageChange')); // Dispatch event
    // }, [searchText]);


    const handleStateChange = (value) => {
        const newState = statesData.states.find((state) => state.name === value);
        setSelectedState(value);
        setSelectedCity('All');
        setState(newState);
    };

    const handleStateSearch = (value) => {
        const filteredStates = states.filter((stateName) =>
            stateName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredStates(filteredStates);
    };

    const handleCitySearch = (value) => {
        const cities = state ? state.cities : [];
        const filteredCities = cities.filter((cityName) =>
            cityName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCities(filteredCities);
    };

    const handleNavigation = () => {
        // Use the navigate function to navigate to the other page with selected state and city as route parameters
        navigate(`/state/${selectedState}/city/${selectedCity}`);
        closeModal();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const nearbyPhrases = [
            "temples near to my home",
            "temple near to my location",
            "temples near to my loc",
            "next to my home"
        ];
        const isNearbySearch = nearbyPhrases.some((phrase) => searchText.toLowerCase().includes(phrase.toLowerCase()));
        if (searchText.length < 5) {
            return;
        }
        closeModal();
        if (isNearbySearch) {
            navigate(`/nearby/${searchText}`);
        } else {
            navigate(`/searchResults/${searchText}`);
        }

        localStorage.setItem('searchTempleText', searchText);
    };


    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;

        // Check the current route and set the corresponding category as selected
        if (currentPath.startsWith('/books')) {
            setSelectedItem('books');
        } else if (currentPath.startsWith('/articles')) {
            setSelectedItem('articles');
        } else if (currentPath.startsWith('/liveTv')) {
            setSelectedItem('livetv');
        } else if (currentPath.startsWith('/videos')) {
            setSelectedItem('videos');
        } else if (currentPath.startsWith('/mantras')) {
            setSelectedItem('mantras');
        } else if (currentPath.startsWith('/audios')) {
            setSelectedItem('audios');
        } else if (currentPath.startsWith('/temples')) {
            setSelectedItem('Temples');
        } else {
            setSelectedItem(null); // Default or fallback
        }
    }, [location.pathname]);


    const handleLibrarySubmit = (event, searchTextOverride = null) => {
        // Prevent default form submission behavior if event exists
        if (event) {
            event.preventDefault();
        }

        // Close modal if needed
        closeModal();

        // Determine the search query, either override or fallback to searchLibraryText
        const searchQuery = searchTextOverride || searchLibraryText;

        // Navigate based on the selected category
        switch (selectedItem) {
            case 'books':
                navigate(`/books/${searchQuery}`);
                break;
            case 'livetv':
                navigate(`/liveTv/${searchQuery}`);
                break;
            case 'videos':
                navigate(`/videos/${searchQuery}`);
                break;
            case 'mantras':
                navigate(`/mantras/${searchQuery}`);
                break;
            case 'articles':
                navigate(`/articles/${searchQuery}`);
                break;
            case 'audios':
                navigate(`/audios/${searchQuery}`);
                break;
            case 'Temple':
                // Using searchText (assuming it's defined somewhere else in the component)
                navigate(`/searchResults/${searchText}`);
                break;
            default:
                // Fallback for other categories or if none of the cases match
                navigate(`/librarySearch/${searchQuery}`);
                break;
        }
    };



    const handleSearch = () => {
        if (searchText && selectedState === 'All' && selectedCity === 'All') {
            navigate(`/searchResults/${searchText}`);
        } else if (!searchText && selectedState !== 'All' && selectedCity !== 'All') {
            navigate(`/state/${selectedState}/city/${selectedCity}`);
        } else {
            const st = selectedState || 'All';
            const dt = selectedCity || 'All';
            const txt = searchText || 'All';
            navigate(`/search/${st}/${dt}/${txt}`);
        }
        setSearchText('');
        closeModal();
    };

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', 'Please Login to use additional features')
        closeModal();
    };

    const handleAdd = () => {
        navigate('/templesList');
    };

    const handleAddTemple = () => {
        navigate('/addPage');
    };

    const handleTemple101 = () => {
        navigate('/templeGroup/101');
    };

    const handleFav = () => {
        navigate('/searchFavourite');
    };

    const handleRecentlyViewed = () => {
        navigate('/recentlyViewed');
    };

    const handleNearBy = () => {
        navigate('/templeGroup/117');
    };

    const handleTemplesTel = () => {
        navigate('/state/Telangana/city/All');
    };

    const handleTemplesWar = () => {
        navigate('/state/Telangana/city/warangal');
    };

    const handleTemplesNel = () => {
        navigate('/state/Telangana/city/nellore');
    };

    const handleShivTemples = () => {
        navigate('/searchResults/Shiva Temples in Telangana');
    };

    useEffect(() => {
        if (selectedItem === null) {
            setSelectedItem("Temples"); // Set default to "Temples"
        }
    }, [selectedItem]);

    const categoryButtons = ['books', 'articles', 'videos', 'livetv', 'audios', 'mantras'];

    const handleButtonClick = (category) => {
        if (category === "Temples") {
            setSelectedItem(category);
        }
        else {
            setSelectedItem(category);
            localStorage.setItem('selectedCategory', category);
        }
    };

    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(isMobileDevice());
    }, []);

    return (
        <div className='search'>
            <div >
                <div class="f2 font-weight-bold  " style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }}>
                    {/* Temples button */}
                    <button
                        type="button"
                        className={`declineButton ${selectedItem === "Temples" ? 'selected' : ''}`}
                        onClick={() => handleButtonClick("Temples")}
                    >
                        {t("label_temples_list")}
                    </button>


                    {categoryButtons.map((category, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`declineButton ${selectedItem === category ? 'selected' : ''}`}
                            onClick={() => handleButtonClick(category)}
                        >
                            {t('label_' + category)}

                        </button>
                    ))}
                </div>
            </div>
            {/* Check if selectedItem is "Temples" */}
            {selectedItem === "Temples" ? (
                <>
                    {/* Temples Search Section */}
                    <div className="float-left mt-4 mb-2 w-100">
                        <h6 className="f13-fw-600">{t('label_search_temples_type_voice')}</h6>
                    </div>



                    <form className="search-form w-100 " onSubmit={(e) => e.preventDefault()}>
                        <div className="search-width">
                            <Input
                                ref={inputRef}
                                type="text"
                                name="query"
                                placeholder="Enter search keyword"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                onFocus={() => setInputFocused(true)}
                                onBlur={() => setTimeout(() => setInputFocused(false), 200)} // Add a delay to allow clicks on suggestions
                                allowClear
                            />

                            {/* Example text suggestions */}
                            {inputFocused && (
                                <div
                                    className="example-suggestions"
                                    style={{
                                        border: '1px solid #ccc',
                                        marginTop: '5px',
                                        padding: '10px',
                                        backgroundColor: '#fff',
                                        position: 'absolute',
                                        zIndex: 1,
                                    }}
                                >
                                    {exampleTexts
                                        .filter(example => example.toLowerCase().includes(searchText.toLowerCase()))
                                        .map((example, index) => (
                                            <div
                                                key={index}
                                                style={{ cursor: 'pointer' }}
                                                onMouseDown={() => setSearchText(example)}
                                            >
                                                {example}
                                            </div>
                                        ))}
                                </div>
                            )}


                            <div >
                                {searchText.length > 0 && searchText.length < 5 && (
                                    <h5 style={{ color: 'red', margin: 0 }}>{t('label_search_chars_limit')}</h5>
                                )}
                            </div>
                        </div>
                        {/* {selectedState && selectedCity && ( */}
                            <div className='search_button'>
                                <a
                                    className="button_move"
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={searchText.length < 5}
                                // Style button
                                >
                                    {t("label_go")}
                                </a>
                            </div>
                        {/* )} */}
                    </form>

                    {/* State and City Search Section */}
                    <div className="search_header mt-5 mb-2 float-left w-100">
                        <h6 className="f13-fw-600">{t('label_search_temples_state_city')}</h6>
                    </div>

                    <div className="search-form d-flex align-items-end w-100">
                        <div className="d-flex  search-width">
                            <div class="d-flex  align-items-center search-city">
                                <span class="search-500">{t('label_state')}</span>
                                <Select
                                    showSearch
                                    name="state"
                                    value={selectedState}
                                    onChange={handleStateChange}
                                    onSearch={handleStateSearch}
                                    className="select-element"
                                >
                                    <Option value="All">{t('label_all')}</Option>
                                    {states.map((stateName) => (
                                        <Option key={stateName} value={stateName}>
                                            {stateName}
                                        </Option>
                                    ))}
                                </Select>
                            </div>

                            {/* {isMobile && (
                                <Typography.Title level={5} >{t("label_city")}</Typography.Title>
                            )} */}
                            <div class="d-flex  align-items-center search-city">
                                <span class="search-500">{t("label_city")}</span>
                                <Select
                                    showSearch
                                    name="city"
                                    value={selectedCity}
                                    onChange={(value) => setSelectedCity(value)}
                                    onSearch={handleCitySearch}
                                    className="select-element"
                                >
                                    <Option value="All">{t('label_all')}</Option>
                                    {state && state.cities.map((city) => (
                                        <Option key={city} value={city}>
                                            {city}
                                        </Option>
                                    ))}
                                </Select>
                            </div>

                        </div>

                        {selectedState && selectedCity && (
                            <div>
                                <a
                                    className='button_move '

                                    type="submit"
                                    title="Search"
                                    onClick={handleNavigation}
                                >
                                    {t("label_go")}
                                </a>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    {/* Library Search Section */}
                    <div className="search_header mt-4 mb-2 float-left">
                        <h6 className="f13-fw-600"> {t('label_search_' + selectedItem)}</h6>
                    </div>

                    <form className="search-form w-100" onSubmit={(e) => e.preventDefault()}>
                        <div className="search-width">
                            <Input
                                type="text"
                                name="query"
                                placeholder="Enter search keyword"
                                value={searchLibraryText}
                                onChange={(e) => setSearchLibraryText(e.target.value)}
                                allowClear
                            />
                            <span class="search-alert">{t('label_search_chars_limit')}</span>
                            {/* <div style={{ minHeight: '20px' }}>
                                {searchLibraryText.length > 0 && searchLibraryText.length < 5 && (
                                    <h5 style={{ color: 'red', margin: 0 }}>{t('label_search_chars_limit')}</h5>
                                )}
                            </div> */}
                        </div>

                        <a
                            className="button_move"
                            type="submit"
                            onClick={handleLibrarySubmit}
                            disabled={searchLibraryText.length < 5}
                        // style={{ marginTop: "-2%" }}
                        >
                            {t("label_go")}
                        </a>
                    </form>
                </>
            )}
            <div className="search_header mt-5 mb-2 float-left w-100">
                <h6 className="f13-fw-600">{t('label_quick_links')}</h6>
            </div>
            {selectedItem === "Temples" && (
                <div className="search-form search-quicklinks">

                    {token ? (
                        <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleAdd(); closeModal(); }}>
                            {t('label_fam_temples')}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                        </button>
                    ) : (
                        <Link onClick={handleLinkClick}>
                            <button type="button" className="btn1 btn btn-secondary">
                                {t('label_fam_temples')}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                            </button>
                        </Link>
                    )}
                    {token ? (
                        <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleAddTemple(); closeModal(); }}>
                            {t('add_temple')}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                        </button>
                    ) : (
                        <Link onClick={handleLinkClick}>
                            <button type="button" className="btn1 btn btn-secondary">
                                {t('add_temple')}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                            </button>
                        </Link>
                    )}
                    {token ? (
                        <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleRecentlyViewed(); closeModal(); }}>
                            {t('lable_recent_view')}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                        </button>
                    ) : (
                        <Link onClick={handleLinkClick}>
                            <button type="button" className="btn1 btn btn-secondary">
                                {t('lable_recent_view')}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                            </button>
                        </Link>
                    )}
                    {token ? (
                        <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleFav(); closeModal(); }}>
                            {t("page_title_fav")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                        </button>
                    ) : (
                        <Link onClick={handleLinkClick}>
                            <button type="button" className="btn1 btn btn-secondary">
                                {t("page_title_fav")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                            </button>
                        </Link>
                    )}
                    <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleTemple101(); closeModal(); }}>
                        {t("label_div_desam")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleNearBy(); closeModal(); }}>
                        {t("label_iskon_temples")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleTemplesTel(); closeModal(); }}>
                        {t("label_temples_in_state")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleTemplesWar(); closeModal(); }}>
                        {t("label_temples_in_warangal")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleTemplesNel(); closeModal(); }}>
                        {t("label_temples_in_nellore")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleShivTemples(); closeModal(); }}>
                        {t("label_shiva_temples")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                </div>
            )}
            {selectedItem === "books" && (
                <div className="search-form search-quicklinks">
                    <button
                        type="button"
                        className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'ramayanam')}
                    >
                        {t('label_ramayanam')}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>

                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'mahabharatam')}
                    >
                        {t("label_mahabharat")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'bhagavatam')}
                    >
                        {t("label_bhagavat")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'bhagavath geetha')}
                    >
                        {t("label_bhagavatgeetha")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                </div>
            )}
            {selectedItem === "articles" && (
                <div className="search-form search-quicklinks">
                    <button
                        type="button"
                        className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'ramayanam')}
                    >
                        {t('label_ramayanam')}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>

                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'mahabharatam')}
                    >
                        {t("label_mahabharat")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'bhagavatam')}
                    >
                        {t("label_bhagavat")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'bhagavath geetha')}
                    >
                        {t("label_bhagavatgeetha")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                </div>
            )}
            {selectedItem === "mantras" && (
                <div className="search-form search-quicklinks">
                    <button
                        type="button"
                        className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'ramayanam')}
                    >
                        {t('label_ramayanam')}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>

                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'mahabharatam')}
                    >
                        {t("label_mahabharat")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'bhagavatam')}
                    >
                        {t("label_bhagavat")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'bhagavath geetha')}
                    >
                        {t("label_bhagavatgeetha")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                </div>
            )}
            {selectedItem === "audios" && (
                <div className="search-form search-quicklinks">
                    <button
                        type="button"
                        className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'ramayanam')}
                    >
                        {t('label_ramayanam')}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>

                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'mahabharatam')}
                    >
                        {t("label_mahabharat")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'bhagavatam')}
                    >
                        {t("label_bhagavat")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'bhagavath geetha')}
                    >
                        {t("label_bhagavatgeetha")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                </div>
            )}
            {selectedItem === "livetv" && (
                <div className="search-form search-quicklinks">
                    <button
                        type="button"
                        className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'ramayanam')}
                    >
                        {t('label_ramayanam')}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>

                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'mahabharatam')}
                    >
                        {t("label_mahabharat")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'bhagavatam')}
                    >
                        {t("label_bhagavat")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'bhagavath geetha')}
                    >
                        {t("label_bhagavatgeetha")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                </div>
            )}
            {selectedItem === "videos" && (
                <div className="search-form search-quicklinks">
                    <button
                        type="button"
                        className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'ramayanam')}
                    >
                        {t('label_ramayanam')}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>

                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'mahabharatam')}
                    >
                        {t("label_mahabharat")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'bhagavatam')}
                    >
                        {t("label_bhagavat")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                    <button type="button" className="btn1 btn btn-secondary"
                        onClick={(e) => handleLibrarySubmit(e, 'bhagavath geetha')}
                    >
                        {t("label_bhagavatgeetha")}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                </div>
            )}
            <ResponsiveAd />
        </div>
    );
};

export default HeaderSearch;
