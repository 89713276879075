import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import showToast from '../../../../utils/showToast';
import { useTranslation } from 'react-i18next';

const FooterMobile = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        // Set the active tab based on the current path
        const path = location.pathname;
        if (path === '/') {
            setActiveTab('home');
        } else if (path.includes('templeCtgry')) {
            setActiveTab('temples');
        }
        else if (path.includes('templeDetails')) {
            setActiveTab('temples');
        }
        else if (path.includes('/templeGroup')) {
            setActiveTab('temples');
        } else if (path.includes('/templesList')) {
            setActiveTab('temples');
        } else if (path.includes('library')) {
            setActiveTab('library');
        } else if (path.includes('/videos')) {
            setActiveTab('library');
        } else if (path.includes('/books')) {
            setActiveTab('library');
        } else if (path.includes('/articles')) {
            setActiveTab('library');
        } else if (path.includes('/mantras')) {
            setActiveTab('library');
        } else if (path.includes('horoscope')) {
            setActiveTab('horoscope');
        } else if (path.includes('/weeklyHoroscope')) {
            setActiveTab('horoscope');
        } else if (path.includes('live')) {
            setActiveTab('live');
        } else if (path.includes('user')) {
            setActiveTab('user');
        }
    }, [location]);

    const token = localStorage.getItem('token');

    const handleLinkClick = () => {
        showToast('error', ('Please Login to use additional features'));
    };

    return (
        <div className="footer-mobile">
            <Link to='/' className={activeTab === 'home' ? 'active-nav' : ''}>
                <i className="fa-solid fa-house"></i>
                <div className='ignore-styles' onClick={() => setActiveTab('home')}>
                    <span>{t("Home")}</span>
                </div>
            </Link>


            <Link to='/templeCtgry' className={activeTab === 'temples' ? 'active-nav' : ''}>
                <i className="fa-solid fa-gopuram"></i>
                <div className='ignore-styles' onClick={() => setActiveTab('temples')}>
                    <span>{t("label_temples_list")}</span>
                </div>
            </Link>

            <Link to='/library' className={activeTab === 'library' ? 'active-nav' : ''} onClick={() => setActiveTab('library')}>
                <i className="fa-solid fa-photo-film"></i>
                <div className='ignore-styles' onClick={() => setActiveTab('temples')}>
                    <span>{t("label_library")}</span>
                </div>
            </Link>

            <Link to='/horoCtgry' className={activeTab === 'horoscope' ? 'active-nav' : ''} onClick={() => setActiveTab('horoscope')}>
                <i className="fa-regular fa-hand"></i>
                <div className='ignore-styles' >
                    <span>{t("label_horo")}</span>
                </div>
            </Link>

            <Link to='/livetv' className={activeTab === 'live' ? 'active-nav' : ''} onClick={() => setActiveTab('live')}>
                <i className="fa-solid fa-video"></i>
                <div className='ignore-styles' onClick={() => setActiveTab('temples')}>
                    <span>{t("label_livetv")}</span>

                </div>
            </Link>
            {token ? (<Link to='/profile' className={activeTab === 'user' ? 'active-nav' : ''} onClick={() => setActiveTab('user')}>
                <i className="fa-regular fa-circle-user"></i>
                <div className='ignore-styles' onClick={() => setActiveTab('temples')}>
                    <span>{t("label_user")}</span>

                </div>
            </Link>) : (<Link to='' className={activeTab === 'user' ? 'active-nav' : ''} onClick={() => {handleLinkClick()}}>
                <i className="fa-regular fa-circle-user"></i>
                <div className='ignore-styles' onClick={() => setActiveTab('temples')}>
                    <span>{t("label_user")}</span>
                </div>
            </Link>)}

        </div>
    );
};

export default FooterMobile;
