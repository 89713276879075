
import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


// import AddPage from './components/pages/addPage1';
// import AddPage2 from './components/pages/addPage2';
// import AddPage3 from './components/pages/addPage3';
import AddPage1 from './components/pages/Homepage/Temples/addPage1';
import ForgetPassword from './components/pages/Homepage/Profile/forgetPassword';
import TemplesList from './components/pages/Homepage/Temples/temples';
import HomePage from './components/pages/Homepage/homePage';
import TemplesDetails from './components/pages/Homepage/Temples/templeDetails';
import Profile from './components/pages/Homepage/Profile/profile';
import Contribution from './components/pages/Homepage/Drawer/contribution';
import Notification from './components/pages/Homepage/Drawer/notification';
import Favourite from './components/pages/Homepage/Drawer/favourites';
import ProfileCard from './components/pages/Homepage/Profile/profile2';
import Horoscope from './components/pages/Homepage/Resources/horoscope';
import LiveTv from './components/pages/Homepage/Resources/liveTv';

import Articles from './components/pages/Homepage/Resources/articles';
import { Provider } from 'react-redux';
import store from './redux/store/store'
import Gallery from './components/pages/Homepage/Temples/gallery';
import EditTemples from './components/pages/Homepage/Temples/editTemples';
import Books from './components/pages/Homepage/Resources/books';
import NearBy from './components/pages/Homepage/Temples/nearby';
import SearchFavourite from './components/pages/Homepage/Temples/searchFavourites';
import Shakthi from './components/pages/Homepage/shakthi';
import TempleGroup from './components/pages/Homepage/Groups/templeGroups';
import SearchResults from './components/pages/Homepage/searchResults';
import Details from './components/pages/Homepage/details/details';
import AddResource from './components/pages/Homepage/Resources/addResource';
import Resource from './components/pages/Homepage/Resources/resources';
import EditResource from './components/pages/Homepage/Resources/editResource';
import TempleData from './components/pages/Homepage/Groups/templeGroup';
import AddTempleGroup from './components/pages/Homepage/Groups/addTempleGroup';
import PageHeader from './components/pages/Homepage/Header/pageHeader';
import TempleCtgry from './components/pages/Homepage/Temples/templeCtgry';
import Footer from './components/pages/Homepage/footer/footer';
import RecentlyViewed from './components/pages/Homepage/Temples/recentlyViewed';
import StateFilter from './components/pages/Homepage/Temples/stateFilter';
import HeaderSearch from './components/pages/Homepage/Temples/headerSearch';
import TempleMap from './components/pages/Homepage/Temples/templeMap';
import Events from './components/pages/Homepage/Resources/events';
import EditEvent from './components/pages/Homepage/Resources/editEvent';
import EditHoroscope from './components/pages/Homepage/Resources/editHoroscope';
import EventDetails from './components/pages/Homepage/Resources/eventDetails';
import HoroscopeDetails from './components/pages/Homepage/Resources/horoscopeDetails';
import TempleBooks from './components/pages/Homepage/Temples/templeResource';
import ManageNotification from './components/pages/Homepage/Drawer/manageNotification';
import AddNotification from './components/pages/Homepage/Drawer/addNotifi';
import Donations from './components/admin/donations';
import Expenses from './components/admin/expenses';
import AddDonation from './components/admin/addDonation';
import AddExpenses from './components/admin/addExpenses';
import DonationDetails from './components/admin/donationDetails';
import ExpenseDetails from './components/admin/expenseDetails';
import GroupDetails from './components/pages/Homepage/Groups/groupDetails';
import AddPriest from './components/priest/addPriest';
import AddPriestReq from './components/priest/addPriestReq';
import PriestList from './components/priest/priestList';
import PriestDetails from './components/priest/priestDetails.js';
import WeeklyHoroscope from './components/pages/Homepage/Resources/weeklyHoroscope.js';
// import WeeklyHoroscopeDetails from './components/pages/Homepage/Resources/weeklyHoroscopeDetails.js';
import PriestBySpclty from './components/priest/priestBySpclty.js';
import EditWeeklyHoroscope from './components/pages/Homepage/Resources/addWeeklyHoroscope.js';
import MyReq from './components/priest/myReq.js';
import PriestReq from './components/priest/priestReq.js';
import MyReqDetails from './components/priest/myReqDetails.js';
import PriestReqDetails from './components/priest/priestreqDetails.js';
import Mantras from './components/pages/Homepage/Resources/mantras.js';
import DonationsByMonth from './components/admin/donationsByMonth.js';
import TempleService from './components/pages/Homepage/Services/templeService.js';
import CreateService from './components/pages/Homepage/Services/createService.js';
import ServiceRegistration from './components/pages/Homepage/Services/serviceRegistrstion.js';
import TempleOutsideIndia from './components/pages/Homepage/Temples/outsideIndia.js';
import ServiceDetails from './components/pages/Homepage/Services/serviceDetails.js';
import AdminService from './components/pages/Homepage/Services/adminServices.js';
import TempleDashboard from './components/pages/Homepage/Temples/templeDashboard.js';
import PendingTemples from './components/pages/Homepage/Drawer/pendingTemples.js';
import TempleServiceRegistration from './components/pages/Homepage/Services/templeServiceRegistration.js';
import ServiceRegDetails from './components/pages/Homepage/Services/serviceRegistrationDetails.js';
import MyServices from './components/admin/myservices.js';

import LiveDarshan from './components/pages/Homepage/Resources/liveDarshan.js';
import CreatePriest from './components/priest/createPriest.js';
import Audio from './components/pages/Homepage/Resources/audio.js';
import AddTempleAd from './components/admin/addTempleAdmin.js';
import TempleUsersBySt from './components/admin/templeUsersBySt.js';
import LangTranslation from './components/pages/Homepage/Temples/langTranslation.js';
import PasswordResetForm from './components/pages/Homepage/Profile/passwordReset.js';
import Library from './components/pages/Homepage/Resources/library.js';
import DirectionsPage from './components/pages/Homepage/Temples/directions.js';
import AppUsers from './components/admin/appUsers.js';
import ContactUs from './components/pages/Homepage/footer/contactUs.js';
import PrivacyPolicy from './components/pages/Homepage/footer/privacyPolicy.js';
import TermsConditions from './components/pages/Homepage/footer/terms.js';
import SearchByTxt from './components/pages/Homepage/searchByTxt.js';
import OutsideIndiaTemples from './components/pages/Homepage/Temples/outsideIndiaTemples.js';
import CreateNewPriest from './components/priest/createNewPriest.js';
import FooterMobile from './components/pages/Homepage/footer/footerMobile.js';
import ServiceRegistrationPOS from './components/pages/Homepage/Services/serviceRegistrstionPOS.js';
import TempleServiceRegistrationList from './components/admin/templeservicereglist.js';
import HoroCategeries from './components/pages/Homepage/Resources/horoCategeries.js';
import DailyHoroRaasi from './components/pages/Homepage/Resources/dailyHoroRaasi.js';
import DailyRaasiDetails from './components/pages/Homepage/Resources/dailyRaasiDetails.js';
import AddResourceTxt from './components/pages/Homepage/Resources/resourceTxt.js';
import AddHoroTxt from './components/pages/Homepage/Resources/horoTxt.js';
import ResourceFavourite from './components/pages/Homepage/Resources/resourceFavourites.js';
import QuickLinks from './components/pages/Homepage/Resources/quickLinks.js';
import AddDailyPanchangam from './components/pages/Homepage/Resources/addDailyPanchangam.js';
import TempleAdminLinks from './components/pages/Homepage/Resources/templeAdminlinks.js';
import TodayPanchangam from './components/pages/Homepage/Resources/todayPanchangam.js';
import EditDailyHoroscope from './components/pages/Homepage/Resources/addDailyHoroscope.js';
import AccountActivation from './components/pages/Homepage/accountActivation.js';
import TempleInIndia from './components/pages/Homepage/Temples/inIndia.js';
import CityPage from './components/pages/Homepage/Temples/cityPage.js';





function App() {
  return (
    <Provider store={store}>
      <div>
        <Router>
          <PageHeader/>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/templeDetails/:tid' element={<TemplesDetails />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/contribution' element={<Contribution />} />
            <Route path='/notification' element={<Notification />} />
            <Route path='/favourite' element={<Favourite />} />
            <Route path='/notificationForm' element={<ProfileCard />} />
            <Route path='/horoscope' element={<Horoscope />} />
            <Route path='/videos/:uid' element={<LiveDarshan />} />
            <Route path='/videos' element={<LiveDarshan />} />
            <Route path='/liveTv/:uid' element={<LiveTv />} />
            <Route path='/liveTv' element={<LiveTv />} />
            <Route path='/articles/:uid' element={<Articles />} />
            <Route path='/articles' element={<Articles />} />
            <Route path='/books/:uid' element={<Books />} />
            <Route path='/books' element={<Books />} />
            <Route path='/mantras/:uid' element={<Mantras />} />
            <Route path='/mantras' element={<Mantras />} />
            <Route path='/addPage' element={<AddPage1/>}/>
            <Route path='/gallery/:tid' element={<Gallery/>}/>
            <Route path='/editTemples/:tid' element={<EditTemples/>}/>
            <Route path='/forget' element={<ForgetPassword/>}/>
            <Route path='/nearby' element={<NearBy/>}/>
            <Route path='/searchFavourite' element={<SearchFavourite/>}/>
            <Route path='/shakthi' element={<Shakthi/>}/>
            <Route path='/templeGroup/:gid' element={<TempleGroup />} />
            <Route path='/searchResults/:searchText' element={<SearchResults/>}/>
            <Route path='/details/:tid' element={<Details/>}/>
            <Route path='/addResource' element={<AddResource />} />
            <Route path='/resource/:uid' element={<Resource />} />
            <Route path='/editResource/:rid' element={<EditResource/>}/>     
            <Route path='/templeData' element={<TempleData/>}/>      
            <Route path='/addTempleGroup/:rid' element={<AddTempleGroup/>}/> 
            <Route path='/templeCtgry' element={<TempleCtgry/>}/> 
            <Route path='/templesList' element={<TemplesList/>}/> 
            <Route path='/recentlyViewed' element={<RecentlyViewed/>}/> 
            <Route path='/state/:selectedState/city/:selectedCity' element={<StateFilter/>}/>
            <Route path='/headerSearch' element={<HeaderSearch/>}/> 
            <Route path='/templeMap' element={<TempleMap/>}/>
            <Route path='/events' element={<Events/>}/> 
            <Route path='/editEvent/:nid' element={<EditEvent/>}/>
            <Route path='/editHoroscope/:hid' element={<EditHoroscope/>}/> 
            <Route path='/notifiDetails/:nid' element={<EventDetails/>}/> 
            <Route path='/horoscopeDetails/:hid' element={<HoroscopeDetails/>}/>
            <Route path='/templeBooks' element={<TempleBooks/>}/> 
            <Route path='/manageNotification' element={<ManageNotification />} />
            <Route path='/addNotification' element={<AddNotification/>}/>
            <Route path='/donations' element={<Donations/>}/>
            <Route path='/myservices' element={<MyServices/>}/>
            <Route path='/templeservicereglist' element={<TempleServiceRegistrationList/>}/>
            <Route path='/donationsByMonth' element={<DonationsByMonth/>}/>
            <Route path='/expenses' element={<Expenses/>}/>
            <Route path='/addDonations' element={<AddDonation/>}/>
            <Route path='/addExpenses' element={<AddExpenses/>}/>
            <Route path='/donationDetails/:donid' element={<DonationDetails/>}/>
            <Route path='/expenseDetails/:expid' element={<ExpenseDetails/>}/>
            <Route path='/groupDetails/:gid' element={<GroupDetails/>}/>
            <Route path='/addPriest' element={<AddPriest/>}/>
            <Route path='/addPriestReq/:id/:name' element={<AddPriestReq/>}/>
            <Route path='/addPriestReq' element={<AddPriestReq/>}/>
            <Route path='/priestList' element={<PriestList/>}/>
            <Route path='/priestDetails/:id/:name/:city/:st' element={<PriestDetails/>}/>
            <Route path='/priestDetails/:id' element={<PriestDetails/>}/>
            <Route path='/weeklyHoroscope' element={<WeeklyHoroscope />} />
            {/* <Route path='/weeklyHoroscopeDetails/:hid' element={<WeeklyHoroscopeDetails/>}/> */}
            <Route path='/dailyRaasiDetails/:hid' element={<DailyRaasiDetails/>}/> 
            <Route path='/priestBySpclty' element={<PriestBySpclty/>}/>
            <Route path='/editWeeklyHoroscope/:hid' element={<EditWeeklyHoroscope/>}/>
            <Route path='/myReq' element={<MyReq/>}/>
            <Route path='/priestReq' element={<PriestReq/>}/>
            <Route path='/reqDetails/:reqid' element={<MyReqDetails/>}/>
            <Route path='/priestReqDetails/:reqid' element={<PriestReqDetails/>}/>
            <Route path='/templeService/:tid/:tname' element={<TempleService/>}/>
            <Route path='/createService' element={<CreateService/>}/>
            <Route path='/serviceRegistration/:capacity/:amount/:servid/:bookbefore/:title' element={<ServiceRegistration/>}/>
            <Route path='/serviceRegistrationPOS/:capacity/:amount/:servid/:title' element={<ServiceRegistrationPOS/>}/>
            <Route path='/outsideIndia' element={<TempleOutsideIndia/>}/>
            <Route path='/serviceDetails/:id/:tname' element={<ServiceDetails/>}/>
            <Route path='/adminService' element={<AdminService/>}/>
            <Route path='/templeDashboard' element={<TempleDashboard/>}/>
            <Route path='/pendingTemples' element={<PendingTemples/>}/>
            <Route path='/templeServiceRegistration/:id' element={<TempleServiceRegistration/>}/>
            <Route path='/serviceRegDetails/:sregid' element={<ServiceRegDetails/>}/>
            <Route path="/resetpwd" element={<PasswordResetForm/>} />
            <Route path='/createPriest' element={<CreatePriest/>}/>
            <Route path='/createNewPriest' element={<CreateNewPriest/>}/>
            <Route path='/audios/:uid' element={<Audio/>}/>
            <Route path='/audios' element={<Audio/>}/>
            <Route path='/addTempleAd' element={<AddTempleAd/>}/>
            <Route path='/templeUsersBySt' element={<TempleUsersBySt/>}/>
            <Route path='/langTrans/:tid' element={<LangTranslation/>}/>
            <Route path='/library' element={<Library/>}/>
            <Route path="/directions" element={<DirectionsPage />} />
            <Route path="/appUsers" element={<AppUsers/>} />
            <Route path="/contactUs" element={<ContactUs/>} />
            <Route path="/privacy" element={<PrivacyPolicy/>} />
            <Route path="/terms" element={<TermsConditions/>} />
            <Route path='/search/:st/:dt/:txt' element={<SearchByTxt/>}/>
            <Route path='/outsideIndia/:txt/:gid' element={<OutsideIndiaTemples/>}/>
            <Route path='/inIndia' element={<TempleInIndia/>}/>
            <Route path='/horoCtgry' element={<HoroCategeries/>}/> 
            <Route path='/dailyRaasi' element={<DailyHoroRaasi/>}/>
            <Route path='/dailyRaasi' element={<DailyHoroRaasi/>}/> 
            <Route path='/dailyRaasiDetails' element={<DailyRaasiDetails/>}/> 
            <Route path='/addResTxt' element={<AddResourceTxt/>}/>
            <Route path='/addHoroTxt' element={<AddHoroTxt/>}/>
            <Route path='/resourceFavorite' element={<ResourceFavourite/>}/>
            <Route path='/quicklinks' element={<QuickLinks/>}/>
            <Route path='/adddailypanchangam' element={<AddDailyPanchangam/>}/>
            <Route path='/templeadminlinks' element={<TempleAdminLinks/>}/>
            <Route path='/todaypanchangam' element={<TodayPanchangam/>}/>
            <Route path='/editDailyHoroscope/:hid' element={<EditDailyHoroscope/>}/>
            <Route path="/acc_activated/:userid" element={<AccountActivation/>} />
            <Route path="/cityPage/:state" element={<CityPage/>} />
          </Routes>
          <FooterMobile />
        </Router>
        {/* <ProfileCard/> */}
        {/* <Profile/> */}
        {/* <NotificationForm/> */}
        
      </div>
    </Provider>
  );
}

export default App;
