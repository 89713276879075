import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchNotifications, postClearALLNotifi, removeNotification } from '../../../../redux/actions/acions';
import { getNotificationsError, getNotificationsList, getNotificationstionsLoading } from '../../../../redux/selectors/selectors';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, Card, Modal, Popconfirm, Result, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Error from '../error';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

let last_rec = 0;
const Notification = () => {
  const { t } = useTranslation();
  const records_per_page = 15;
  const [clearAll, setClearAll] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const storedLanguage = localStorage.getItem('lng') || 5;
  const dispatch = useDispatch();
  const { nList, nLoading, nError } = useSelector(state => ({
    nList: getNotificationsList(state),
    nLoading: getNotificationstionsLoading(state),
    nError: getNotificationsError(state),
  }));

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    showToast('success', t('label_delete_notifi_sucess'))
    window.location.reload()
  };

  const failure = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };

  useEffect(() => {
    dispatch(fetchNotifications(storedLanguage, 0))
  }, []);

  function deleteNotification(id) {
    const data = {
      "nid": id,
      "status": "d",
      "prev_status": ""
    };

    // Dispatch an action to remove the notification via API
    dispatch(removeNotification(data, success, failure));

    // Update the UI by filtering out the deleted notification
    setCurrentPageTemples(currentPageTemples.filter(notification => notification.nid !== id));

    // Optionally, you can display a success message
    // warning();
  }


  function ClearALLNotifi() {
    dispatch(postClearALLNotifi(success, failure))
    setClearAll(true);
  }



  // const nextSetRef = useRef(null);


  // useEffect(() => {
  //     // Add a scroll event listener to the window
  //     window.addEventListener("scroll", handleScroll);
  //     return () => {
  //         // Remove the scroll event listener when the component unmounts
  //         window.removeEventListener("scroll", handleScroll);
  //     };
  // }, []);

  // const handleScroll = () => {
  //     const windowHeight =
  //         "innerHeight" in window
  //             ? window.innerHeight
  //             : document.documentElement.offsetHeight;
  //     const body = document.body;
  //     const html = document.documentElement;
  //     const docHeight = Math.max(
  //         body.scrollHeight,
  //         body.offsetHeight,
  //         html.clientHeight,
  //         html.scrollHeight,
  //         html.offsetHeight
  //     );
  //     const windowBottom = windowHeight + window.pageYOffset;

  //     // Detect if the user has scrolled to the bottom of the page
  //     if (windowBottom >= docHeight && !isLoading) {
  //         // Load more data when scrolling to the end
  //         nextTemplesList();
  //     }
  // };

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
  }, [])

  // function isNotificationDeleted(id) {
  //   return deletedIds.includes(id);
  // }

  function isNotificationDeleted(id) {
    const data = {
      "nid": id,
      "status": "d",
      "prev_status": ":"
    }
    // dispatch(removeNotification(data, failure))
    return deletedIds.includes(id);

  }

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTemples, setCurrentPageTemples] = useState([]);

  useEffect(() => {
    dispatch(fetchNotifications(storedLanguage, last_rec));
  }, [storedLanguage, currentPage]);

  useEffect(() => {
    if (!nLoading && nList) {
      setCurrentPageTemples([...currentPageTemples, ...nList]);
    }
  }, [nLoading]);

  function nextTemplesList() {
    setIsLoading(true);
    last_rec = last_rec + records_per_page;
    // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
    dispatch(fetchNotifications(last_rec));
    // setIsLoading(false);
  }
  const { confirm } = Modal;

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>

      <div class="mainsec">
        <div class=" mob-nav fluid-container page-title justify-content-between">
          <div class="d-flex align-items-center left-sec-mob-nav">
            <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
            <span class="bc-link">{t('label_notif')}</span>
          </div>
          <div></div>
        </div>
        <div className="f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link">
          <Link style={{ color: 'black' }} to="/">
            <span className="bc-active-link">{t('Home')}</span>
          </Link> -
          <span className="bc-link"> {t("label_notif")}</span>
        </div>

        <div class="bg-details-highlight" >
          <div class=" details-headertxt container">
            <div class="header_txt"> {t("label_notif")}</div>
            <div class="">
              <a type="button" class="button_move" onClick={ClearALLNotifi} style={{ fontSize: "bold" }}>{t("label_clear_all")}</a>
            </div>
            <div class="d-flex flex-row header_star mr-5">
              <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
              </span>
            </div>
          </div>
        </div>
        <div className="mainsec-block-addtemple-detailspage">
          {clearAll ? (
            <></>
          ) : (
            <div className="container">
              {nLoading ? (
                <Error />
              ) : (
                nList && nList.length > 0 ? (
                  nList
                    .filter((notificationList) => !isNotificationDeleted(notificationList.nid))
                    .map((notificationList) => {
                      const isRead = notificationList.read;
                      const notificationClass = isRead ? 'notification-read' : 'notification-unread'; // apply different class based on read status
                      return (
                        <div
                          key={notificationList.nid}
                          className="tiles-designs-events"
                        >
                          <div className="d-flex flex-space-between">
                            <Link to={`/notifiDetails/${notificationList.nid}`} className="notification-link">
                              <div className="f1 events-block-link-title">
                                <i className="fa-solid fa-bell mr-3"></i>{' '}
                                <span className="f1">{notificationList.subject}</span>{' '}
                              </div>
                            </Link>
                            <div className="d-flex" style={{marginTop:"17px"}}>
                              <a
                                className="share-button"
                                style={{ color: "#c15506", marginTop:"-3px", marginRight:"15px" }}
                                onClick={() => {
                                  if (navigator.share) {
                                    navigator.share({
                                      title: "Know " + notificationList.subject,
                                      text: "Know " + notificationList.subject,
                                      url: `https://www.templeswiki.com/eventDetails/${notificationList.nid}`
                                    })
                                      .then(() => console.warn("Share successful."))
                                      .catch((error) => console.error("Error sharing:", error));
                                  } else {
                                    // Fallback for browsers that do not support the Web Share API
                                    console.error("Web Share API not supported.");
                                  }
                                }}
                              >
                                <i title="Share" className="fa-solid fa-share-nodes"></i>
                              </a>
                              <Popconfirm
                                title="Are you sure you want to delete this notification?"
                                okText="Yes"
                                okType="danger"
                                cancelText="No"
                                onConfirm={() => {
                                  deleteNotification(notificationList.nid);
                                }}
                                onCancel={() => {
                                  console.warn('Deletion cancelled');
                                }}
                              >
                                <i className="fa-solid fa-trash" style={{color:"#c15506"}}></i>
                              </Popconfirm>
                            </div>
                          </div>
                          <span className="f1 events-block-link-value">{notificationList.txt}</span>
                        </div>
                      );
                    })
                ) : (
                  <Result
                    status="404"
                    title="No Data Found"
                    subTitle="Sorry, no notifications found."
                  />
                )
              )}
            </div>
          )}

        </div>

        <Footer />
      </div>
    </>
  )
}

export default Notification;
