import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Result, Row, Skeleton, message } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getWeeklyHoroscopeLis, getWeeklyHoroscopeList, getWeeklyHoroscopeListLoading, getWeeklyHoroscopeLoading } from '../../../../redux/selectors/selectors';
import { fetchDailyHoroscope, fetchWeeklyHoroscope, fetchWeeklyHoroscopeList } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.png';
import { useTranslation } from 'react-i18next';
import { EditOutlined, ShopTwoTone } from '@ant-design/icons';
import Error from '../error';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

const WeeklyHoroscope = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;

    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const failure = (msg) => {
        showToast('fail', t('label_whoroempty_failure'))
        // messageApi.error(msg); // You can use 'error' directly
    };

    const { hDetails, hLoading } = useSelector((state) => ({
        hDetails: getWeeklyHoroscopeLis(state),
        hLoading: getWeeklyHoroscopeListLoading(state),
    }));

    useEffect(() => {
        dispatch(fetchWeeklyHoroscopeList(storedLanguage, 'whoro', 0, failure));
    }, [dispatch]);

    const userid = localStorage.getItem('urole')

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_weekly_horoscope')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}
            
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                <span className="bc-active-link" onClick={handleGoBack}> {t('label_panchangam')}</span> -
                &nbsp;<span className="bc-link">{t("label_weekly_horoscope")}</span>
            </div>
            <div class="bg-details-highlight" >
                <div class=" details-headertxt container">
                    <div class="header_txt"> {t("label_weekly_horoscope")}</div>
                    <div class="">
                        {userid === "AD" || userid === "AS" ? (
                            <Link to='/editWeeklyHoroscope/0'><button type="button" class="button_move" style={{ marginLeft: '5px', marginBottom: '5px' }}>{t("label_create_weekly_horo")}</button></Link>
                        ) : (null)}
                        <Link to='/horoscope'><button type="button" class="button_move" style={{ marginLeft: '5px', marginBottom: '5px' }}>{t("label_daily_panchamgam")}</button></Link>
                    </div>
                    <div class="d-flex flex-row header_star mr-5">
                        <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                        </span>
                    </div>
                </div>
            </div>
            <div class="mainsec-block-addtemple-detailspage">
                <div className='container'>

                    {hLoading ? (
                        <Error />
                    ) : Array.isArray(hDetails) ? (
                        hDetails.map((notificationList) => {
                            return (
                                <>
                                    <Link to={`/dailyRaasiDetails/${notificationList.hid}`} className="notification-link">


                                        <div key={notificationList.nid} className="tiles-designs-events" >
                                            <div class="d-flex flex-space-between"><div class="f1 events-block-link-title" >
                                                <i class="fa-solid fa-calendar-week mr-3"></i>
                                                <span class="f1">{notificationList.title}</span>
                                                {/* <Link to={`/editEvent/${notificationList.nid}`} >
                                    <EditOutlined className="edit-icon" title="Edit" />
                                    </Link> */}
                                            </div>
                                                <div class="d-flex">
                                                    {/* <div className="detail-but ml-3">
                                                    Goto Temple Page
                                                </div> */}
                                                    {/* <Link to={`/editEvent/${notificationList.nid}`} >
                                                    <div className="detail-but ml-3" >
                                                        Edit
                                                    </div>
                                                </Link> */}
                                                </div></div>

                                            <span class="f1 events-block-link-value">{notificationList.subject}</span>

                                        </div>


                                    </Link>
                                    {/* <span class="f1 events-block-links-title">{notificationList.subject}</span>
                                <Link to={`/editEvent/${notificationList.nid}`} >
                                    <EditOutlined className="edit-icon" title="Edit" />
                                </Link>
                                <span class="f1 events-block-links">{notificationList.txt}</span> */}

                                </>
                            )
                        })
                    ) : (
                        <Result status="404" title="No Data Found" subTitle="Sorry, Horoscope details not found." />
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default WeeklyHoroscope;
