import {
    Form, Input, Typography,
    Col,
    Row,
    Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import "../../addTemple.css"
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getGodNames, getGodNamesError, getGodNamesLoading, } from '../../../../redux/selectors/selectors';
import { fetchGodNames, postResourceTxt, retrieveGodNames } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import aum from '../../../../assets/icons/aum.png'
import TextArea from 'antd/es/input/TextArea';
import Footer from '../footer/footer';
import { Option } from 'antd/es/mentions';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import deityImages from '../../../common/godImages';





const AddResourceTxt = () => {

    const { t } = useTranslation();
    const [selectedDeity, setSelectedDeity] = useState(null);
    const localEnv = false;
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { godCtgryList, godCtgryListLoading, godCtgryListError } = useSelector(state => ({
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    }))

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])

    const rid = localStorage.getItem('resid')
    const success = () => {
        showToast('success', t('label_add_notifi_sucess'))
        form.resetFields()
        Navigate(`/editResource/${rid}`)
    };

    const failure = (msg) => {
        showToast('error', msg)
        form.resetFields()
    };

    const [showAllImages, setShowAllImages] = useState(false);


    const handleImageClick = () => {
        setShowAllImages(true);
    };

    const handleDeityImageClick = (deityNumber) => {
        setSelectedDeity(deityNumber);
        setShowAllImages(false);
        //dispatch(postResource({ did: did, }));
    };


    const handleCloseModal = () => {
        setShowAllImages(false);
    };

    const userid = localStorage.getItem('uid')
    const onFinish = () => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const data = {
                    raw_text: values.raw_text,
                    avatar: selectedDeity !== null ? selectedDeity : values.maindeity,
                    res_type: values.res_type,
                }
                dispatch(postResourceTxt(data, success, failure))
            })
            .catch((errorInfo) => {
                console.error('Validation failed:', errorInfo);
            });

    };
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        // Reset the form to its initial values
        form.resetFields();
        navigate(-1);
        // setButtonText('Edit');
        // setEditable(false);
    };

    return (
        <>
            
            <div class='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_res_details')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> -
                    {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                    &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('label_create_resource')}</span> -
                    &nbsp; <span className="bc-link">{t("label_res_details")}</span>
                </div>
                <div class="bg-details-highlight">
                    <div class=" details-headertxt container">
                        <div class="header_txt"> {t('label_res_details')}</div>
                        <div class="">
                            <a href="#" class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                                {t('label_cancel')}
                            </a>
                            <a href="#" class="button_move" ype="primary" htmlType="submit" onClick={onFinish}>
                                {t("label_save")}
                            </a>
                        </div>
                        <div class="d-flex flex-row header_star mr-5">
                            <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="mainsec-block-detailspage">
                    <div className='container'>
                        {/* <div className='details-page'> */}
                        {/* <Link to={'/'}><Typography.Text>Back</Typography.Text></Link> */}
                        <Form form={form} name="nest-messages" onFinish={onFinish}
                        // labelCol={{
                        //     span: 7,
                        // }}
                        // wrapperCol={{
                        //     span: 9,
                        // }}
                        >
                            <div class="container" ><h5 class="card-text-title">{t("label_res_details")} <span></span></h5></div>
                            {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t("label_enter_tmpl_det")}</Typography.Title> */}
                            <div class="active-details">
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t('label_sel_diety_img')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    // name='avatar'
                                    >

                                        <>
                                            <img
                                                src={selectedDeity ? deityImages[selectedDeity] : deityImages[0]}
                                                alt="Selected Deity Image"
                                                onClick={handleImageClick}
                                                style={{ cursor: 'pointer', width: '5%' }}
                                            />
                                            <div class="popup-bg" style={{ display: showAllImages ? 'flex' : 'none' }}>
                                                <div class="popup-main-filter animate__animated animate__fadeInUp ">
                                                    <div class="popup-main-header">
                                                        <span class="popup-main-header-text">{t('label_god_categ')}</span>
                                                        <div class="popup-close" onClick={handleCloseModal}><i class="fa-solid fa-xmark"></i></div>
                                                        <div class="popup-back" onClick={handleCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                                                    </div>
                                                    <div class="popup-main-container">
                                                        <div class="d-block htinner-popup-main-container">
                                                            <div className=" god-category-list">
                                                                <div className="all-gods-sec">{t('label_all')}</div>
                                                                <div className="god_select">
                                                                    <div className="god_pic">
                                                                        <img
                                                                            className="dietyImg"
                                                                            src={aum}
                                                                            alt="All"
                                                                            onClick={() => handleDeityImageClick(null)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="all-gods-sec">{t("label_filter_by_god")}</div>
                                                                <Row >
                                                                    {godCtgryList && godCtgryList.map((godCtgry) => (
                                                                        <Col key={godCtgry.avatar}>
                                                                            <div className="god_select">
                                                                                <div className="god_pic">
                                                                                    <img
                                                                                        src={deityImages[godCtgry.avatar]}
                                                                                        alt="Deity Image"
                                                                                        style={{
                                                                                            width: '80px',
                                                                                            height: '80px',
                                                                                            margin: '5px',
                                                                                            cursor: 'pointer',
                                                                                            border: '1px solid #ddd'
                                                                                        }}
                                                                                        onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                                                                    />
                                                                                </div>
                                                                                <p>{godCtgry.deity}</p>
                                                                            </div>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="popup-main-footer">
                                                        <a className="button_move" onClick={handleCloseModal}>{t('label_cancel')}</a>

                                                    </div>
                                                </div>
                                            </div>

                                        </>


                                    </Form.Item>
                                </div>

                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='raw_text'
                                        label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <TextArea rows={13} placeholder={t('label_enter_details')} />
                                    </Form.Item>
                                </div>

                                {/* <div class="container" ><h5 class="card-text-title">{t('label_additi_det')} <span>| {t('label_category_language')}</span></h5></div>

                            <div class="active-details"> */}
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='res_type'
                                        label={<Typography.Title level={4}>{t('label_resource_type')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select placeholder='-- Select Resource --'>
                                            <Option value="videos">{t("label_videos")}</Option>
                                            <Option value="livetv">{t("label_livetv")}</Option>
                                            <Option value="books">{t("label_books")}</Option>
                                            <Option value="audios">{t("label_audios")}</Option>
                                            <Option value="articles">{t("label_articles")}</Option>
                                            <Option value="mantras">{t("label_mantras")}</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <Footer />
                </div>

            </div>

        </>
    );
};
export default AddResourceTxt;