import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Upload, Card, Col, DatePicker, Form, Image, Input, Popconfirm, Row, Select, Checkbox, message } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getExpenseDetails, getExpenseDetailsError, getExpenseDetailsLoading, getNotificationDetailsError, getNotificationDetailsList, getNotificationDetailsLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../redux/selectors/selectors';
import { deleteExpenses, fetchDailyHoroscope, fetchExpensesDetails, fetchNotificationDetails, fetchRegionalNotifi, postLibraryUploadImageRequest, updateDonationBill, updateDonationNotes, updateExpenseAmount, updateExpenseBill, updateExpenseNotes, updateExpenseType } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.jpg'
import { CloseCircleOutlined, DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getImageAPI, getCoreImagesAPI } from "../../http-common";
import Error from '../pages/Homepage/error';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import jsPDF from 'jspdf';
import ImgCrop from 'antd-img-crop';
import ReactCrop from 'react-image-crop';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import 'jspdf-autotable';
import Footer from '../pages/Homepage/footer/footer';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';

const ExpenseDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { expid } = useParams()
    const dispatch = useDispatch();
    const token = Boolean(localStorage.getItem('token'));
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [amount, setAmount] = useState(null);
    const [notes, setNotes] = useState(null);
    const [bill, setBill] = useState(null);
    const [expenses, setExpenses] = useState('expenses');
    const [previewImage, setPreviewImage] = useState(null);


    const failure = (msg) => {
        showToast('failure', msg);
    };

    const deleteSuccess = () => {
        showToast('deleteSuccess', t('label_expense_delete_success'));
        navigate(-1);
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getExpenseDetails(state),
        rLoading: getExpenseDetailsLoading(state),
        rError: getExpenseDetailsError(state),
    }));

    useEffect(() => {
        dispatch(fetchExpensesDetails(expid));
    }, [dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };


    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, deityname: rList && rList.deityname });
            setFormData({ ...formData, otherdeities: rList && rList.otherdeities });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const AmountSuccess = () => {
        showToast('success', t('label_expense_amount_success'));
        // form.resetFields()
    };

    const handleSelect = (value) => {
        dispatch(updateExpenseType(expid, { exptype: value }, AmountSuccess, failure))
    };

    const handleAmount = (e) => {
        setAmount(e.target.value)
    };

    const updateAmount = () => {
        dispatch(updateExpenseAmount(expid, { amount: amount }, AmountSuccess, failure));
    };

    const NotesSuccess = () => {
        showToast('success', t('label_expense_notes_success'));
        // form.resetFields()
    };

    const handleNotes = (e) => {
        setNotes(e.target.value)
    };

    const updateNotes = () => {
        dispatch(updateExpenseNotes(expid, { notes: notes }, NotesSuccess, failure));
    };
    const BillSuccess = () => {
        showToast('success', t('label_expense_bill_success'));
        // form.resetFields()
    };

    const handleBill = (e) => {
        setBill(e.target.value)
    };

    const updateBill = () => {
        dispatch(updateExpenseBill(expid, { bill: bill }, BillSuccess, failure));
    };


    function deleteExp(did, ts) {
        dispatch(deleteExpenses(did, ts, deleteSuccess, failure));
    }

    const categoryLabels = {
        'E': t('label_event'),
        'S': t('label_spcl_puja'),
        'R': t('label_repaire'),
        'P': t('label_puja_items'),
        'N': t('label_new_item'),
        'D': t('label_daily_expenses'),
    };

    const typeLabels = {
        'D': t('label_daily'),
        'W': t('label_weekly'),
        'A': t('label_always'),
    };

    const downloadAsPDF = (expenseDetails) => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Expenses Bill', 14, 15);

        const rows = [
            { label: t('label_temple_name'), value: expenseDetails.tname },
            { label: t('label_vendor'), value: expenseDetails.vendor },
            { label: t('label_date'), value: formatDate(expenseDetails.expdt) },
            { label: t('label_amount'), value: expenseDetails.amount },
            { label: t('label_notes'), value: expenseDetails.notes },
            { label: t('label_categ'), value: categoryLabels[expenseDetails.ctgry] || t('label_notchosen') },
        ];

        const formattedRows = rows.map(item => [item.label, item.value]);

        doc.autoTable({
            startY: 20,
            body: formattedRows,
            theme: 'grid',
        });

        doc.save('Expense_Details.pdf');
    };

    const columns = [
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Download
                </span>
            ),
            dataIndex: "download",
            key: "download",
            render: (text, record) => (
                <i className="fa-solid fa-download fa-lg" style={{ cursor: 'pointer' }} onClick={() => downloadAsPDF(record)}></i>
            ),
        },
    ];

    const [selectedImage, setSelectedImage] = useState(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });

    const normFile = (e) => {
        console.warn('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e.fileList;
    };

    const onImageLoaded = (image) => {
        console.warn(image);
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const onCropComplete = (crop, pixelCrop) => {
        console.warn(crop, pixelCrop);
    };

    const onFileChange = (info) => {
        if (info.file.status === 'done') {
            // File has been uploaded successfully
            setSelectedImage(info.file.response.path); // Update state with the uploaded image path
        }
    };

    const [newImagePath, setNewImagePath] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [cropper, setCropper] = useState(null);
    const imageRef = useRef();


    const handleImageLinkClick = () => {
        showToast('error', t(`Please login to upload image.`))
    };

    const handleImageCloseModal = () => {
        setIsModalVisible(false);
        if (cropper) {
            cropper.destroy(); // Destroy cropper instance when modal is closed
        }
    };

    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(isMobileDevice());
    }, []);

    const handleCameraClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.capture = 'environment'; // Use 'user' for front camera and 'environment' for back camera

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];

            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);

                dispatch(postLibraryUploadImageRequest(expenses, expid, formData, () => {
                    const uploadedImagePath = URL.createObjectURL(selectedFile);
                    setNewImagePath(uploadedImagePath);
                    console.warn('Image uploaded successfully');
                }, (errorMsg) => {
                    console.error('Image upload failed:', errorMsg);
                }));
            } else {
                console.warn('No file selected');
            }

            document.body.removeChild(fileInput); // Clean up the file input
        });


        document.body.appendChild(fileInput);
        fileInput.click();
    };

    const handleGalleryClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*,application/pdf'; // Accept both images and PDFs
        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile); // Append the selected file (image or PDF)
                // Check the file type for custom handling (optional)
                const fileType = selectedFile.type;
                if (fileType.startsWith('image/')) {
                    console.log('Image selected:', selectedFile.name);
                } else if (fileType === 'application/pdf') {
                    console.log('PDF selected:', selectedFile.name);
                }
                // Upload the file (either image or PDF)
                dispatch(postLibraryUploadImageRequest(expenses, expid, formData, () => {
                    console.log('File uploaded successfully');
                    handleImageCloseModal(); // Close modal if applicable
                }, (errorMsg) => {
                    console.error('File upload failed:', errorMsg);
                }));
            }
        });
        fileInput.click(); // Open the file selection dialog
    };

    const downloadPDF = () => {
        const fileUrl = getCoreImagesAPI() + rList.bill; // Dynamically use the path from rList
        fetch(fileUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.arrayBuffer())  // Use arrayBuffer instead of blob
            .then((buffer) => {
                const blob = new Blob([buffer], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Expense_Details.pdf'); // File name for the download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
            });
    };

    const handleSaveClick = () => {
        const notesDataToSend = {
            notes: notes || rList.notes,
        };
        dispatch(updateExpenseNotes(expid, notesDataToSend, NotesSuccess, failure));
        setEditable(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <div className='mainsec'>
            {contextHolder}

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('label_expenses')}</span> -
                &nbsp; <span className="bc-link">{t("label_expense_details")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt container">
                    <div class="header_txt"> {rList && rList.vendor}</div>
                    <div className="">
                        {editable ? (
                            <>
                                <a
                                    href="#"
                                    className="button_move"
                                    onClick={handleSaveClick}
                                    style={{ marginRight: '5px', fontWeight: '200px' }}
                                >
                                    {t("label_save")}
                                </a>
                                <a
                                    href="#"
                                    className="button_move"
                                    onClick={handleCancelClick}
                                    style={{ marginRight: '10px' }}
                                >
                                    {t("label_cancel")}
                                </a>
                            </>
                        ) : (
                            <a
                                href="#"
                                className="button_move"
                                onClick={handleEditClick}
                            >
                                {buttonText}
                            </a>
                        )}
                    </div>
                    <div className="" style={{ marginLeft: '10px' }}>
                        <a href="#" className="button_move" onClick={() => downloadAsPDF(rList)} style={{ marginRight: '10px' }}>
                            {t("label_print")}
                        </a>
                        <Button className="button_move" type="primary" style={{ marginRight: '10px' }}>
                            <Popconfirm
                                title="Are you sure you want to delete?"
                                onConfirm={() => deleteExp(rList.expid, rList.ludt)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Link>Delete</Link>
                            </Popconfirm>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    {/* <Link to={`/editHoroscope/${rList&&rList.hid}`}>
                    <EditOutlined className="edit-icon" title="Edit" />
                </Link> */}
                    {rLoading ? (
                        <p>Loading...</p>
                    ) : (
                        rList && (
                            <div className='horoscope-item' >
                                <div  class="float-left w-100 mt-4" ><h5 class="card-text-title">{t('label_expense_details')} <span>| {t('label_contains_expense_id')}</span></h5></div>
                                {/* <h2>{rList.author}</h2> */}
                                <div class="active-details">
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_temple_name")}</Typography.Title>}
                                            name="Temple Name"
                                        >
                                            <Typography.Text level={5} className='typ'>{rList.tname}</Typography.Text>
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_vendor")}</Typography.Title>}
                                            name="st"
                                        >
                                            <Typography.Text level={5} className='typ'>{rList.vendor}</Typography.Text>
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_expense_date")}</Typography.Title>}
                                            name="addr1"
                                        >
                                            <Typography.Text level={5} className='typ'> {rList && formatDate(rList.expdt)}</Typography.Text>
                                        </Form.Item>
                                    </div>

                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_expense_category")}</Typography.Title>}
                                            name="tname"
                                        >

                                            {editable ? (
                                                <Select style={{ width: '300px' }} placeholder={t('label_sel_type')} defaultValue={rList && rList.ctgry}>
                                                    <Select.Option value="">{t("label_pls_sel_type")}</Select.Option>
                                                    <Select.Option value="E">{t("label_event")}</Select.Option>
                                                    <Select.Option value="S">{t("label_spcl_puja")}</Select.Option>
                                                    <Select.Option value="R">{t("label_repaire")}</Select.Option>
                                                    <Select.Option value="P">{t("label_puja_items")}</Select.Option>
                                                    <Select.Option value="N">{t("label_new_item")}</Select.Option>
                                                    <Select.Option value="D">{t("label_daily_expenses")}</Select.Option>
                                                </Select>
                                            ) : (

                                                <Typography.Text level={5} className='typ'> {rList ? (
                                                    (() => {
                                                        switch (rList.ctgry) {
                                                            case 'E':
                                                                return t('label_event');
                                                            case 'S':
                                                                return t('label_spcl_puja');
                                                            case 'O':
                                                                return t('label_other');
                                                            case 'N ':
                                                                return t('label_new_items');
                                                            default:
                                                                return t('label_notchosen');
                                                        }
                                                    })()
                                                ) : (
                                                    t("label_notchosen")
                                                )}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_expense_amount")}</Typography.Title>}
                                            name="amount"
                                        >

                                            {editable ? (
                                                <Input style={{ height: '30px' }}
                                                    defaultValue={rList && rList.amount}
                                                    onChange={handleAmount}
                                                />
                                            ) : (

                                                <Typography.Text level={5} className='typ'>{rList.amount}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    {/* <div class="tiles-designs" >
                                    {editable ? (
                                        <div >
                                            <Form.Item >
                                                <Button class="button_move" type="primary" onClick={updateAmount} >{t("label_update_amount")} </Button>
                                            </Form.Item>
                                        </div>
                                    ) : (null
                                    )}
                                </div> */}
                                    {/* <div class="tiles-designs" >
                                    {editable ? (
                                        <div >
                                            <Form.Item >
                                                <Button class="button_move" type="primary" onClick={updateBill} >{t("label_update_bill")} </Button>
                                            </Form.Item>
                                        </div>
                                    ) : (null
                                    )}
                                </div> */}
                                    <div className="tiles-designs">
                                        <Form.Item label={<Typography.Title level={4}>{t("label_notes")}</Typography.Title>} name="notes">
                                            {editable ? (
                                                <Input.TextArea defaultValue={rList && rList.notes} onChange={handleNotes} />
                                            ) : (
                                                <Typography.Text level={5} className="typ">{rList.notes}</Typography.Text>
                                            )}
                                        </Form.Item>

                                        {/* {editable && (
        // <div className="tiles-designs">
        //     <Button type="primary" onClick={handleSaveClick}>
        //         {t("label_update_notes")}
        //     </Button>
        // </div>
    )} */}
                                    </div>
                                    {editable && (
                                        <div className="tiles-designs">
                                            <Form.Item
                                                name="img"
                                                label={<Typography.Title level={4}>{t('label_add_img')}</Typography.Title>}
                                                valuePropName="fileList"
                                                getValueFromEvent={normFile}
                                                extra="use png/jpg/jpeg format only"
                                            >
                                                <div>
                                                    <ImgCrop rotationSlider>
                                                        <Upload
                                                            name="logo"
                                                            action="/upload.do"
                                                            listType="picture"
                                                            onChange={onFileChange}
                                                        >
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-dark"
                                                                style={{ marginBottom: '8px' }} // Adds spacing between buttons
                                                            >
                                                                <i className="fa-solid fa-upload"></i>&nbsp;{t('label_click')}
                                                            </button>
                                                        </Upload>
                                                    </ImgCrop>

                                                    {/* Conditionally render the Camera Button only on mobile view */}
                                                    {isMobile && (
                                                        <Upload
                                                            name="logo"
                                                            action="/upload.do"
                                                            listType="picture"
                                                            onChange={onFileChange}
                                                            showUploadList={false}
                                                            accept="image/*;capture=camera"
                                                        >
                                                            <button type="button" className="btn btn-outline-dark">
                                                                <i className="fa-solid fa-camera"></i>&nbsp;{t('label_camera')}
                                                            </button>
                                                        </Upload>
                                                    )}
                                                </div>
                                            </Form.Item>

                                            <ReactCrop
                                                src="https://via.placeholder.com/150"
                                                crop={crop}
                                                onImageLoaded={onImageLoaded}
                                                onChange={onCropChange}
                                                onComplete={onCropComplete}
                                            />
                                        </div>
                                    )}
                                    <div className="active-details">
                                        <div className="tiles-designs">
                                            {token ? (
                                                <div text="Add Image" >
                                                    {previewImage ? (
                                                        <img src={previewImage} alt="Captured Image" />
                                                    ) : (
                                                        <>
                                                            {isMobile && (
                                                                <button className="button_move" onClick={handleCameraClick} style={{ marginBottom: '8px' }}>
                                                                    {t('label_camera')}
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <div text="Add Image" >
                                                    {previewImage ? (
                                                        <img src={previewImage} alt="Captured Image" />
                                                    ) : (
                                                        <>
                                                            {isMobile && (
                                                                <button className="button_move" onClick={handleImageLinkClick} style={{ marginBottom: '8px' }}>
                                                                    {t('label_camera')}
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                    <button className="button_move" onClick={handleImageLinkClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>{t('label_gallery')}</button>


                                                </div>)}
                                            {/* <div className="detail-container detail-container-image">
                                    <img
                                        className='det-image'
                                        src={rList && rList.img_path ? getImageAPI() + rList.img_path : 'default-image-path.jpg'}
                                        alt="image"
                                    />
                                </div> */}
                                            <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
                                                <div class="popup-main animate__animated animate__fadeInUp ">
                                                    <div class="popup-main-header">
                                                        <span class="popup-main-header-text">Main header</span>
                                                        <div class="popup-close" onClick={handleImageCloseModal}><i class="fa-solid fa-xmark"></i></div>
                                                        <div class="popup-back" onClick={handleImageCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                                                    </div>
                                                    <div class="popup-main-container">
                                                        <div class="d-block htinner-popup-main-container">
                                                            {/* <span className="close" onClick={handleImageCloseModal}>&times;</span> */}
                                                            {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                                                        </div>
                                                    </div>
                                                    <div class="popup-main-footer">
                                                        <button class="button_move">Crop and Upload</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {rList && rList.img_path ? (
                                                <div className="tiles-grid">
                                                    <Image.PreviewGroup>

                                                        <div >
                                                            <div className="detail-container detail-container-image">
                                                                <Image
                                                                    className="card-img"
                                                                    src={rList && rList.img_path ? getImageAPI() + rList.img_path : 'default-image-path.jpg'}
                                                                    alt="images"
                                                                />
                                                                {editable ? (
                                                                    <Popconfirm
                                                                        title="Are you sure you want to delete?"
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <CloseCircleOutlined className="close-icon" />
                                                                    </Popconfirm>
                                                                ) : null}
                                                                {editable ? (
                                                                    <Popconfirm
                                                                        title="Are you sure you want to update?"
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Checkbox className="close-icon1" />
                                                                    </Popconfirm>
                                                                ) : null}
                                                            </div>
                                                        </div>


                                                        {/* <div className="detail-container detail-container-image">
                                                {newImagePath && <Image className="card-img" src={newImagePath} alt="Selected Image" />}
                                                {newImagePath && editable ? (
                                                    // <CloseCircleOutlined className="close-icon" onClick={onFinish} />
                                                ) : null}
                                            </div> */}
                                                    </Image.PreviewGroup>
                                                </div>
                                            ) : (
                                                <div className="detail-container detail-container-image">
                                                    <img
                                                        className="card-img"
                                                        alt="temples"
                                                        src={deftemples}
                                                        style={{
                                                            width: '20%',
                                                            height: '20%',
                                                            marginBottom: '2px',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div >
                                        <Form.Item >
                                            <div class="tiles-designs" >
                                                {/* <Button class="button_move" type="primary"  ><Popconfirm
                                                title="Are you sure you want to delete?"
                                                onConfirm={() => deleteExp(rList.expid, rList.ludt)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Link>Delete</Link>
                                            </Popconfirm> </Button> */}
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default ExpenseDetails;
