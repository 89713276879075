import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getImageAPI } from "../../../../http-common";
import { fetchGodNames, fetchGroupDescription, fetchTempleGroups, fetchTemplesByGroup, retrieveGodNames, retrieveTemples } from "../../../../redux/actions/acions";
import {
  getTempleGroupsList,
  getTempleGroupsLoading,
  getTempleGroupsError,
  getTempleGroupList,
  getTempleGroupLoading,
  getTempleGroupError,
  getGodNames,
  getGodNamesLoading,
  getGodNamesError,
  getGroupDescriptionList,
  getGroupDescriptionLoading,
  getGroupDescriptionError,
} from "../../../../redux/selectors/selectors";
import { Card, Button, Col, Row, message } from 'antd';
import { Link, useLocation, useParams } from "react-router-dom";
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import Error from '../error';
import "../../search.css"
import PageHeader from "../Header/pageHeader";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import TempleCard from "../../../common/card";
import TemplesPage from "../../../common/TemplesPage";
import Footer from "../footer/footer";
import deftemples from '../../../../assets/images/templeicon.png'
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Images from "../../../common/images";
var last_rec = 0; // Last record shown on screen



const TempleGroup = () => {
  const { t } = useTranslation();
  const [filterId, setFilterId] = useState(null);
  const records_per_page = 15;
  const { gid } = useParams()
  const localEnv = false;
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [isLoading, setIsLoading] = useState(false);
  const [currentPageTemples, setCurrentPageTemples] = useState([]);
  const { groupName } = useParams();
  const [did, setDid] = useState(0);


  const [messageApi, contextHolder] = message.useMessage();
  const failure = (msg) => {
    showToast('fail', t('label_loginerror'))
    // messageApi.open({
    //   type: 'error',
    //   content: 'Please Login to see additional features',
    // });
  };

  const dispatch = useDispatch();
  const { tList, tLoading, tError, godCtgryList, godCtgryListLoading, godCtgryListError, gList, gLoading, gError } = useSelector(state => ({
    tList: getTempleGroupsList(state),
    tLoading: getTempleGroupsLoading(state),
    tError: getTempleGroupsError(state),
    godCtgryList: getTempleGroupList(state),
    godCtgryListLoading: getTempleGroupLoading(state),
    godCtgryListError: getTempleGroupError(state),
    gList: getGroupDescriptionList(state),
    gLoading: getGroupDescriptionLoading(state),
    gError: getGroupDescriptionError(state),
  }));

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  useEffect(() => {

    dispatch(fetchTemplesByGroup(storedLanguage, gid, last_rec));
  }, [storedLanguage]);
  
  useEffect(() => {

    dispatch(fetchGroupDescription(storedLanguage, gid));
  }, [storedLanguage]);

  useEffect(() => {
    // Initial API request on component mount
    dispatch(fetchTempleGroups(storedLanguage));
  }, [storedLanguage]);



  function filterTemplesList(gDid) {
    setDid(gDid);
    if (gDid === filterId) {
      setFilterId(null)
      dispatch(retrieveTemples(storedLanguage, last_rec));
    } else {
      setFilterId(gDid);
    }
  }

  function getStyle(did) {
    if (did == filterId) {
      return {
        color: '#4096ff',
        borderColor: '#4096ff',
      }
    }
    else {
      return {
        borderColor: '#d9d9d9',
        color: '#000'
      }
    }
  }

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(fetchTemplesByGroup(storedLanguage, gid, last_rec));
  }, [storedLanguage, gid, currentPage]);

  useEffect(() => {

    if (!tLoading && tList) {
      setCurrentPageTemples([...currentPageTemples, ...tList]);
    }

  }, [tLoading]);

  const nextTemplesList = async () => {
    setIsLoading(true);
    last_rec = last_rec + records_per_page;
    // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
    dispatch(fetchTemplesByGroup(storedLanguage, gid, last_rec));
    // setIsLoading(false);
  }

  function prevTemplesList() {
    last_rec = last_rec - records_per_page;
    last_rec = (last_rec < 0) ? 0 : last_rec;
    // god_last_rec = god_last_rec - records_per_page;
    // god_last_rec = (god_last_rec < 0) ? 0 : god_last_rec;
    dispatch(fetchTemplesByGroup(storedLanguage, gid, last_rec));
  };


  return (

    <>
      
      <TemplesPage
        title={gList && gList.group_name}
        description={gList && gList.descr}
        tLoading={tLoading}
        tList={tList}
        pageTitle={gid}
        filterId={filterId}
        setFilterId={setFilterId}
        filterTemplesList={filterTemplesList}
        gList={godCtgryList}
        godCtgryListLoading={godCtgryListLoading}
        temple={gList && gList.descr}
        nextTemplesList={nextTemplesList}
        prevTemplesList={prevTemplesList}
        last_rec={last_rec}
        image={Images[gList && gList.imgpath] || deftemples}
      />

      <div>
      </div>  
    </>

  );
}

export default TempleGroup;
