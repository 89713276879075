import React, { useEffect, useState } from 'react'

import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchTempleGroups, fetchTemplesByGroup } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import showToast from '../../../../utils/showToast';
import { getTempleGroupError, getTempleGroupList, getTempleGroupLoading } from '../../../../redux/selectors/selectors';
import Footer from '../footer/footer';
import statesData from '../../states.json';
import deftemples from '../../../../assets/images/templeicon.jpg'
import india from '../../../../assets/images/india.png';

const TempleInIndia = () => {
    const { t } = useTranslation();
    const [dialogVisible, setDialogVisible] = useState(false); // State to control modal visibility
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [states, setStates] = useState([]);
    useEffect(() => {
        // Fetch the list of states from the statesData and store it in state
        setStates(statesData.states.map(state => state.name));
    }, []);

    const dispatch = useDispatch();
    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])
    const handleChange = (value) => {
        setSelectedLanguage(value);
    };

    const stateImages = {
        "Andhra Pradesh": "https://diligentias.com/wp-content/uploads/2019/05/Map-of-Andhra-pradesh.png",
        "Arunachal Pradesh": "https://1.bp.blogspot.com/-DLqBV5F5_5E/YNMbvlRrtAI/AAAAAAAAunc/Wyjj08cr4bk8nSsJaXIQnoA64_iPD43tACLcBGAsYHQ/s16000/arunachal-pradesh-map.jpg",
        "Assam": "https://paintmaps.com/blank_map_samples/map_238c_2.jpg",
        "Bihar": "https://www.fileonlinerti.in/assets/img/blog/Bihar.png",
        "Chhattisgarh": "https://as2.ftcdn.net/v2/jpg/04/08/50/65/1000_F_408506528_lMphp9hjmruKJhAV7BgHLsLqgepMTe14.jpg",
        "Goa": "https://tse3.mm.bing.net/th?id=OIP.85fhLOJjeuF8R54zpfs0ywAAAA&pid=Api&P=0&h=180",
        "Gujarat": "https://tse4.mm.bing.net/th?id=OIP.sm37Uepl73vTOMGHDFEGkwHaFA&pid=Api&P=0&h=180",
        "Haryana": "https://tse4.mm.bing.net/th?id=OIP.BH_YQdMfNLr8_zefcKO-AwHaHf&pid=Api&P=0&h=180",
        "Himachal Pradesh": "https://tse1.mm.bing.net/th?id=OIP.jiJ0qNJ1ndDJy3BGyku47AHaE7&pid=Api&P=0&h=180",
        "Jharkhand": "https://tse2.mm.bing.net/th?id=OIP.JtqHJy4ICz9Vsm5vQZdNDwHaFj&pid=Api&P=0&h=180",
        "Karnataka": "https://www.infoandopinion.com/wp-content/uploads/2019/09/Karnataka-Districts-768x1152.jpg",
        "Kerala": "https://tse3.mm.bing.net/th?id=OIP.rNHmdGksCX4mPoKHtPQFegHaLl&pid=Api&P=0&h=180",
        "Madhya Pradesh": "https://tse3.mm.bing.net/th?id=OIP.f54J2w0j0UPAF6BhVCyvdgHaE3&pid=Api&P=0&h=180",
        "Maharashtra": "https://tse3.mm.bing.net/th?id=OIP.b8ik9QHjO9vb7HC0zZmf4QHaGp&pid=Api&P=0&h=180",
        "Manipur": "https://tse1.mm.bing.net/th?id=OIP.OefjOn8CK3Zlk8sWvtyYjQHaEn&pid=Api&P=0&h=180",
        "Meghalaya": "https://tse4.mm.bing.net/th?id=OIP.Gl-toyXrKcgTtu1NcrLn-AHaDp&pid=Api&P=0&h=180",
        "Mizoram": "https://tse3.mm.bing.net/th?id=OIP.yNMCLHiaZ9e8Z7Mq8mKOaQHaJL&pid=Api&P=0&h=180",
        "Nagaland": "https://tse3.mm.bing.net/th?id=OIP.T2YQyVULiH8TfjJCIpleJAHaGs&pid=Api&P=0&h=180",
        "Odisha": "https://tse4.mm.bing.net/th?id=OIP.m7gtYS48Du3XdVWR69KD7gHaGg&pid=Api&P=0&h=180",
        "Punjab": "https://tse1.mm.bing.net/th?id=OIP.awS8uuaYSGzpa2cJWZF5oQHaJL&pid=Api&P=0&h=180",
        "Rajasthan": "https://tse2.mm.bing.net/th?id=OIP.KKJ4ESgbiD0vts1IX-Kb1AHaGb&pid=Api&P=0&h=180",
        "Sikkim": "https://tse2.mm.bing.net/th?id=OIP.AC5gG0FqdzxqtoTRZSC2MAHaHl&pid=Api&P=0&h=180",
        "Tamil Nadu": "https://tse4.mm.bing.net/th?id=OIP.sQ9kGS-83t672f6flpVPwwHaIH&pid=Api&P=0&h=180",
        "Telangana": "https://tse2.mm.bing.net/th?id=OIP.SguZ0Tp427p-Ou7hUFJhmAHaH5&pid=Api&P=0&h=180",
        "Tripura": "https://tse2.mm.bing.net/th?id=OIP.C_hTak1Aabil0BFsTKhvxAHaHS&pid=Api&P=0&h=180",
        "Uttar Pradesh": "https://tse2.mm.bing.net/th?id=OIP.oksTMvegiJvJsd1A6AKAQAHaF_&pid=Api&P=0&h=180",
        "Uttarakhand": "https://tse1.mm.bing.net/th?id=OIP.esWC98o6ZCeMgSbgu6VvwwHaG9&pid=Api&P=0&h=180",
        "West Bengal": "https://tse2.mm.bing.net/th?id=OIP.n2t7uyq2D9LPXZDXclsneAHaLI&pid=Api&P=0&h=180",
        "Andaman and Nicobar Islands": "https://www.aksholidays.co.in/images/andaman-map1.png",
        "Chandigarh": "https://tse3.mm.bing.net/th?id=OIP.IAXOTR3H9lbNt_KYBV2bwAAAAA&pid=Api&P=0&h=180",
        "Dadra and Nagar Haveli": "https://tse1.mm.bing.net/th?id=OIP.UnYq5bNItB7_3wIrely6tQHaEw&pid=Api&P=0&h=180",
        "Lakshadweep": "https://tse3.mm.bing.net/th?id=OIP.V2w4YLW1MpvO0U0PCHbj-QHaFW&pid=Api&P=0&h=180",
        "Delhi": "https://tse3.mm.bing.net/th?id=OIP.QZZS5-Er4qMcAF4MumreSgHaI5&pid=Api&P=0&h=180",
        "Puducherry": "https://tse4.mm.bing.net/th?id=OIP.6zd86HDl1pssA8NHngdVVgHaHD&pid=Api&P=0&h=180",
        "Uttaranchal":"https://tse2.mm.bing.net/th?id=OIP.8npFDBYGtuJS6OIXOiHUogHaGx&pid=Api&P=0&h=180",
        "Daman and Diu":"https://tse1.mm.bing.net/th?id=OIP.UnYq5bNItB7_3wIrely6tQHaEw&pid=Api&P=0&h=180",
        "Jammu and Kashmir":"https://tse3.mm.bing.net/th?id=OIP.upzMwiEIvvoT9PcLUSLxvAHaGL&pid=Api&P=0&h=180"
    };




    const fetchGroupTemples = (gid) => {
        // Find the object in nList with the matching gid
        const group = nList.find(group => group.gid === gid);

        if (group) {
            // If a matching group is found, extract the group name
            const groupName = group.group_name;

            // Dispatch fetchTemplesByGroup and navigate with gid and groupName
            dispatch(fetchTemplesByGroup(storedLanguage, gid, 0));
            navigate(`/templeGroup/${gid}`);
        } else {
            // Handle the case where no matching group is found
            console.error('Group not found for gid:', gid);
        }
    };

    const { nList, nLoading, nError } = useSelector((state) => ({
        nList: getTempleGroupList(state),
        nLoading: getTempleGroupLoading(state),
        nError: getTempleGroupError(state),
    }));

    useEffect(() => {
        // Initial API request on component mount
        dispatch(fetchTempleGroups(storedLanguage));
    }, [storedLanguage]);


    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    const handleStateClick = (stateName) => {
        // Navigate to the city page for the selected state
        navigate(`/cityPage/${stateName}`);
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div class="mainsec">
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_states_in_india')}</span>
                </div>
                <div></div>
            </div>
            <div className='f2 font-weight-bold mb-3 mt-3 container breadcrumb-link'>

                <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link> - <Link style={{ color: 'black' }} to='/templeCtgry'><span class="bc-active-link">{t("label_temples_list")}</span></Link> - <span class="bc-link">{t('label_states_in_india')}</span></div>
            <div class="mainsec-block">
                <div class="f2 font-weight-bold  container title-head-txt" style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }} >
                    {token ? (
                        <Link to='/addPage'><button type="button" class="declineButton ">{t("add_temple")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}><button type="button" class="declineButton ">{t("add_temple")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )}
                    <Link to='/recentlyViewed'><button type="button" class="declineButton ">{t("lable_recent_view")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    <Link to='/nearby'> <button type="button" class="declineButton ">{t('label_temples_by_map')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    {token ? (
                        <Link to='/searchFavourite'><button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}><button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )}
                </div>


                {/* <div className='f2 font-weight-bold mb-2 mt-4 container title-head-txt'>
                
                Temple Categories</div> */}
                <div className="container">
                    <div class="container">
                        <h5 class="card-text-title">{t("label_states_in_india")}
                            {/* <span>| {t("label_temple_divided_groups")}</span> */}
                        </h5>
                    </div>
                    <div className="tiles-main">
                        {states.map((state, index) => (
                            <Link to={`/cityPage/${state}`} key={index}>
                                <div className="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div className="title-img-bg">
                                        <img className="card-img-temple-cat" alt="temples" src={stateImages[state] || india} />
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{state}</span> <span>{state}</span>
                                    </div>
                                </div>
                            </Link>
                        ))}

                    </div>
                </div>

                <Footer />
            </div>
        </div>



    )

}

export default TempleInIndia;