import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import statesData from '../../states.json';
import india from '../../../../assets/images/india.png';
import showToast from '../../../../utils/showToast';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import { Col, Row } from 'antd';

const CityPage = () => {
    const { state } = useParams();
    const { t } = useTranslation();
    // Normalize both the URL parameter and the state name for case-insensitive comparison
    const normalizedState = state.trim().toLowerCase();
    const states = statesData.states.find(s => s.name.toLowerCase() === normalizedState);
    const cities = states ? states.cities : [];
    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div class="mainsec">
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{`${t('label_cities')} ${t(state)}`}</span>
                </div>
                <div></div>
            </div>
            <div className='f2 font-weight-bold mb-3 mt-3 container breadcrumb-link'>

                <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link> - <Link style={{ color: 'black' }} to='/templeCtgry'><span class="bc-active-link">{t("label_temples_list")}</span></Link> - <span class="bc-link">{`${t('label_cities')} ${t(state)}`}</span></div>
            <div class="mainsec-block">
                <div class="f2 font-weight-bold  container title-head-txt" style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }} >
                    {token ? (
                        <Link to='/addPage'><button type="button" class="declineButton ">{t("add_temple")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}><button type="button" class="declineButton ">{t("add_temple")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )}
                    <Link to='/recentlyViewed'><button type="button" class="declineButton ">{t("lable_recent_view")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    <Link to='/nearby'> <button type="button" class="declineButton ">{t('label_temples_by_map')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    {token ? (
                        <Link to='/searchFavourite'><button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}><button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )}
                </div>


                {/* <div className='f2 font-weight-bold mb-2 mt-4 container title-head-txt'>
      
      Temple Categories</div> */}
                <div className="container">
                    <div class="container">
                        <h5 class="card-text-title">{`${t('label_cities')} ${t(state)}`}
                            {/* <span>| {t("label_temple_divided_groups")}</span> */}
                        </h5>
                    </div>
                    {/* <div className="tiles-main">
                        {cities.map((city, index) => {
                            const cleanedCity = city.trim().replace(/[{}]/g, '');
                            const cityURL = encodeURIComponent(cleanedCity);
                            return (
                                <Link to={`/state/${state}/city/${cityURL}`} key={index}>
                                    <div className="tiles-design-temple-cat animate__animated animate__flipInX">
                                        
                                        <div className="tile-text-temple-cat">
                                            <span>{cleanedCity}</span> <span>{cleanedCity}</span>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div> */}
                    <section className="container h-100 d-flex flex-column">
                        <div className="search-rt-sec1 ">
                            <Row>
                                {
                                    cities.map((city,index) => {
                                        const cleanedCity = city.trim().replace(/[{}]/g, '');
                                        const cityURL = encodeURIComponent(cleanedCity);
                                        return (
                                            <Col className='youtube-video' index={city}>
                                                <div class="tiles-design animate__animated animate__flipInX">
                                                    <div >

                                                        <div class="card-body p-0">
                                                            <Link to={`/state/${state}/city/${cityURL}`} key={index}>
                                                                <div class="p-2">
                                                                    <h6 className="home-temple-text" title={city}>{city}</h6>
                                                                    
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </div>
        // <div>
        //     <h2>Cities in {state}</h2>
        //     <ul>
        //         {cities.map((city, index) => (
        //             <li key={index}>{city}</li>
        //         ))}
        //     </ul>
        // </div>
    );
};

export default CityPage;
