import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, InfoWindow, MarkerClusterer, MarkerF, useLoadScript } from "@react-google-maps/api";
import logo from '../../../../assets/images/img.png';
import { useTranslation } from 'react-i18next';

const apiKey = 'AIzaSyBLWiZul9MyYq8DwaGhosrNWP7QJJT9krU';

const defaultCenter = { lat: 20.5937, lng: 78.9629 }; // Coordinates for the center of India

function TemplesListMap({ templesList, mapContainerStyle }) {
  const [activeMarker, setActiveMarker] = useState(null);
  const { t } = useTranslation();
  const [center, setCenter] = useState(defaultCenter); // Set default center to India

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const validateCoordinates = (lat, lng) => {
    return typeof lat === 'number' && typeof lng === 'number' && !isNaN(lat) && !isNaN(lng);
  };

  useEffect(() => {
    if (templesList && templesList.length > 0) {
      const { latit, longi } = templesList[0];
      const lat = parseFloat(latit);
      const lng = parseFloat(longi);

      if (validateCoordinates(lat, lng)) {
        setCenter({ lat, lng });
        console.warn('Center set to:', { lat, lng });
      } else {
        console.warn('Invalid coordinates:', latit, longi);
      }
    } else {
      console.warn('Using default center:', defaultCenter);
    }
  }, [templesList]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  if (!isLoaded) return <div>Loading...</div>;

 

  return (
    <div className="main">
      <GoogleMap
        center={center}
        zoom={5}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={mapContainerStyle}
      >
        <MarkerClusterer
          options={{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' }}
        >
          {(clusterer) =>
            templesList && templesList.map(({ tid, tname, latit, longi }) => {
              const lat = parseFloat(latit);
              const lng = parseFloat(longi);

              return validateCoordinates(lat, lng) && (
                <MarkerF
                  key={tid}
                  position={{ lat, lng }}
                  clusterer={clusterer}
                  onClick={() => handleActiveMarker(tid)}
                  icon={{
                    url: logo,
                    scaledSize: new window.google.maps.Size(40, 40), // Adjust the size as per your image
                  }}
                >
                  {activeMarker === tid ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <div>{tname}</div>
                    </InfoWindow>
                  ) : null}
                </MarkerF>
              );
            })
          }
        </MarkerClusterer>
      </GoogleMap>
    </div>
  );
}

export default TemplesListMap;
