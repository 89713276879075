import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const availableLanguages = ['1', '2', '3', '4', '5', '7']
const option = {
    order: ['navigator', 'htmlTag', 'path', 'subdomail'],
    checkWhitelist: true
}
i18n.use(Backend).use(LanguageDetector).use(initReactI18next)
    .init({
        fallbackLng: '5',
        debug: true,
        whitelist: availableLanguages,
        detection: option,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
